import axios from "axios";
import {
  DELETE_PROFILE_IMAGE,
  FETCH_PROFILE_IMAGES,
  REMOVE_DEFAULT_PROFILE_IMAGE,
  SET_DEFAULT_PROFILE_IMAGE,
  SET_PROFILE_IMAGE,
} from "../config/config";

// get profile images get api call
export const getProfileImages = async (token) => {
  try {
    const response = await axios.get(FETCH_PROFILE_IMAGES, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get profile images:", error);
    throw error;
  }
};

// Function to send a picture via API
export const sendPicture = async (token, pictureFile) => {
  try {
    const response = await axios.post(SET_PROFILE_IMAGE, pictureFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error sending picture:", error);
    throw error;
  }
};

// Function to set default  profile pics via API
export const setDefaultProfilePicture = async (token, id) => {
  try {
    const response = await axios.post(
      SET_DEFAULT_PROFILE_IMAGE,
      { image_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error setting default picture:", error);
    throw error;
  }
};

// Function to remove default profile pics via API
export const removeDefaultProfilePicture = async (token, id) => {
  try {
    const response = await axios.post(
      REMOVE_DEFAULT_PROFILE_IMAGE,
      { image_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error remove default picture:", error);
    throw error;
  }
};

// Function to delete profile pics via API
export const deleteProfilePicture = async (token, id) => {
  try {
    const response = await axios.post(
      DELETE_PROFILE_IMAGE,
      { image_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error delete default picture:", error);
    throw error;
  }
};
