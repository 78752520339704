import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "../../components/Accordion/Accordion";
import { IoLocationOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  FaCalendarAlt,
  FaGraduationCap,
  FaPlus,
  FaUniversity,
} from "react-icons/fa";
import Sidebar from "../../components/Sidebar/Sidebar";
import LanguageSelect from "../../components/Select/Language";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import Modal from "../../components/Modal/Modal";
import { BsExclamationCircleFill } from "react-icons/bs";
import {
  qualificationDataCompleted,
  qualificationDataNotCompleted,
  qualificationDataNotEducated,
  qualificationDataPassFail,
} from "../../constants/fieldData";
import StatusSelect from "../../components/Select/Status";
import QualificationSelect from "../../components/Select/Qualification";
import CourseSelect from "../../components/Select/Course";
import SpecialisationSelect from "../../components/Select/Specialisation";
import {
  deleteQualificationnsData,
  getQualificationsDetails,
  updateQualificationData,
} from "../../api/qualification";
import DurationSelect from "../../components/Select/Duration";
import MarkSelect from "../../components/Select/Marks";
import BoardSelect from "../../components/Select/Board";
import LocationAutocomplete from "../../components/Location/Location";
import { getMenuState } from "../../api/sideBarApi";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import AccordionSkeleton from "../../components/Skeleton/AccordionSkeleton";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";

const Qualification = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [addNew, setAddNew] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [data, setData] = useState(qualificationDataCompleted);
  const [bord, setBoard] = useState({
    board_city: "",
    board_country: " ",
  });
  const [institution, setInstitution] = useState({
    institution_city: "",
    institution_country: " ",
  });
  const [closeAccordion, setCloseAccordion] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteQualification, setDeleteQualification] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchQualificationsDetails = async (token) => {
      try {
        const details = await getQualificationsDetails(token);
        if (details.status === 1) {
          setQualifications(details.data);
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          setSkeletonLoad(false);
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to fetch qualification details details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error qualification details details :", error);
        setSkeletonLoad(false);
      }
    };
    fetchQualificationsDetails(token);
  }, [token]);

  const handleEdit = (index) => {
    handleAddNewCancel();
    formikEdit.setValues(qualifications[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    setDeleteQualification(index);
    setDeleteModal(true);
  };

  const handleQualificationDelete = async () => {
    const delete_id = qualifications[deleteQualification].id;
    try {
      setLoading(true);
      const response = await deleteQualificationnsData(
        {
          id: delete_id,
        },
        token
      );

      if (response.status === 1) {
        setLoading(false);
        setQualifications(response.data);
        dispatch(setAlert(response.message, "success"));
        setDeleteModal(false);
        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error dleteing qualification ", error.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
    formikEdit.resetForm();
  };

  const handleAddNewCancel = () => {
    setAddNew(false);
    formikAdd.resetForm();
  };

  const handleAddNew = () => {
    formikAdd.resetForm();
    setAddNew(true);
    setData(qualificationDataCompleted);
    setEditIndex(null);
    setCloseAccordion(true);
    setTimeout(() => {
      setCloseAccordion(false);
    }, 200);
  };

  const validationSchema = yup.object().shape({
    qualification_status: yup
      .string()
      .required("Status of Education is required"),
    qualification_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Qualification Level is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    course_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Course & Subject is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    discipline_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Specialised Area is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    duration_course: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Duration of Course is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    medium_study_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Medium of Teaching is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    year_of_pass: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) => schema.required("Year is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    discipline_id_other: yup
      .string()
      .when(["qualification_status", "discipline_name"], {
        is: (qualification_status, discipline_name) =>
          qualification_status !== "Not Educated" &&
          discipline_name === "Other",
        then: (schema) => schema.required("Specialised Area is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    qualification_id_other: yup
      .string()
      .when(["qualification_status", "qualification_name"], {
        is: (qualification_status, qualification_name) =>
          qualification_status !== "Not Educated" &&
          qualification_name === "Other",
        then: (schema) => schema.required("Qualification Level is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    course_id_other: yup
      .string()
      .when(["qualification_status", "course_name"], {
        is: (qualification_status, course_name) =>
          qualification_status !== "Not Educated" && course_name === "Other",
        then: (schema) => schema.required("Course & Subject is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    studing_years: yup.string().when(["qualification_status"], {
      is: (qualification_status) => qualification_status === "Not Completed",
      then: (schema) => schema.required("Years completed is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    mark_range_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) =>
        schema.required("Marks/Grade in Final Result is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    board_name: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) =>
        schema.required("Certificate Issuing Authority is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    board_id_other: yup.string().when(["qualification_status", "board_name"], {
      is: (qualification_status, board_name) =>
        qualification_status !== "Not Educated" && board_name === "Other",
      then: (schema) =>
        schema.required("Certificate Issuing Authority is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    board_city: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) =>
        schema.required("City & Country of the Authority is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    institution: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) =>
        schema.required("Name of Studied Institution is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    institution_city: yup.string().when("qualification_status", {
      is: (value) => value !== "Not Educated",
      then: (schema) =>
        schema.required("City & Country of the Institution is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleBordError = (error) => {
    // Clear location  error when the user starts typing
    if (!error) {
      formik.setErrors({ ...formik.errors, board_city: "" });
    }
  };

  const handleInstitutionError = (error) => {
    // Clear location  error when the user starts typing
    if (!error) {
      formik.setErrors({ ...formik.errors, institution_city: "" });
    }
  };

  const handleBoardCitySelected = (location) => {
    if (location?.city) {
      setBoard({
        board_city:
          location && `${location.city},${location.state},${location.country}`,
        board_country: location && location.country_code,
      });
    } else {
      setBoard({
        board_city: null,
        board_country: null,
      });
    }
  };

  useEffect(() => {
    const handleBoard = () => {
      if (bord) {
        formik.setValues({
          ...formik.values,
          board_city: bord?.board_city,
          board_country: bord?.board_country,
        });
      } else {
        formik.setValues({
          ...formik.values,
          board_city: null,
          board_country: null,
        });
      }
    };
    handleBoard();
  }, [bord]);

  const handleInstitutionCitySelected = (location) => {
    if (location?.city) {
      setInstitution({
        institution_city:
          location && `${location.city},${location.state},${location.country}`,
        institution_country: location && location.country_code,
      });
    } else {
      setInstitution({
        institution_city: null,
        institution_country: null,
      });
    }
  };

  useEffect(() => {
    const handleInstitution = () => {
      if (institution) {
        formik.setValues({
          ...formik.values,
          institution_city: institution?.institution_city,
          institution_country: institution?.institution_country,
        });
      } else {
        formik.setValues({
          ...formik.values,
          institution_city: null,
          institution_country: null,
        });
      }
    };
    handleInstitution();
  }, [institution]);

  const handleStatusSelect = (status) => {
    formik.setValues({
      ...formik.values,
      qualification_status: status?.label,
      status: status?.value,
    });
    switch (status?.label) {
      case "Passed":
      case "Failed":
        setData(qualificationDataPassFail);

        break;
      case "Not Completed":
        setData(qualificationDataNotCompleted);

        break;
      case "Not Educated":
        setData(qualificationDataNotEducated);

        break;
      default:
        setData(qualificationDataCompleted);
        break;
    }

    formik.setErrors({ ...formik.errors, qualification_status: "" });
  };
  const handleQualificationSelect = (education) => {
    if (education) {
      formik.setValues({
        ...formik.values,
        qualification_name: education?.label,
        qualification_id: education?.value,
      });
    } else {
      formik.setValues({
        ...formik.values,
        qualification_name: null,
        qualification_id: null,
        course_name: null,
        course_id: null,
        discipline_name: null,
        discipline_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      qualification_name: "",
      qualification_id_other: "",
    });
  };

  const handleCourseSelect = (course) => {
    if (course) {
      formik.setValues({
        ...formik.values,
        course_name: course?.label,
        course_id: course?.value,
      });
    } else {
      formik.setValues({
        ...formik.values,
        course_name: null,
        course_id: null,
        discipline_name: null,
        discipline_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      course_name: "",
      course_id_other: "",
    });
  };

  const handleSpecialisationSelect = (specialisation) => {
    if (specialisation) {
      formik.setValues({
        ...formik.values,
        discipline_name: specialisation?.label,
        discipline_id: specialisation?.value,
      });
    } else {
      formik.setValues({
        ...formik.values,
        discipline_name: null,
        discipline_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      discipline_name: "",
      discipline_id_other: "",
    });
  };

  const handleDurationSelect = (duration) => {
    if (duration) {
      formik.setValues({
        ...formik.values,
        duration_year: duration?.year,
        duration_month: duration?.month,
        duration_year_name: duration?.year_name,
        duration_month_name: duration?.month_name,
        duration_course:
          duration && `${duration.year_name} & ${duration.month_name}`,
      });
    } else {
      formik.setValues({
        ...formik.values,
        duration_year: null,
        duration_month: null,
        duration_year_name: null,
        duration_month_name: null,
        duration_course: null,
      });
    }

    formik.setErrors({ ...formik.errors, duration_course: "" });
  };

  const handleLanguageSelect = (language) => {
    formik.setValues({
      ...formik.values,
      medium_study_name: language?.label,
      medium_study: language?.value,
    });

    formik.setErrors({ ...formik.errors, medium_study_name: "" });
  };

  const handleMarkSelect = (mark) => {
    formik.setValues({
      ...formik.values,
      mark_range_name: mark?.label,
      mark_range_id: mark?.value,
    });

    formik.setErrors({ ...formik.errors, mark_range_name: "" });
  };

  const handleBoardSelect = (board) => {
    formik.setValues({
      ...formik.values,
      board_name: board?.label,
      board_id: board?.value,
    });

    formik.setErrors({ ...formik.errors, board_name: "" });
  };

  const getDataArg = (flag, index) => {
    if (flag) {
      return values.qualification_status;
    } else {
      return qualifications[index].qualification_status;
    }
  };

  // Function to get data based on status
  const getDataByStatus = (status) => {
    switch (status) {
      case "Passed":
      case "Failed":
        return qualificationDataPassFail;
      case "Not Completed":
        return qualificationDataNotCompleted;
      case "Not Educated":
        return qualificationDataNotEducated;
      default:
        return qualificationDataCompleted;
    }
  };

  // Function to handle form submission when adding a new item
  const onSubmitAdd = async (values, { setErrors }) => {
    try {
      setLoading(true);
      const response = await updateQualificationData(values, token);
      if (response.status === 1) {
        setLoading(false);
        setQualifications(response.data);
        // Handle the error if the response is not okay
        dispatch(setAlert(response.message, "success"));
        setAddNew(false);
        formikAdd.resetForm();

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  // Function to handle form submission when editing an existing item
  const onSubmitEdit = async (values, { setErrors }) => {
    const edit_id = qualifications[editIndex].id;
    const editValues = { ...values, id: edit_id };
    try {
      setLoading(true);
      const response = await updateQualificationData(editValues, token);
      if (response.status === 1) {
        setLoading(false);
        setQualifications(response.data);
        dispatch(setAlert(response.message, "success"));
        setEditIndex(null);

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the field value
    formikAdd.handleChange(e);

    // Clear errors for the field
    formikAdd.setErrors({
      ...formikAdd.errors,
      [name]: undefined,
    });

    formikEdit.setValues({
      ...formikEdit.values,
      [name]: value,
    });

    formikEdit.setErrors({
      ...formikEdit.errors,
      [name]: undefined,
    });
  };
  const initialValuesAdd = {};

  qualificationDataNotCompleted.forEach((field) => {
    initialValuesAdd[field.name] = "";
  });

  const formikAdd = useFormik({
    initialValues: initialValuesAdd,
    validationSchema: validationSchema,
    onSubmit: onSubmitAdd,
    validateOnChange: false,
    validateOnBlur: true,
  });

  // For editing existing item
  const initialValuesEdit = {};

  const formikEdit = useFormik({
    initialValues: initialValuesEdit,
    validationSchema: validationSchema,
    onSubmit: onSubmitEdit,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    setTimeout(() => {
      formikEdit.setValues(qualifications[editIndex]);
    }, 500);
  }, [editIndex]);

  // Selecting the correct formik object based on whether adding new or editing
  const formik = addNew ? formikAdd : formikEdit;

  const { values, errors, touched } = formik;

  if (skeletonLoad) {
    return <AccordionSkeleton />;
  }
  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center  md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>
        <div className="lg:w-[65%] w-[97%] mb-4  h-fit lg:ml-6 md:ml-2 ml-0">
          <div className="bg-white shadow-md">
            <div className="mb-4 md:mb-6 md:flex items-center p-6 md:p-10 pb-4">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">QUALIFICATION DETAILS</h2>
                  <button
                    className=" ml-2  flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 my-2 h-[2px] "></div>
              </div>
              {!addNew &&
              qualifications[0]?.qualification_status !== "Not Educated" ? (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2" />
                  Add new
                </button>
              ) : (
                <button
                  className="ml-auto flex items-center cursor-not-allowed text-gray-500 text-xs"
                  onClick={handleAddNew}
                  disabled={true}
                >
                  <FaPlus className="mr-2" />
                  Add new
                </button>
              )}
            </div>
          </div>

          {qualifications.map((qualification, index) => (
            <div className="mt-6" key={index}>
              <Accordion>
                <AccordionItem
                  title={qualification.heading_name}
                  onEdit={() => handleEdit(index)}
                  onDelete={() => handleDelete(index)}
                  firstAccordion={index}
                  closeAll={closeAccordion}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="md:ps-8 ps-6 pe-4"
                  >
                    {getDataByStatus(
                      getDataArg(editIndex === index, index)
                    ).map((field) => (
                      <React.Fragment key={field.name}>
                        <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                          {editIndex === index
                            ? (() => {
                                switch (field.name) {
                                  case "course_name":
                                    return (
                                      values["qualification_name"] && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );
                                  case "discipline_name":
                                    return (
                                      values["course_name"] &&
                                      values["qualification_name"] && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );
                                  case "discipline_id_other":
                                    return (
                                      values["discipline_id"] === "-1" && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );

                                  default:
                                    return (
                                      <label className="text-gray-500 text-xs lg:w-[250px]">
                                        {field.label}
                                        {editIndex === index &&
                                          field.required && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                      </label>
                                    );
                                }
                              })()
                            : (() => {
                                switch (field.name) {
                                  case "discipline_id_other":
                                    return "";
                                  default:
                                    return (
                                      <label className="text-gray-500 text-xs lg:w-[250px]">
                                        {field.label}
                                        {editIndex === index &&
                                          field.required && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                      </label>
                                    );
                                }
                              })()}

                          <div className="flex items-center flex-1 mt-3 lg:mt-0">
                            {editIndex !== index ? (
                              <>
                                {(() => {
                                  switch (field.name) {
                                    case "duration_course":
                                    case "year_of_pass":
                                      return (
                                        <FaCalendarAlt className="text-custom mr-3 text-[15px]" />
                                      );

                                    case "board_name":
                                    case "institution":
                                      return (
                                        <FaUniversity className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "institution_city":
                                    case "board_city":
                                      return (
                                        <IoLocationOutline className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "discipline_id_other":
                                      return "";
                                    case "qualification_id_other":
                                      return "";
                                    case "course_id_other":
                                      return "";
                                    case "board_id_other":
                                      return "";
                                    default:
                                      return (
                                        <FaGraduationCap className="text-custom mr-3 text-[15px]" />
                                      );
                                  }
                                })()}

                                <span className="text-xs font-medium">
                                  {qualification[field.name]}
                                </span>
                              </>
                            ) : (
                              <div className="text-xs w-full">
                                {(() => {
                                  switch (field.name) {
                                    case "qualification_status":
                                      return (
                                        <StatusSelect
                                          getStatus={handleStatusSelect}
                                          error={errors["qualification_status"]}
                                          val={{
                                            value: values.status,
                                            label: values.qualification_status,
                                          }}
                                        />
                                      );
                                    case "qualification_name":
                                      return (
                                        <QualificationSelect
                                          getQualification={
                                            handleQualificationSelect
                                          }
                                          error={errors["qualification_name"]}
                                          val={{
                                            value: values.qualification_id,
                                            label: values.qualification_name,
                                          }}
                                        />
                                      );
                                    case "course_name":
                                      return (
                                        values["qualification_name"] && (
                                          <CourseSelect
                                            getCourse={handleCourseSelect}
                                            id={values["qualification_id"]}
                                            error={errors["course_name"]}
                                            val={{
                                              value: values.course_id,
                                              label: values.course_name,
                                            }}
                                          />
                                        )
                                      );
                                    case "discipline_name":
                                      return (
                                        values["course_name"] &&
                                        values["qualification_name"] && (
                                          <SpecialisationSelect
                                            getSpecialisation={
                                              handleSpecialisationSelect
                                            }
                                            id={values["course_id"]}
                                            error={errors["discipline_name"]}
                                            val={{
                                              value: values.discipline_id,
                                              label: values.discipline_name,
                                            }}
                                          />
                                        )
                                      );
                                    case "discipline_id_other":
                                      return (
                                        values["discipline_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name] &&
                                              touched[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "qualification_id_other":
                                      return (
                                        values["qualification_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name] &&
                                              touched[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "course_id_other":
                                      return (
                                        values["course_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name] &&
                                              touched[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "board_id_other":
                                      return (
                                        values["board_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name] &&
                                              touched[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "duration_course":
                                      return (
                                        <DurationSelect
                                          getDuration={handleDurationSelect}
                                          error={errors["duration_course"]}
                                          valMonth={{
                                            value: values.duration_month,
                                            label: `${values.duration_month} Months`,
                                          }}
                                          valYear={{
                                            value: values.duration_year,
                                            label: `${values.duration_year} Years`,
                                          }}
                                        />
                                      );
                                    case "medium_study_name":
                                      return (
                                        <LanguageSelect
                                          getLanguage={handleLanguageSelect}
                                          error={errors["medium_study_name"]}
                                          language={{
                                            value: values.medium_study,
                                            label: values.medium_study_name,
                                          }}
                                        />
                                      );
                                    case "mark_range_name":
                                      return (
                                        <MarkSelect
                                          getMark={handleMarkSelect}
                                          error={errors["mark_range_name"]}
                                          val={{
                                            value: values.mark_range_id,
                                            label: values.mark_range_name,
                                          }}
                                        />
                                      );
                                    case "board_name":
                                      return (
                                        <BoardSelect
                                          getBoard={handleBoardSelect}
                                          error={errors["board_name"]}
                                          val={{
                                            value: values.board_id,
                                            label: values.board_name,
                                          }}
                                        />
                                      );
                                    case "board_city":
                                      return (
                                        <LocationAutocomplete
                                          getLocation={handleBoardCitySelected}
                                          locationError={handleBordError}
                                          Error={errors["board_city"]}
                                          val={values.board_city}
                                        />
                                      );
                                    case "institution_city":
                                      return (
                                        <LocationAutocomplete
                                          getLocation={
                                            handleInstitutionCitySelected
                                          }
                                          locationError={handleInstitutionError}
                                          Error={errors["institution_city"]}
                                          val={values.institution_city}
                                        />
                                      );
                                    default:
                                      return (
                                        <input
                                          className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                            errors[field.name] &&
                                            touched[field.name]
                                              ? "border-red-500"
                                              : ""
                                          }`}
                                          type="text"
                                          id={field.name}
                                          name={field.name}
                                          value={values[field.name]}
                                          onChange={handleFieldChange}
                                          placeholder={field.placeholder}
                                        />
                                      );
                                  }
                                })()}

                                {errors[field.name] &&
                                  touched[field.name] &&
                                  (() => {
                                    switch (field.name) {
                                      case "course_name":
                                        return (
                                          values["qualification_name"] && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors[field.name]}
                                            </div>
                                          )
                                        );
                                      case "discipline_name":
                                        return (
                                          values["course_name"] &&
                                          values["qualification_name"] && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors[field.name]}
                                            </div>
                                          )
                                        );
                                      case "discipline_id_other":
                                        return (
                                          // values["discipline_id"] === "-1" &&
                                          <div className="text-red-500 text-xs mt-1">
                                            {errors.discipline_id_other}
                                          </div>
                                        );

                                      default:
                                        return (
                                          <div className="text-red-500 text-xs mt-1">
                                            {errors[field.name]}
                                          </div>
                                        );
                                    }
                                  })()}
                              </div>
                            )}
                          </div>
                        </div>
                        {editIndex === index
                          ? (() => {
                              switch (field.name) {
                                case "course_name":
                                  return (
                                    values["qualification_name"] && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "discipline_name":
                                  return (
                                    values["course_name"] &&
                                    values["qualification_name"] && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "discipline_id_other":
                                  return (
                                    values["discipline_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "qualification_id_other":
                                  return (
                                    values["qualification_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "course_id_other":
                                  return (
                                    values["course_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "board_id_other":
                                  return (
                                    values["board_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                default:
                                  return (
                                    <hr className="border-gray-300 my-4" />
                                  );
                              }
                            })()
                          : (() => {
                              switch (field.name) {
                                case "discipline_id_other":
                                  return "";
                                case "qualification_id_other":
                                  return "";
                                case "course_id_other":
                                  return "";
                                case "board_id_other":
                                  return "";

                                default:
                                  return (
                                    <hr className="border-gray-300 my-4" />
                                  );
                              }
                            })()}
                      </React.Fragment>
                    ))}

                    {editIndex === index && (
                      <div className="text-end">
                        <>
                          <button
                            className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCancel}
                          >
                            CANCEL
                          </button>
                          <button
                            className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Saving..." : "SAVE"}
                          </button>
                        </>
                      </div>
                    )}
                  </form>
                </AccordionItem>
              </Accordion>
            </div>
          ))}

          {!addNew &&
            qualifications.length > 0 &&
            qualifications[0]?.qualification_status !== "Not Educated" && (
              <div className="w-full flex justify-center">
                <div className="bg-white shadow-lg text-center flex   w-max my-6 text-xs py-3   rounded-full    px-6 focus:outline-none focus:shadow-outline">
                  Do you have more qualifications ?
                  <button
                    className="ml-auto flex items-center text-blue-500 text-xs"
                    onClick={handleAddNew}
                  >
                    <FaPlus className="mr-2 ml-4" />
                    Add new
                  </button>
                </div>
              </div>
            )}

          {addNew && (
            <div className="bg-white shadow-lg mt-6 pt-10">
              <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="px-10 pb-10"
              >
                {data.map((field, index) => (
                  <React.Fragment key={index}>
                    <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                      {(() => {
                        switch (field.name) {
                          case "course_name":
                            return (
                              values["qualification_name"] && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "discipline_name":
                            return (
                              values["course_name"] &&
                              values["qualification_name"] && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "discipline_id_other":
                            return (
                              values["discipline_id"] === "-1" && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          default:
                            return (
                              <label className="text-gray-500 text-xs lg:w-[250px]">
                                {field.label}
                                {field.required && (
                                  <span className="text-red-500">*</span>
                                )}
                              </label>
                            );
                        }
                      })()}

                      <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                        <div className="text-xs w-full">
                          {(() => {
                            switch (field.name) {
                              case "qualification_status":
                                return (
                                  <StatusSelect
                                    getStatus={handleStatusSelect}
                                    error={errors["qualification_status"]}
                                  />
                                );
                              case "qualification_name":
                                return (
                                  <QualificationSelect
                                    getQualification={handleQualificationSelect}
                                    error={errors["qualification_name"]}
                                    val={{
                                      value: values.qualification_id,
                                      label: values.qualification_name,
                                    }}
                                  />
                                );
                              case "qualification_id_other":
                                return (
                                  values["qualification_id"] === "-1" && (
                                    <input
                                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? "border-red-500"
                                          : ""
                                      }`}
                                      type="text"
                                      id={field.name}
                                      name={field.name}
                                      value={values[field.name]}
                                      onChange={handleFieldChange}
                                      placeholder={field.placeholder}
                                    />
                                  )
                                );
                              case "course_name":
                                return (
                                  values["qualification_name"] && (
                                    <CourseSelect
                                      getCourse={handleCourseSelect}
                                      id={values["qualification_id"]}
                                      error={errors["course_name"]}
                                      val={{
                                        value: values.course_id,
                                        label: values.course_name,
                                      }}
                                    />
                                  )
                                );
                              case "course_id_other":
                                return (
                                  values["course_id"] === "-1" && (
                                    <input
                                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? "border-red-500"
                                          : ""
                                      }`}
                                      type="text"
                                      id={field.name}
                                      name={field.name}
                                      value={values[field.name]}
                                      onChange={handleFieldChange}
                                      placeholder={field.placeholder}
                                    />
                                  )
                                );
                              case "discipline_name":
                                return (
                                  values["course_name"] &&
                                  values["qualification_name"] && (
                                    <SpecialisationSelect
                                      getSpecialisation={
                                        handleSpecialisationSelect
                                      }
                                      id={values["course_id"]}
                                      error={errors["discipline_name"]}
                                      val={{
                                        value: values.discipline_id,
                                        label: values.discipline_name,
                                      }}
                                    />
                                  )
                                );
                              case "discipline_id_other":
                                return (
                                  values["discipline_id"] === "-1" && (
                                    <input
                                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? "border-red-500"
                                          : ""
                                      }`}
                                      type="text"
                                      id={field.name}
                                      name={field.name}
                                      value={values[field.name]}
                                      onChange={handleFieldChange}
                                      placeholder={field.placeholder}
                                    />
                                  )
                                );
                              case "board_id_other":
                                return (
                                  values["board_id"] === "-1" && (
                                    <input
                                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? "border-red-500"
                                          : ""
                                      }`}
                                      type="text"
                                      id={field.name}
                                      name={field.name}
                                      value={values[field.name]}
                                      onChange={handleFieldChange}
                                      placeholder={field.placeholder}
                                    />
                                  )
                                );
                              case "duration_course":
                                return (
                                  <DurationSelect
                                    getDuration={handleDurationSelect}
                                    error={errors["duration_course"]}
                                  />
                                );
                              case "medium_study_name":
                                return (
                                  <LanguageSelect
                                    getLanguage={handleLanguageSelect}
                                    error={errors["medium_study_name"]}
                                  />
                                );
                              case "mark_range_name":
                                return (
                                  <MarkSelect
                                    getMark={handleMarkSelect}
                                    error={errors["mark_range_name"]}
                                  />
                                );
                              case "board_name":
                                return (
                                  <BoardSelect
                                    getBoard={handleBoardSelect}
                                    error={errors["board_name"]}
                                  />
                                );
                              case "board_city":
                                return (
                                  <LocationAutocomplete
                                    getLocation={handleBoardCitySelected}
                                    locationError={handleBordError}
                                    Error={errors["board_city"]}
                                  />
                                );
                              case "institution_city":
                                return (
                                  <LocationAutocomplete
                                    getLocation={handleInstitutionCitySelected}
                                    locationError={handleInstitutionError}
                                    Error={errors["institution_city"]}
                                  />
                                );
                              default:
                                return (
                                  <input
                                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                      errors[field.name] && touched[field.name]
                                        ? "border-red-500"
                                        : ""
                                    }`}
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    value={values[field.name]}
                                    onChange={handleFieldChange}
                                    placeholder={field.placeholder}
                                  />
                                );
                            }
                          })()}
                          {errors[field.name] &&
                            touched[field.name] &&
                            (() => {
                              switch (field.name) {
                                case "course_name":
                                  return (
                                    values["qualification_name"] && (
                                      <div className="text-red-500 text-xs mt-1">
                                        {errors["course_name"]}
                                      </div>
                                    )
                                  );
                                case "discipline_name":
                                  return (
                                    values["course_name"] &&
                                    values["qualification_name"] && (
                                      <div className="text-red-500 text-xs mt-1">
                                        {errors["discipline_name"]}
                                      </div>
                                    )
                                  );
                                case "discipline_id_other":
                                  return (
                                    // values["discipline_id"] === "-1" &&
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors["discipline_id_other"]}
                                    </div>
                                  );
                                default:
                                  return (
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors[field.name]}
                                    </div>
                                  );
                              }
                            })()}
                        </div>
                      </div>
                    </div>

                    {(() => {
                      switch (field.name) {
                        case "course_name":
                          return (
                            values["qualification_name"] && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "discipline_name":
                          return (
                            values["course_name"] &&
                            values["qualification_name"] && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "qualification_id_other":
                          return (
                            values["qualification_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "course_id_other":
                          return (
                            values["course_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "discipline_id_other":
                          return (
                            values["discipline_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "board_id_other":
                          return (
                            values["board_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        default:
                          return <hr className="border-gray-300 my-4" />;
                      }
                    })()}
                  </React.Fragment>
                ))}

                <div className="text-end">
                  <>
                    <button
                      className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleAddNewCancel}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                  </>
                </div>
              </form>
            </div>
          )}
        </div>

        {deleteModal && (
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to delete this qualification ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleQualificationDelete}
                >
                  {loading ? "DELETING..." : "DELETE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"QUALIFICATION INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Qualification;
