import React from "react";

const PublicitySkeleton = () => {
  return (
    <div className="flex min-h-screen pt-[65px]">
      {/* Sidebar Skeleton */}
      <div className="hidden md:block ">
        <p className="w-64 h-6 bg-gray-100"></p>
        <aside className="w-64   p-4 animate-pulse">
          <ul className="space-y-4">
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
            <li className="  h-6 bg-gray-300"></li>
          </ul>
        </aside>
      </div>

      {/* Main Content Skeleton */}
      <main className="flex-1 p-6 bg-gray-100 animate-pulse">
        <div className='className=" p-4  pt-0 lg:w-[80%] w-[97%] bg-white shadow'>
          <div className="grid grid-cols-1 gap-6  ">
            {/* Example of a card skeleton */}
            <div className="p-4 pb-0 pt-6 w-full ">
              <div className="h-8 bg-gray-300  w-1/3 mb-4"></div>
              <div className="h-20 bg-gray-300 rounded mb-4"></div>
            </div>
            {/* Additional skeleton cards */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default PublicitySkeleton;
