import React, { useState } from "react";
import Select from "react-select";

const DatePicker = ({ getDob, error, dob }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };
  const months = [
    { value: 1, label: "JAN" },
    { value: 2, label: "FEB" },
    { value: 3, label: "MAR" },
    { value: 4, label: "APR" },
    { value: 5, label: "MAY" },
    { value: 6, label: "JUN" },
    { value: 7, label: "JUL" },
    { value: 8, label: "AUG" },
    { value: 9, label: "SEP" },
    { value: 10, label: "OCT" },
    { value: 11, label: "NOV" },
    { value: 12, label: "DEC" },
  ];

  // Split the string into an array based on the hyphen delimiter
  const dobParts = dob?.split("-");

  // Extract the year, month, and day from the array
  const year = dobParts && dobParts[0];
  const month = dobParts && months[dobParts[1] - 1];
  const day = dobParts && dobParts[2];

  // Initial state for day, month, and year
  const [selectedDay, setSelectedDay] = useState(
    dob ? { value: day, label: day } : null
  );
  const [selectedMonth, setSelectedMonth] = useState(dob ? month : null);
  const [selectedYear, setSelectedYear] = useState(
    dob ? { value: year, label: year } : null
  );

  // Function to generate options for days
  const generateDayOptions = () => {
    const options = [];
    for (let i = 1; i <= 31; i++) {
      options.push({
        value: i,
        label: i.toString(),
      });
    }
    return options;
  };

  // Function to generate options for months
  const generateMonthOptions = () => {
    const months = [
      { value: 1, label: "JAN" },
      { value: 2, label: "FEB" },
      { value: 3, label: "MAR" },
      { value: 4, label: "APR" },
      { value: 5, label: "MAY" },
      { value: 6, label: "JUN" },
      { value: 7, label: "JUL" },
      { value: 8, label: "AUG" },
      { value: 9, label: "SEP" },
      { value: 10, label: "OCT" },
      { value: 11, label: "NOV" },
      { value: 12, label: "DEC" },
    ];
    return months;
  };

  // Function to generate options for years (100 years from 2 years back from the current year)
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let year = currentYear - 3; year >= currentYear - 101; year--) {
      options.push({
        value: year,
        label: year.toString(),
      });
    }
    return options;
  };

  // Function to handle change in day selection
  const handleDayChange = (selectedOption) => {
    setSelectedDay(selectedOption);
    passDate(selectedOption, selectedMonth, selectedYear);
  };

  // Function to handle change in month selection
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    passDate(selectedDay, selectedOption, selectedYear);
  };

  // Function to handle change in year selection
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    passDate(selectedDay, selectedMonth, selectedOption);
  };

  // Function to pass the selected date
  const passDate = (day, month, year) => {
    if (day && month && year) {
      getDob({
        dob_day: day.value,
        dob_month: month.value,
        dob_year: year.value,
      });
    }
  };

  return (
    <>
      <div className="flex">
        {/* Day */}
        <Select
          className={`w-1/3 mr-2 text-xs `}
          options={generateDayOptions()}
          onChange={handleDayChange}
          value={selectedDay}
          placeholder={"Day"}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: "7px",

            boxShadow: null,
            colors: {
              ...theme.colors,
              primary25: "#526754",
              primary: "#526754",
            },
          })}
        />
        {/* Month */}
        <Select
          className={`w-1/3 mr-2 text-xs`}
          options={generateMonthOptions()}
          onChange={handleMonthChange}
          value={selectedMonth}
          styles={customStyles}
          placeholder={"Month"}
          theme={(theme) => ({
            ...theme,
            borderRadius: "7px",
            boxShadow: null,
            colors: {
              ...theme.colors,
              primary25: "#526754",
              primary: "#526754",
            },
          })}
        />
        {/* Year */}
        <Select
          className={`w-1/3 text-xs`}
          options={generateYearOptions()}
          onChange={handleYearChange}
          value={selectedYear}
          placeholder={"Year"}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: "7px",
            boxShadow: null,
            colors: {
              ...theme.colors,
              primary25: "#526754",
              primary: "#526754",
            },
          })}
        />
        {/* Error message */}
      </div>
    </>
  );
};

export default DatePicker;
