import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateStepOneData } from "../../Redux/Actions/registerActions";
import PhoneForm from "../../components/Phone/Phone";
import { updateRegisterLevelOne, userCheck } from "../../api/registerApi";
import { PhoneNumberUtil } from "google-libphonenumber";
import { setAlert } from "../../Redux/Actions/alertActions";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};
const ViewForm = () => {
  const dispatch = useDispatch();

  // Access the form data from Redux store
  const stepOneData = useSelector((state) => state.register.stepOneData);
  const userToken = useSelector((state) => state.register.token);

  const [viewData, setViewData] = useState({
    firstname: stepOneData.firstname,
    lastname: stepOneData.lastname,
    email: stepOneData.email,
    dial_code: stepOneData.dial_code,
    mobile: stepOneData.mobile,
    username: stepOneData.username,
    country_code: stepOneData.country_code,
  });

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    mobile: Yup.string()
      .required("Phone number is required")
      .test("is-valid-phone", "Valid phone number required", function (value) {
        const dialCode = viewData.dial_code;
        const phoneNumber = `+${dialCode}${value}`;
        return isPhoneValid(phoneNumber);
      }),
    username: Yup.string()
      .matches(
        /^(?![0-9])[a-zA-Z0-9]+$/,
        "Username must contain only letters and numbers, and cannot start with a number"
      )
      .required("Studywin ID is required"),
  });

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const lowerCaseValue = name === "email" ? value.toLowerCase() : value;
    setViewData({
      ...viewData,
      [name]: lowerCaseValue,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const inputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const handlePhoneChange = ({ dial_code, mobile, country_code }) => {

    setViewData({
      ...viewData,
      dial_code: dial_code,
      mobile: mobile,
      country_code: country_code,
    });
    // Clear mobile number error when the user starts typing
    if (errors.mobile) {
      setErrors({ ...errors, mobile: "" });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (errors.username || errors.mobile || errors.email) {
        return;
      }
      setLoading(true);
      
      await validationSchema.validate(viewData, { abortEarly: false });

      const response = await updateRegisterLevelOne(viewData, userToken);

      
      if (response.status === 1) {
        dispatch(updateStepOneData(response.data));
        dispatch(setAlert(response.message, "success"));
        setEditMode(false);
        setLoading(false);
        setErrors({});
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        // If validation fails, set errors state with validation errors
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setErrors({});
  };
  const handleCancelClick = () => {
    setErrors({});
    setViewData(stepOneData);
    setEditMode(false);
  };

  const handleViewClick = () => {
    setViewMode((prev) => !prev);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value) {
      checkUser({ [name]: value }, userToken, event.target);
    }
  };

  const checkUser = async (userData, userToken, target) => {
    try {
      const response = await userCheck(userData, userToken);
      const key = Object.keys(userData)[0];
      if (response.data.status) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: response.data.errors[key],
        }));
        target.focus();
      }
    } catch (error) {
      console.error("Error checking user duplicate", error);
    }
  };

  return (
    <div>
      <div className="text-end mb-3 md:hidden">
        <button
          className="border text-xs border-custom text-custom hover:text-lime-950 hover:border-lime-950 rounded-full    py-1 px-6 focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleViewClick}
        >
          {viewMode ? "HIDE" : "VIEW"}
        </button>
      </div>

      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={`md:block ${!viewMode && "hidden"} mb-4`}
      >
        {editMode ? (
          <>
            {" "}
            <div className="mb-4">
              <label
                className={`block text-gray-700 text-xs mb-2  `}
                htmlFor="firstname"
              >
                First Name{editMode && <span className="text-red-500">*</span>}
              </label>
              <input
                className={`border-gray-400  text-xs bg-gray-100  w-full focus:outline-none ${
                  errors.firstname ? "border-red-500" : ""
                } ${editMode ? "border rounded-lg px-4 py-2" : "border-b "}`}
                type="text"
                id="firstname"
                name="firstname"
                value={viewData.firstname}
                onChange={handleInputChange}
                placeholder="Enter your first name"
                readOnly={!editMode}
              />
              {errors.firstname && (
                <p className="text-red-500 text-xs mt-1">{errors.firstname}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className={`block text-gray-700 text-xs mb-2  `}
                htmlFor="lastname"
              >
                Last Name {editMode && <span className="text-red-500">*</span>}
              </label>

              <input
                className={`border-gray-400 text-xs  bg-gray-100  w-full focus:outline-none ${
                  errors.lastname ? "border-red-500" : ""
                } ${editMode ? "border rounded-lg px-4 py-2" : "border-b "}`}
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Enter your last name"
                value={viewData.lastname}
                onChange={handleInputChange}
              />
              {errors.lastname && (
                <p className="text-red-500 text-xs mt-1">{errors.lastname}</p>
              )}
            </div>{" "}
          </>
        ) : (
          <div className="mb-4">
            <label
              className={`block text-gray-700 text-xs mb-2 `}
              htmlFor="number"
            >
              Full Name
              {editMode && <span className="text-red-500">*</span>}
            </label>
            <p
              className={`border-gray-400 border-b text-xs bg-gray-100 w-full focus:outline-none `}
            >
              {`${viewData.firstname} ${viewData.lastname}`}
            </p>
          </div>
        )}

        <div className="mb-4">
          {/* Conditionally render number field based on editMode */}
          {!editMode ? (
            <>
              <label
                className={`block text-gray-700 text-xs mb-2 `}
                htmlFor="number"
              >
                Number
                {editMode && <span className="text-red-500">*</span>}
              </label>
              <p
                className={`border-gray-400 border-b text-xs bg-gray-100 w-full focus:outline-none `}
              >
                {`+${viewData.dial_code}-${viewData.mobile}`}
              </p>
            </>
          ) : (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-xs mb-2"
                htmlFor="country"
              >
                Mobile No <span className="text-red-500">*</span>
              </label>
              {/* Country Dropdown */}
              <PhoneForm
                onPhoneChange={handlePhoneChange}
                inputPhoneRef={phoneInputRef}
                gray={true}
                Error={errors.mobile}
                checkUser={checkUser}
                val={{
                  country_code: viewData.country_code,
                  mobile: viewData.mobile,
                  dial_code: viewData.dial_code,
                }}
              />
              {errors.mobile && (
                <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
              )}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label
            className={`block text-gray-700 text-xs mb-2  `}
            htmlFor="email"
          >
            Email Id
          </label>
          <input
            className={`border-gray-400 text-xs  bg-gray-100  w-full focus:outline-none ${
              errors.email ? "border-red-500" : ""
            } ${editMode ? "border rounded-lg px-4 py-2" : "border-b"}`}
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={viewData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            ref={inputRef}
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>
        <div className="mb-4">
          <label className={`block text-gray-700 text-xs mb-2 `} htmlFor="id">
            Studywin Id {editMode && <span className="text-red-500">*</span>}
          </label>
          <input
            className={`border-gray-400 text-xs bg-gray-100  w-full focus:outline-none ${
              errors.username ? "border-red-500" : ""
            } ${editMode ? "border rounded-lg px-4 py-2" : "border-b "}`}
            type="text"
            id="username"
            name="username"
            placeholder="Enter your studywin id (username)"
            value={viewData.username}
            onChange={handleInputChange}
            onBlur={handleBlur}
            ref={inputRef}
          />
          {errors.username && (
            <p className="text-red-500 text-xs mt-1">{errors.username}</p>
          )}
        </div>
        {/* Add other input fields */}
        {editMode ? (
          <>
            <button
              className="bg-[#5E8862]  hover:bg-[#4F7552] mr-3  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Saving.." : "SAVE"}
            </button>
            <button
              className="border-custom border mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleCancelClick}
            >
              CANCEL
            </button>
          </>
        ) : (
          <>
            <p className="text-gray-700 text-xs mt-6 mb-2">
              Feel free to update any necessary details if needed
            </p>
            <button
              className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleEditClick}
            >
              EDIT
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ViewForm;
