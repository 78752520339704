import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "../../components/Accordion/Accordion";
import { IoLanguage } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import { otherLanguagesData } from "../../constants/fieldData";
import { FaMicrophoneAlt, FaPen, FaPlus } from "react-icons/fa";
import Sidebar from "../../components/Sidebar/Sidebar";

import LanguageSelect from "../../components/Select/Language";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLanguagesData,
  getOtherLanguagesDetails,
  updateOtherLanguagesData,
} from "../../api/otherLanguages";
import { FiBookOpen, FiMenu } from "react-icons/fi";
import KnowledgeSelect from "../../components/Select/Knowledge";
import { setAlert } from "../../Redux/Actions/alertActions";
import Modal from "../../components/Modal/Modal";
import { BsExclamationCircleFill } from "react-icons/bs";
import { getMenuState } from "../../api/sideBarApi";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import AccordionSkeleton from "../../components/Skeleton/AccordionSkeleton";
import { getSkillList } from "../../api/englishApi";
import { getLanguageList } from "../../api/registerApi";
import { BiSolidHelpCircle } from "react-icons/bi";

const OtherLanguages = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [addNew, setAddNew] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLanguage, setDeleteLanguage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [closeAccordion, setCloseAccordion] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [skillOptions, setSkillOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchOtherLanguageDetails = async (token) => {
      try {
        const details = await getOtherLanguagesDetails(token);
        if (details.status === 1) {
          setLanguages(details.data);
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          setSkeletonLoad(false);
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to fetch english details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error basic details :", error);
        setSkeletonLoad(false);
      }
    };

    fetchOtherLanguageDetails(token);
  }, [token]);

  useEffect(() => {
    const fetchSkills = async (token) => {
      try {
        const skillData = await getSkillList(token);
        if (skillData.status === 1) {
          const options = skillData.data.map((skills) => ({
            value: skills.value,
            label: skills.label,
          }));
          setSkillOptions(options);
        } else {
          setSkillOptions([]);
        }
      } catch (error) {
        console.error("Failed to get knowledge list");
      }
    };

    fetchSkills(token);
  }, [token, dispatch]);

  useEffect(() => {
    const fetchLanguages = async (token) => {
      try {
        const languageData = await getLanguageList(token, "0");
        if (languageData.status === 1) {
          const options = languageData.data.map((language) => ({
            value: language.value,
            label: language.label,
          }));
          setLanguageOptions(options);
        } else {
          setLanguageOptions([]);
        }
      } catch (error) {
        console.error("Failed to get language list", error);
      }
    };

    fetchLanguages(token);
  }, [token, dispatch]);

  const handleEdit = (index) => {
    formik.resetForm();
    formikEdit.setValues(languages[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    setDeleteLanguage(index);
    setDeleteModal(true);
  };

  const handleLanguageDelete = async () => {
    const delete_id = languages[deleteLanguage].language_id;
    try {
      setLoading(true);

      const response = await deleteLanguagesData(
        {
          language_id: delete_id,
        },
        token
      );

      if (response.status === 1) {
        setLoading(false);
        setLanguages(response.data);
        dispatch(setAlert(response.message, "success"));

        setDeleteModal(false);
        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error dleteing language ", error.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
  };

  const handleAddNewCancel = () => {
    setAddNew(false);
    formik.resetForm();
  };

  const handleAddNew = () => {
    setAddNew(true);
    setCloseAccordion(true);
    setTimeout(() => {
      setCloseAccordion(false);
    }, 200);
  };

  const validationSchema = Yup.object().shape({
    language_name: Yup.string().required("Language name is required"),
    speak_skill_name: Yup.string().required("Speak skill is required"),
    read_skill_name: Yup.string().required("Read skill is required"),
    write_skill_name: Yup.string().required("Write skill is required"),
  });

  const handleLanguageChange = (language) => {
    formik.setValues({
      ...formik.values,
      language_name: language?.label,
      language_id: language?.value,
    });

    formik.setErrors({ ...errors, language_name: "" });
  };

  const handleSpeakLevel = (knowledge) => {
    formik.setValues({
      ...formik.values,
      speak_skill_name: knowledge?.label,
      speak_skill: knowledge?.value,
    });

    formik.setErrors({ ...errors, speak_skill_name: "" });
  };

  const handleReadLevel = (knowledge) => {
    formik.setValues({
      ...formik.values,
      read_skill_name: knowledge?.label,
      read_skill: knowledge?.value,
    });
    formik.setErrors({ ...errors, read_skill_name: "" });
  };

  const handleWriteLevel = (knowledge) => {
    formik.setValues({
      ...formik.values,
      write_skill_name: knowledge?.label,
      write_skill: knowledge?.value,
    });

    formik.setErrors({ ...errors, write_skill_name: "" });
  };

  // Function to handle form submission when adding a new item
  const onSubmitAdd = async (values, { setErrors }) => {
    try {
      setLoading(true);

      const response = await updateOtherLanguagesData(values, token);

      if (response.status === 1) {
        setLoading(false);
        setLanguages(response.data);
        dispatch(setAlert(response.message, "success"));

        setAddNew(false);
        formikAdd.resetForm();

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  // Function to handle form submission when editing an existing item
  const onSubmitEdit = async (values, { setErrors }) => {
    try {
      setLoading(true);

      const response = await updateOtherLanguagesData(values, token);

      if (response.status === 1) {
        setLoading(false);
        setLanguages(response.data);
        dispatch(setAlert(response.message, "success"));
        setEditIndex(null);
        formikAdd.resetForm();

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
    }
  };

  // For adding new item
  const initialValuesAdd = {};
  otherLanguagesData.forEach((field) => {
    initialValuesAdd[field.name] = "";
  });

  const formikAdd = useFormik({
    initialValues: initialValuesAdd,
    validationSchema: validationSchema,
    onSubmit: onSubmitAdd,
    validateOnChange: false,
    validateOnBlur: false,
  });

  // For editing existing item
  const initialValuesEdit = {};
  otherLanguagesData.forEach((field) => {
    initialValuesEdit[field.name] = "";
  });

  const formikEdit = useFormik({
    initialValues: initialValuesEdit,
    validationSchema: validationSchema,
    onSubmit: onSubmitEdit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  // Selecting the correct formik object based on whether adding new or editing
  const formik = addNew ? formikAdd : formikEdit;

  const { errors, touched } = formik;

  if (skeletonLoad) {
    return <AccordionSkeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center overflow-x-hidden  md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>
        <div className="lg:w-[65%] md:w-full w-[97%] mb-4 md:ml-2 lg:ml-6 ml-0">
          <div className="mb-4 md:mb-6 bg-white w-full shadow-md md:flex items-center p-6 md:p-10 pb-4">
            <div>
              <div className="flex items-center">
                <h2 className="text-lg text-custom">OTHER LANGUAGES</h2>
                <button
                  className="ml-2   flex items-center text-blue-500 text-xs"
                  onClick={() => setHelp(true)}
                  title="Help"
                >
                  <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                </button>
              </div>

              <div className="bg-custom w-12 mt-2 h-[2px] mb-2"></div>
            </div>
            {!addNew && (
              <button
                className="ml-auto flex items-center text-blue-500 text-xs"
                onClick={handleAddNew}
              >
                <FaPlus className="mr-2" />
                Add new
              </button>
            )}
          </div>

          {languages.map((language, index) => (
            <div className="mt-6" key={index}>
              <Accordion>
                <AccordionItem
                  title={language.language_name}
                  onEdit={() => handleEdit(index)}
                  onDelete={() => handleDelete(index)}
                  firstAccordion={0}
                  closeAll={closeAccordion}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="md:ps-8 ps-6 pe-4"
                  >
                    {otherLanguagesData.map((field) => (
                      <React.Fragment key={field.name}>
                        <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                          <label className="text-gray-500 text-xs lg:w-[250px]">
                            {field.label}
                            {editIndex === index && field.required && (
                              <span className="text-red-500">*</span>
                            )}
                          </label>

                          <div className="flex items-center flex-1 mt-3 lg:mt-0">
                            {editIndex !== index ? (
                              <>
                                {(() => {
                                  switch (field.name) {
                                    case "language_name":
                                      return (
                                        <IoLanguage className="text-custom mr-3 text-[15px]" />
                                      );

                                    case "speak_skill_name":
                                      return (
                                        <FaMicrophoneAlt className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "read_skill_name":
                                      return (
                                        <FiBookOpen className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "write_skill_name":
                                      return (
                                        <FaPen className="text-custom mr-3 text-[15px]" />
                                      );

                                    default:
                                      return "";
                                  }
                                })()}

                                <span className="text-xs font-medium">
                                  {language[field.name]}
                                </span>
                              </>
                            ) : (
                              <div className="text-xs w-full">
                                {(() => {
                                  switch (field.name) {
                                    case "language_name":
                                      return (
                                        <LanguageSelect
                                          is_default={"0"}
                                          options={languageOptions}
                                          getLanguage={handleLanguageChange}
                                          language={{
                                            value: language.language_id,
                                            label: language.language_name,
                                          }}
                                          error={errors["language_name"]}
                                        />
                                      );
                                    case "speak_skill_name":
                                      return (
                                        <KnowledgeSelect
                                          getKnowledge={handleSpeakLevel}
                                          val={{
                                            value: language.speak_skill,
                                            label: language.speak_skill_name,
                                          }}
                                          error={errors["speak_skill_name"]}
                                          options={skillOptions}
                                        />
                                      );
                                    case "read_skill_name":
                                      return (
                                        <KnowledgeSelect
                                          getKnowledge={handleReadLevel}
                                          val={{
                                            value: language.read_skill,
                                            label: language.read_skill_name,
                                          }}
                                          error={errors["read_skill_name"]}
                                          options={skillOptions}
                                        />
                                      );
                                    case "write_skill_name":
                                      return (
                                        <KnowledgeSelect
                                          getKnowledge={handleWriteLevel}
                                          val={{
                                            value: language.write_skill,
                                            label: language.write_skill_name,
                                          }}
                                          error={errors["write_skill_name"]}
                                          options={skillOptions}
                                        />
                                      );
                                    default:
                                      return "";
                                  }
                                })()}
                                {errors[field.name] && touched[field.name] && (
                                  <div className="text-red-500 text-xs mt-1">
                                    {errors[field.name]}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <hr className="border-gray-300 my-4" />
                      </React.Fragment>
                    ))}

                    {editIndex === index && (
                      <div className="text-end">
                        <>
                          <button
                            className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCancel}
                          >
                            CANCEL
                          </button>
                          <button
                            className="bg-[#5E8862]  hover:bg-custom  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Saving..." : "SAVE"}
                          </button>
                        </>
                      </div>
                    )}
                  </form>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
          {!addNew && languages.length > 0 && (
            <div className="w-full flex justify-center">
              <div className="bg-white shadow-lg text-center flex   w-max my-6 text-xs py-3   rounded-full    px-6 focus:outline-none focus:shadow-outline">
                Do you know more languages ?
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2 ml-4" />
                  Add new
                </button>
              </div>
            </div>
          )}

          {addNew && (
            <div className="bg-white shadow-lg mt-6 pt-10">
              <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="px-10 pb-10"
              >
                {otherLanguagesData.map((field, index) => (
                  <React.Fragment key={index}>
                    <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                      <label className="text-gray-500 text-xs lg:w-[250px]">
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>

                      <div className="text-xs w-full mt-3 lg:mt-0">
                        {(() => {
                          switch (field.name) {
                            case "language_name":
                              return (
                                <LanguageSelect
                                  is_default={"0"}
                                  options={languageOptions}
                                  getLanguage={handleLanguageChange}
                                  error={errors["language_name"]}
                                />
                              );
                            case "speak_skill_name":
                              return (
                                <KnowledgeSelect
                                  getKnowledge={handleSpeakLevel}
                                  error={errors["speak_skill_name"]}
                                  options={skillOptions}
                                />
                              );
                            case "read_skill_name":
                              return (
                                <KnowledgeSelect
                                  getKnowledge={handleReadLevel}
                                  error={errors["read_skill_name"]}
                                  options={skillOptions}
                                />
                              );
                            case "write_skill_name":
                              return (
                                <KnowledgeSelect
                                  getKnowledge={handleWriteLevel}
                                  error={errors["write_skill_name"]}
                                  options={skillOptions}
                                />
                              );
                            default:
                              return "";
                          }
                        })()}
                        {errors[field.name] && touched[field.name] && (
                          <div className="text-red-500 text-xs mt-1">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    </div>

                    <hr className="border-gray-300 my-4" />
                  </React.Fragment>
                ))}

                <div className="text-end">
                  <>
                    <button
                      className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleAddNewCancel}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                  </>
                </div>
              </form>
            </div>
          )}
        </div>

        {deleteModal && (
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to delete this language ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleLanguageDelete}
                >
                  {loading ? "DELETING..." : "DELETE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"OTHER LANGUAGES INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default OtherLanguages;
