import axios from "axios";
import {
  FETCH_ENGLISH_KNOWLEDGE,
  FETCH_LANGUAGE_SKILL,
  UPDATE_ENGLISH_DATA,
} from "../config/config";

// get english details get api call
export const getEnglishDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_ENGLISH_KNOWLEDGE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get english knowledge", error);
    throw error;
  }
};

//update ENGLISH data post api call
export const updateEnglishData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_ENGLISH_DATA, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update english data:", error);
    throw error;
  }
};

// get skill level list api call
export const getSkillList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_LANGUAGE_SKILL,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching skill list:", error);
    throw error;
  }
};
