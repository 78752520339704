import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaPhoneAlt,
  FaRegEdit,
  FaRegUser,
  FaWhatsapp,
} from "react-icons/fa";
import * as Yup from "yup";
import Sidebar from "../../components/Sidebar/Sidebar";
import { IoLanguage, IoLocationOutline, IoMaleFemale } from "react-icons/io5";
import {
  MdOutlineBloodtype,
  MdOutlineEmail,
  MdOutlineGroup,
} from "react-icons/md";
import DatePicker from "../../components/Select/Date";
import LanguageSelect from "../../components/Select/Language";
import { GiDiamondRing, GiRotaryPhone } from "react-icons/gi";
import PhoneForm from "../../components/Phone/Phone";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
import LandPhoneForm from "../../components/Phone/LandPhone";
import CountrySelect from "../../components/Select/Country";
import MarriageSelect from "../../components/Select/Marriage";
import GenderSelect from "../../components/Select/Gender";
import BloodGroupSelect from "../../components/Select/BloodGroup";
import ReligionSelect from "../../components/Select/Religion";
import JobSelect from "../../components/Select/Job";
import { personalData } from "../../constants/fieldData";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  getPersonalDetails,
  updatePersonalData,
} from "../../api/personalApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getMenuState } from "../../api/sideBarApi";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import Skeleton from "../../components/Skeleton/Skeleton";
import { BiSolidHelpCircle } from "react-icons/bi";
import Modal from "../../components/Modal/Modal";
import { FiMenu } from "react-icons/fi";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [personalValue, setPersonalValue] = useState({});
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [help, setHelp] = useState(false);

  useEffect(() => {
    const fetchPersonalDetails = async (token) => {
      try {
        const details = await getPersonalDetails(token);

        if (details.status === 1) {
          setPersonalValue(details.data);
          setSkeletonLoad(false);
        } else {
          console.error("Failed to fetch personal details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error personal details :", error);
        setSkeletonLoad(false);
      }
    };

    fetchPersonalDetails(token);
  }, [token]);

  useEffect(() => {
    const fetchCountryList = async (token) => {
      try {
        const countryData = await getCountryList(token, "2");
        if (countryData.status === 1) {
          const options = countryData.data.map((country) => ({
            value: country.value,
            label: country.label,
            dial_code: country.dial_code,
          }));
          setCountryOptions(options);
        } else {
          setCountryOptions([]);
        }
      } catch (error) {
        console.error("Failed to get country list", error);
      }
    };

    fetchCountryList(token);
  }, [token]);

  // For adding new item
  const initialValues = {};
  personalData.forEach((field) => {
    initialValues[field.name] = personalValue[field.name];
  });

  const favoriteJobSchema = Yup.object().shape({
    // Define the structure of each favorite job object
    value: Yup.string().required(),
    label: Yup.string().required(),
  });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().trim().required("First Name is required"),
    lastname: Yup.string().trim().required("Last Name is required"),
    fathers_name: Yup.string().trim().required("Father's Name is required"),
    mothers_name: Yup.string().trim().required("Mother's Name is required"),
    place_of_birth: Yup.string().trim().required("Place of Birth is required"),
    dob: Yup.string()
      .trim()
      .required("Date of Birth is required")
      .test("is-valid-date", "Invalid date", function (value) {
        // Validate if the date is a valid calendar date
        if (!value) return true;
        const parts = value.split("-");
        const day = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[0], 10);
        const date = new Date(year, month, day);
        return (
          date.getDate() === day &&
          date.getMonth() === month &&
          date.getFullYear() === year
        );
      }),

    country_of_birth_name: Yup.string()
      .trim()
      .required("Country of Birth is required"),
    nationality_name: Yup.string().trim().required("Nationality is required"),
    mother_tongue_name: Yup.string()
      .trim()
      .required("Mother Tongue is required"),
    marital_status_name: Yup.string()
      .trim()
      .required("Marital Status is required"),
    spouse_name: Yup.string().when(
      "marital_status_name",
      (maritalStatus, schema) => {
        return maritalStatus[0] === "MARRIED"
          ? schema.required("Spouse's Name is required")
          : schema.notRequired();
      }
    ),
    gender_name: Yup.string().trim().required("Gender is required"),
    whatsapp_number: Yup.string()
      .notRequired()
      .test("is-valid-phone", "Valid phone number required", function (value) {
        if (!value) return true;
        const dialCode = values.whatsapp_dial_code;
        const phoneNumber = `+${dialCode}${value}`;
        return isPhoneValid(phoneNumber);
      }),
    secondary_mob_number: Yup.string()
      .notRequired()
      .test("is-valid-phone", "Valid phone number required", function (value) {
        if (!value) return true;
        const dialCode = values.secondary_dial_code;
        const phoneNumber = `+${dialCode}${value}`;
        return isPhoneValid(phoneNumber);
      }),
    secondary_email: Yup.string()
      .notRequired()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    favorite_job: Yup.array()
      .of(favoriteJobSchema)
      .min(1, "At least one favorite job is required")
      .required("At least one favorite job is required"),
  });
  const handleBirthCountryChange = (country) => {
    formik.setValues({
      ...formik.values,
      country_of_birth_name: country?.label,
      country_of_birth: country?.value,
    });
    formik.setErrors({ ...errors, country_of_birth_name: "" });
  };

  const handleNationalityChange = (country) => {
    formik.setValues({
      ...formik.values,
      whatsapp_dial_code: country?.dial_code,
      secondary_dial_code: country?.dial_code,
      land_dial_code: country?.dial_code,
      nationality_name: country?.label,
      nationality: country?.value,
      whatsapp_country_code: country?.value,
      secondary_mob_country_code: country?.value,
      land_country_code: country?.value,
    });
    formik.setErrors({ ...errors, nationality_name: "" });
  };

  const handleMotherTongue = (language) => {
    formik.setValues({
      ...formik.values,
      mother_tongue_name: language?.label,
      mother_tongue: language?.value,
    });
    formik.setErrors({ ...errors, mother_tongue_name: "" });
  };

  const handleMarriage = (marriage) => {
    formik.setValues({
      ...formik.values,
      marital_status_name: marriage?.label,
      marital_status: marriage?.value,
    });
    formik.setErrors({ ...errors, marital_status_name: "" });
  };

  const handleDob = (dob) => {
    formik.setValues({
      ...formik.values,
      dob: `${dob.dob_year}-${dob.dob_month}-${dob.dob_day}`,
    });
    formik.setErrors({ ...errors, dob: "" });
  };
  const handleGender = (gender) => {
    formik.setValues({
      ...formik.values,
      gender_name: gender?.label,
      gender: gender?.value,
    });

    formik.setErrors({ ...errors, gender_name: "" });
  };

  const handleBlood = (blood) => {
    formik.setValues({
      ...formik.values,
      blood_group_name: blood?.label,
      blood_group_id: blood?.value,
    });
  };

  const handleReligion = (religion) => {
    formik.setValues({
      ...formik.values,
      religion_name: religion?.label,
      religion_id: religion?.value,
    });
  };

  const handleFavJobs = (jobs) => {
    formik.setValues({
      ...formik.values,
      favorite_job: jobs,
    });
    formik.setErrors({ ...errors, favorite_job: "" });
  };

  const handleWhatsAppChange = ({ dial_code, mobile, country_code }) => {
    formik.setValues({
      ...formik.values,
      whatsapp_country_code: country_code,
      whatsapp_dial_code: dial_code,
      whatsapp_number: mobile,
    });
    formik.setErrors({ ...errors, whatsapp_number: "" });
  };

  const handleSecondPhoneChange = ({ dial_code, mobile, country_code }) => {
    formik.setValues({
      ...formik.values,
      secondary_mob_country_code: country_code,
      secondary_dial_code: dial_code,
      secondary_mob_number: mobile,
    });
    formik.setErrors({ ...errors, secondary_mob_number: "" });
  };

  const handleLandPhoneChange = ({
    dial_code,
    mobile,
    country_code,
    area_code,
  }) => {
    formik.setValues({
      ...formik.values,
      land_country_code: country_code,
      land_dial_code: dial_code,
      land_area_number: mobile,
      land_area_code: area_code,
    });
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      setLoading(true);

      const response = await updatePersonalData(values, token);

      if (response.status === 1) {
        setPersonalValue(response.data);

        dispatch(setAlert(response.message, "success"));
        setLoading(false);
        setIsEditMode(false);

        //fetch the menu state
        const details = await getMenuState(token);
        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the field value
    formik.handleChange(e);

    // Clear errors for the field
    formik.setErrors({
      ...formik.errors,
      [name]: undefined,
    });
  };

  const { values, errors, touched } = formik;
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    formik.setValues({ ...formik.values, ...personalValue });
  };
  const handleCancelClick = () => {
    setIsEditMode(false);
    formik.resetForm();
  };

  if (skeletonLoad) {
    return <Skeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center overflow-x-hidden md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>

        <div className=" lg:w-[65%] md:w-full  w-[97%] mb-4 ">
          <div className="bg-white shadow-lg  w-full lg:ml-6 md:mx-2 mx-0 p-6 md:p-10 mb-4">
            <div className="mb-4 md:mb-6 md:flex items-center justify-between">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">PERSONAL DETAILS</h2>
                  <button
                    className="ml-2   flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 mt-2 h-[2px] mb-2"></div>
              </div>
              {!isEditMode && (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={toggleEditMode}
                >
                  <FaRegEdit className="mr-2" />
                  Edit
                </button>
              )}
            </div>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {personalData.map((field) =>
                // Conditionally render the entire fragment based on marital status
                (() => {
                  if (
                    field.name === "spouse_name" &&
                    values.marital_status_name !== "MARRIED"
                  ) {
                    return null;
                  }
                  return (
                    <React.Fragment key={field.name}>
                      <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                        <label className="text-gray-500 text-xs lg:w-[250px]">
                          {field.label}
                          {isEditMode && field.required && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        <div className="flex items-center flex-1 mt-3 lg:mt-0">
                          {!isEditMode ? (
                            <>
                              {(() => {
                                switch (field.name) {
                                  case "dob":
                                    return (
                                      <FaCalendarAlt className="text-custom mr-3 text-[15px]" />
                                    );

                                  case "place_of_birth":
                                  case "country_of_birth_name":
                                  case "nationality_name":
                                    return (
                                      <IoLocationOutline className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "mother_tongue_name":
                                    return (
                                      <IoLanguage className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "marital_status_name":
                                    return (
                                      <GiDiamondRing className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "gender_name":
                                    return (
                                      <IoMaleFemale className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "blood_group_name":
                                    return (
                                      <MdOutlineBloodtype className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "religion_name":
                                    return (
                                      <MdOutlineGroup className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "religion_id_other":
                                    return "";
                                  case "whatsapp_number":
                                    return (
                                      <FaWhatsapp className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "land_area_number":
                                    return (
                                      <GiRotaryPhone className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "secondary_email":
                                    return (
                                      <MdOutlineEmail className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "secondary_mob_number":
                                    return (
                                      <FaPhoneAlt className="text-custom mr-3 text-[15px]" />
                                    );
                                  case "favorite_job":
                                    return (
                                      <PiSuitcaseSimpleFill className="text-custom mr-3 text-[15px]" />
                                    );

                                  default:
                                    return (
                                      <FaRegUser className="text-custom mr-3 text-[15px]" />
                                    );
                                }
                              })()}

                              <span className="text-xs font-medium ">
                                {(() => {
                                  switch (field.name) {
                                    case "favorite_job":
                                      return personalValue.favorite_job_name;
                                    case "whatsapp_number":
                                      return personalValue.whatsapp;
                                    case "land_area_number":
                                      return personalValue.land_phone;
                                    case "secondary_mob_number":
                                      return personalValue.secondary_mob;
                                    case "religion_id_other":
                                      return "";

                                    case "dob":
                                      return personalValue.dob_formated;
                                    default:
                                      return personalValue[field.name];
                                  }
                                })()}
                              </span>
                            </>
                          ) : (
                            <div className="text-xs w-full">
                              {(() => {
                                switch (field.name) {
                                  case "dob":
                                    return (
                                      <DatePicker
                                        getDob={handleDob}
                                        dob={values.dob}
                                        error={errors["dob"]}
                                      />
                                    );
                                  case "country_of_birth_name":
                                    return (
                                      <CountrySelect
                                        getCountry={handleBirthCountryChange}
                                        country={{
                                          value: values.country_of_birth,
                                          label: values.country_of_birth_name,
                                        }}
                                        error={errors["country_of_birth_name"]}
                                        options={countryOptions}
                                      />
                                    );
                                  case "nationality_name":
                                    return (
                                      <CountrySelect
                                        getCountry={handleNationalityChange}
                                        options={countryOptions}
                                        country={{
                                          value: values.nationality,
                                          label: values.nationality_name,
                                        }}
                                        error={errors["nationality_name"]}
                                      />
                                    );
                                  case "mother_tongue_name":
                                    return (
                                      <LanguageSelect
                                        getLanguage={handleMotherTongue}
                                        language={{
                                          value: values.mother_tongue,
                                          label: values.mother_tongue_name,
                                        }}
                                        error={errors["mother_tongue_name"]}
                                      />
                                    );
                                  case "marital_status_name":
                                    return (
                                      <MarriageSelect
                                        getMarriage={handleMarriage}
                                        marriage={{
                                          value: values.marital_status,
                                          label: values.marital_status_name,
                                        }}
                                        error={errors["marital_status_name"]}
                                      />
                                    );
                                  case "gender_name":
                                    return (
                                      <GenderSelect
                                        getGender={handleGender}
                                        gender={{
                                          value: values.gender,
                                          label: values.gender_name,
                                        }}
                                        error={errors["gender_name"]}
                                      />
                                    );
                                  case "blood_group_name":
                                    return (
                                      <BloodGroupSelect
                                        getBlood={handleBlood}
                                        blood={{
                                          value: values.blood_group_id,
                                          label: values.blood_group_name,
                                        }}
                                        error={errors["blood_group_name"]}
                                      />
                                    );
                                  case "religion_name":
                                    return (
                                      <ReligionSelect
                                        getReligion={handleReligion}
                                        religion={{
                                          value: values.religion_id,
                                          label: values.religion_name,
                                        }}
                                        error={errors["religion_name"]}
                                      />
                                    );
                                  case "religion_id_other":
                                    return (
                                      values["religion_id"] === "-1" && (
                                        <input
                                          className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                            errors[field.name] &&
                                            touched[field.name]
                                              ? "border-red-500"
                                              : ""
                                          }`}
                                          type="text"
                                          id={field.name}
                                          name={field.name}
                                          value={values[field.name]}
                                          onChange={handleFieldChange}
                                          placeholder={field.placeholder}
                                        />
                                      )
                                    );
                                  case "whatsapp_number":
                                    return (
                                      <PhoneForm
                                        onPhoneChange={handleWhatsAppChange}
                                        val={{
                                          country_code:
                                            values.whatsapp_country_code,
                                          dial_code: values.whatsapp_dial_code,
                                          mobile: values.whatsapp_number,
                                        }}
                                        Error={errors["whatsapp_number"]}
                                      />
                                    );
                                  case "land_area_number":
                                    return (
                                      <LandPhoneForm
                                        onPhoneChange={handleLandPhoneChange}
                                        val={{
                                          country_code:
                                            values.land_country_code,
                                          dial_code: values.land_dial_code,
                                          mobile: values.land_area_number,
                                          area_code: values.land_area_code,
                                        }}
                                        Error={errors["land_area_number"]}
                                      />
                                    );
                                  case "secondary_mob_number":
                                    return (
                                      <PhoneForm
                                        onPhoneChange={handleSecondPhoneChange}
                                        val={{
                                          country_code:
                                            values.secondary_mob_country_code,
                                          dial_code: values.secondary_dial_code,
                                          mobile: values.secondary_mob_number,
                                        }}
                                        Error={errors["secondary_mob_number"]}
                                      />
                                    );
                                  case "favorite_job":
                                    return (
                                      <JobSelect
                                        getJobs={handleFavJobs}
                                        jobs={personalValue.favorite_job}
                                        error={errors["favorite_job"]}
                                      />
                                    );

                                  default:
                                    return (
                                      <input
                                        className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                          errors[field.name] &&
                                          touched[field.name]
                                            ? "border-red-500"
                                            : ""
                                        }`}
                                        type="text"
                                        id={field.name}
                                        name={field.name}
                                        value={values[field.name]}
                                        onChange={handleFieldChange}
                                        placeholder={field.placeholder}
                                      />
                                    );
                                }
                              })()}
                              {isEditMode && (
                                <div className="text-xs text-red-500 mt-1">
                                  {errors[field.name] &&
                                    touched[field.name] &&
                                    errors[field.name]}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {(() => {
                        switch (field.name) {
                          case "religion_id_other":
                            return (
                              values["religion_id"] === "-1" &&
                              isEditMode && (
                                <hr className="border-gray-300 my-4" />
                              )
                            );
                          default:
                            return <hr className="border-gray-300 my-4" />;
                        }
                      })()}
                    </React.Fragment>
                  );
                })()
              )}

              <div className="text-end">
                {isEditMode && (
                  <>
                    <button
                      className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleCancelClick}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving.." : "SAVE"}
                    </button>
                  </>
                )}
              </div>
            </form>

            {!isEditMode && (
              <div className="text-end">
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  onClick={toggleEditMode}
                >
                  EDIT
                </button>
              </div>
            )}
          </div>
        </div>
        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"PERSONAL DETAILS INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {personalValue.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: personalValue.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default PersonalDetails;
