import axios from "axios";
import {
  FORGOT_PASSWORD_SEND_OTP,
  FORGOT_PASSWORD_VERIFY_OTP,
  GENERATE_CAPTCHA,
  LOGIN_USER,
  RESET_PASSWORD,
  VALIDATE_CAPTCHA,
} from "../config/config";

// generate captcha get api call
export const generateCaptcha = async () => {
  try {
    const response = await axios.get(GENERATE_CAPTCHA);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error generating captcha:", error);
    throw error;
  }
};

// validate captcha post api call
export const validateCaptcha = async (loginData) => {
  try {
    const response = await axios.post(VALIDATE_CAPTCHA, loginData);

    return response;
  } catch (error) {
    // Handle error
    console.error("Error validating captcha:", error);
    throw error;
  }
};

// login post api call
export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(LOGIN_USER, loginData);

    return response;
  } catch (error) {
    // Handle error
    console.error("Error loging user", error);
    throw error;
  }
};

// forgot password post api call
export const forgotPassword = async (loginData) => {
  try {
    const response = await axios.post(FORGOT_PASSWORD_SEND_OTP, loginData);

    return response;
  } catch (error) {
    // Handle error
    console.error("Error getting forgot otp", error);
    throw error;
  }
};

// Function to verify OTP POST  API
export const verifyForgotOtp = async (data) => {
  try {
    const response = await axios.post(FORGOT_PASSWORD_VERIFY_OTP, data);
    console.log("verify otp  API", response);
    return response.data;
  } catch (error) {
    console.error("Error in verify otp", error);
    throw error;
  }
};

// reset password post api call
export const resetPassword = async (token, Data) => {
  try {
    const response = await axios.post(RESET_PASSWORD, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error reseting password", error);
    throw error;
  }
};
