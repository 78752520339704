// login urls

import { LOGIN_BASE_URL, REGISTER_BASE_URL } from "../constants/constants";

export const GENERATE_CAPTCHA = `${LOGIN_BASE_URL}/captcha/api/default`;

export const VALIDATE_CAPTCHA = `${LOGIN_BASE_URL}/api/captcha-login`;

export const LOGIN_USER = `${LOGIN_BASE_URL}/api/login`;

export const FORGOT_PASSWORD_SEND_OTP = `${LOGIN_BASE_URL}/api/forgot-password`;

export const FORGOT_PASSWORD_VERIFY_OTP = `${LOGIN_BASE_URL}/api/forgot-pass-verify`;

export const RESET_PASSWORD = `${LOGIN_BASE_URL}/api/reset-password`;

//register urls

export const REGISTER_LEVEL_ONE = `${REGISTER_BASE_URL}/register`;

export const REGISTER_LEVEL_TWO = `${REGISTER_BASE_URL}/second-register`;

export const UPDATE_REGISTER_LEVEL_ONE = `${REGISTER_BASE_URL}/update-register`;

export const REFRESH_TOKEN = `${REGISTER_BASE_URL}/refresh-token`;

export const QUALIFICATION = `${REGISTER_BASE_URL}/master/qualification-list`;

export const OCCUPATION = `${REGISTER_BASE_URL}/master/occupation-list`;

export const COURSE = `${REGISTER_BASE_URL}/master/course-list`;

export const DESIGNATION = `${REGISTER_BASE_URL}/master/designation-list`;

export const LANGUAGE = `${REGISTER_BASE_URL}/master/language-list`;

export const USER_CHECK = `${REGISTER_BASE_URL}/check-username`;

//basic details urls

export const FETCH_BASIC_DETAILS = `${REGISTER_BASE_URL}/students/get-basic`;

export const UPLOAD_PIC = `${REGISTER_BASE_URL}/students/photo-upload`;

export const FETCH_PIC_DETAILS = `${REGISTER_BASE_URL}/students/get-photo`;

export const UPDATE_BASIC_DETAILS = `${REGISTER_BASE_URL}/students/update-basic`;

export const SENT_OTP = `${REGISTER_BASE_URL}/students/send-otp`;

export const VERIFY_OTP = `${REGISTER_BASE_URL}/students/verify-otp`;

//personal details

export const FETCH_COUNTRY_LIST = `${REGISTER_BASE_URL}/master/country-list`;

export const FETCH_MARITAL_STATUS = `${REGISTER_BASE_URL}/master/marital-status-list`;

export const FETCH_GENDER_LIST = `${REGISTER_BASE_URL}/master/gender-list`;

export const FETCH_BLOOD_LIST = `${REGISTER_BASE_URL}/master/blood-group-list`;

export const FETCH_RELIGION_LIST = `${REGISTER_BASE_URL}/master/religion-list`;

export const FETCH_JOB_LIST = `${REGISTER_BASE_URL}/master/profession-list`;

export const GET_PERSONAL_DATA = `${REGISTER_BASE_URL}/students/get-personal`;

export const UPDATE_PERSONAL_DATA = `${REGISTER_BASE_URL}/students/update-personal`;

// profile images urls

export const FETCH_PROFILE_IMAGES = `${REGISTER_BASE_URL}/students/get-photos`;

export const SET_PROFILE_IMAGE = `${REGISTER_BASE_URL}/students/photo-upload`;

export const SET_DEFAULT_PROFILE_IMAGE = `${REGISTER_BASE_URL}/students/set-profile-photo`;

export const REMOVE_DEFAULT_PROFILE_IMAGE = `${REGISTER_BASE_URL}/students/remove-profile-photo`;

export const DELETE_PROFILE_IMAGE = `${REGISTER_BASE_URL}/students/delete-photo`;

//english language urls

export const FETCH_ENGLISH_KNOWLEDGE = `${REGISTER_BASE_URL}/students/get-english`;

export const FETCH_LANGUAGE_SKILL = `${REGISTER_BASE_URL}/master/lang-skill-list`;

export const UPDATE_ENGLISH_DATA = `${REGISTER_BASE_URL}/students/update-english`;

//other languages urls

export const FETCH_OTHER_LANGUAGES = `${REGISTER_BASE_URL}/students/get-other-language`;

export const UPDATE_OTHER_LANGUAGES = `${REGISTER_BASE_URL}/students/save-language`;

export const DELETE_OTHER_LANGUAGES = `${REGISTER_BASE_URL}/students/delete-language`;

//qualification urls

export const FETCH_STATUS_LIST = `${REGISTER_BASE_URL}/master/education-status`;

export const FETCH_SPECIALISATION_LIST = `${REGISTER_BASE_URL}/master/discipline-list`;

export const FETCH_MARK_LIST = `${REGISTER_BASE_URL}/master/grade-list`;

export const FETCH_BOARD_LIST = `${REGISTER_BASE_URL}/master/board-list`;

export const FETCH_QUALIFICATIONS = `${REGISTER_BASE_URL}/students/get-education`;

export const UPDATE_QUALIFICATIONS = `${REGISTER_BASE_URL}/students/save-education`;

export const DELETE_QUALIFICATIONS = `${REGISTER_BASE_URL}/students/delete-education`;

//professional details urls

export const FETCH_PROFESSIONS = `${REGISTER_BASE_URL}/students/get-profession`;

export const UPDATE_PROFESSIONS = `${REGISTER_BASE_URL}/students/save-jobs`;

export const FETCH_JOB_CATEGORY_LIST = `${REGISTER_BASE_URL}/master/profession-list`;

export const FETCH_JOB_SPECIALISATION_LIST = `${REGISTER_BASE_URL}/master/specialization-list`;

export const DELETE_PROFESSIONS = `${REGISTER_BASE_URL}/students/delete-jobs`;

//present address urls

export const FETCH_PRESENT_ADDRESS = `${REGISTER_BASE_URL}/students/get-present-address`;

export const UPDATE_PRESENT_ADDRESS = `${REGISTER_BASE_URL}/students/update-present-address`;

//prermanent address urls

export const FETCH_PERMANENT_ADDRESS = `${REGISTER_BASE_URL}/students/get-permanent-address`;

export const UPDATE_PERMANENT_ADDRESS = `${REGISTER_BASE_URL}/students/update-permanent-address`;

//generic info urls

export const FETCH_GENERIC_INFO = `${REGISTER_BASE_URL}/students/get-generic-info`;

export const UPDATE_GENERIC_INFO = `${REGISTER_BASE_URL}/students/update-generic-info`;

export const SURVEY_QUESTIONS = `${REGISTER_BASE_URL}/students/get-generic-new`;

export const ANSWER_SURVEY_QUESTIONS = `${REGISTER_BASE_URL}/students/save-generic-que`;

//publicity address urls

export const FETCH_PUBLICITY_ADDRESS = `${REGISTER_BASE_URL}/students/get-public-address`;

export const UPDATE_PUBLICITY_ADDRESS = `${REGISTER_BASE_URL}/students/update-public-address`;

//other country profiles urls

export const FETCH_OTHER_COUNTRY_PROFILE = `${REGISTER_BASE_URL}/students/get-other-address`;

export const UPDATE_OTHER_COUNTRY_PROFILE = `${REGISTER_BASE_URL}/students/save-other-address`;

export const SET_AS_PRESENT_ADDRESS = `${REGISTER_BASE_URL}/students/set-present-address`;

export const FETCH_IDPROOF_LIST = `${REGISTER_BASE_URL}/master/idproof-list`;

export const DELETE_OTHER_COUNTRY_PROFILE = `${REGISTER_BASE_URL}/students/delete-other-address`;

//side bar apis

export const FETCH_MENU_STATE = `${REGISTER_BASE_URL}/students/get-menu-state`;

//address apis

export const FETCH_ADDRESS_DETAILS = `${REGISTER_BASE_URL}/students/get-all-address`;

export const UPDATE_ADDRESS_DETAILS = `${REGISTER_BASE_URL}/students/save-address`;

export const SET_AS_DEFAULT_ADDRESS  = `${REGISTER_BASE_URL}/students/set-default-address`;

export const SET_SAME_ADDRESS  = `${REGISTER_BASE_URL}/students/set-same-address`;