import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getJobList } from "../../api/personalApi";
import { TbDots } from "react-icons/tb";

const JobSelect = ({ getJobs, jobs, error }) => {
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(jobs ? jobs : null);
  const userToken = useSelector((state) => state.register.token);

  useEffect(() => {
    const fetchJobList = async (userToken) => {
      try {
        setLoading(true);
        const jobData = await getJobList(userToken);

        if (jobData.status === 1) {
          const options = jobData.data.map((job) => ({
            value: job.value,
            label: job.label,
          }));

          setJobOptions(options);
          setLoading(false);
        } else {
          setJobOptions([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Failed to get job list", error);
      }
    };

    fetchJobList(userToken);
  }, [userToken, dispatch]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    getJobs(selectedOption);
  };

  return (
    <div className="relative">
      <Select
        className="text-xs"
        isClearable={true}
        isMulti
        options={jobOptions}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: "7px",
          boxShadow: null,
          colors: {
            ...theme.colors,
            primary25: "#526754",
            primary: "#526754",
          },
        })}
        value={
          selectedOption && Object.keys(selectedOption).length === 0
            ? ""
            : selectedOption
        }
        onChange={handleChange}
      />
      {loading && (
        <div class="  absolute  top-[5px] right-[60px]">
          <img
            class="h-[25px] w-[25px]"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default JobSelect;
