import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";

const PhoneForm = forwardRef(
  (
    {
      onPhoneChange,
      gray,
      val,
      Error,
      logMobile,
      checkUser,
      inputPhoneRef,
      address,
    },
    ref
  ) => {
    const userToken = useSelector((state) => state.register.token);
    const inputRef = useRef(null);

    useEffect(() => {
      if (Error && inputPhoneRef?.current) {
        inputPhoneRef?.current.focus(); // Focus the phone input field if there's an error
      }
    }, [Error]);
    useEffect(() => {
      // Focus the input field when the component mounts
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    // Access the form data from Redux store
    let c_code = null;
    if (val?.country_code) {
      c_code = val?.country_code?.toLowerCase();
    }

    const [country, setCountry] = useState({
      country_code: val?.country_code ? val.country_code : "in",
      dial_code: val?.dial_code ? val.dial_code : "91",
    });
    const [phoneNumber, setPhoneNumber] = useState(
      val?.mobile ? val.mobile : logMobile ? logMobile : ""
    );

    const handlePhoneChange = (e) => {
      const { value } = e.target;

      if (country) {
        setPhoneNumber(value);
      }
    };

    useEffect(() => {
      if (val?.country_code && val?.dial_code) {
        setCountry({ dial_code: val?.dial_code, country_code: c_code });
      }
    }, []);

    useEffect(() => {
      if (val?.country_code) {
        setCountry({ dial_code: val?.dial_code, country_code: c_code });
      }
    }, [val?.country_code, val?.dial_code, phoneNumber]);

    const handleCountryChange = (value, countryData) => {
      setCountry({
        country_code: countryData.countryCode,
        dial_code: countryData.dialCode,
      });
    };

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        inputRef.current.focus();
      },
    }));

    useEffect(() => {
      onPhoneChange({
        dial_code: country.dial_code,
        mobile: phoneNumber,
        country_code: country.country_code,
      });
    }, [country, phoneNumber]);

    const handleBlur = (event) => {
      if (checkUser) {
        checkUser(
          { mobile: phoneNumber, country_code: country.country_code },
          userToken
        );
      }
    };

    const handleKeyPress = (e) => {
      // Allow only numeric keys
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <div className="relative">
        <style>
          {`
          .react-tel-input .country-list {
            /* Your styles here */
           display: ${address ? "none !important" : "block !important"}
          }
        `}
        </style>
        <div className="flex">
          <div className="w-[185px] lg:w-[155px]">
            <PhoneInput
              enableSearch={true}
              country={country.country_code}
              placeholder=""
              onChange={handleCountryChange}
              inputProps={{
                readOnly: true,
                style: {
                  cursor: "pointer",

                  marginRight: "5px",
                  backgroundColor: `${gray && "rgb(243,244,246)"}`,
                },
              }}
            />
          </div>

          <input
            ref={ref ? inputRef : inputPhoneRef && inputPhoneRef}
            className={`w-full border   border-gray-400 rounded-lg px-4 ml-2 text-xs  focus:outline-none   ${
              gray && " bg-gray-100"
            } ${Error && " border-red-500"}`}
            type="number"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="Enter Phone Number"
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
          />
        </div>
      </div>
    );
  }
);

export default PhoneForm;
