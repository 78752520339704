import Welcome from "../../components/Welcome/Welcome";
import React, {   useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {   useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { forgotPassword, loginUser } from "../../api/loginApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import { setToken } from "../../Redux/Actions/registerActions";
import {
  saveForgotData,
  saveRegisterTwoFlag,
  saveStepOneData,
} from "../../Redux/Actions/loginActions";

const LoginTwo = () => {

  const navigate = useNavigate();
  const stepOneData = useSelector((state) => state.login.stepOneData);
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors({});
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await validationSchema.validate(loginData, { abortEarly: false });

      const response = await loginUser({
        ...stepOneData,
        password: loginData.password,
      });

      if (response.data.status === 1) {
        dispatch(setToken(response.data.authorisation.token));
        dispatch(saveRegisterTwoFlag(response.data.second_register));
        setLoginData({
          password: "",
        });
        dispatch(saveStepOneData(null));
        setErrors({});
        navigate("/profile/basic-details");
        setLoading(false);
      } else {
        dispatch(setAlert(response.data.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
      setLoginData({
        password: "",
      });
    }
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleForgotClick = async () => {

    
    try {
      const response = await forgotPassword(stepOneData);
      if (response.data.status === 1) {
        const now = Math.floor(Date.now() / 1000);
        const expire = now + parseInt(response.data.data.expire_time);
        dispatch(
          saveForgotData({ ...stepOneData, message: response.data.message,expire_time: expire})
        );
        setLoginData({
          username: "",
        });
        navigate("/verify-code");
        setErrors({});
        setLoading(false);
      } else {
        dispatch(setAlert(response.data.message, "error"));
        const apiErrors = response.data.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  return (
    <div className="flex bg-custom-pattern justify-center items-center min-h-page-height">
      <div className="container mx-auto">
        <div className="flex flex-col md:justify-around md:flex-row">
          {/* Left Section */}
          <Welcome />
          {/* Right Section */}
          <div className="lg:w-2/5 md:w-[60%] p-4 md:my-auto">
            <div className="bg-white lg:w-11/12   rounded-lg shadow-lg p-9">
              <h2 className="text-lg text-custom text-center pb-4 my-4">
                STUDENT LOGIN
              </h2>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                {/* Username Field */}
                <div className="mb-4">
                  <p className="block text-custom text-xs mb-2">
                    {stepOneData.firstfield}
                  </p>
                </div>

                {/* Password Field */}
                <div className="mb-4 mt-6 relative">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                        errors.password ? "border-red-500" : ""
                      }`}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={loginData.password}
                      onChange={handleInputChange}
                      placeholder="Enter password"
                    />
                    {/* Eye Icons */}
                    <button
                      className="absolute right-2 top-2"
                      type="button"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="text-custom" title="Hide" />
                      ) : (
                        <FaEye className="text-custom" title="Show"/>
                      )}
                    </button>
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.password}
                    </p>
                  )}
                </div>

                {/* Buttons */}
                <div className="flex justify-between ">
                  <button
                    className="border hover:bg-custom hover:text-white border-custom w-1/2 rounded-full   text-custom text-xs mt-4 py-2 px-4 focus:outline-none focus:shadow-outline mr-2"
                    type="button"
                    onClick={handleBack}
                  >
                    BACK
                  </button>
                  <button
                    className="bg-[#5E8862] w-1/2 text-xs rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline ml-2"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Logging in..." : "LOGIN"}
                  </button>
                </div>

                {/* Forgot Password */}
              </form>
              <p className="text-xs my-6">
                <button onClick={handleForgotClick} className="text-custom">
                  Forgot password?
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTwo;
