import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getJobCategoryList } from "../../api/professionApi";
import { TbDots } from "react-icons/tb";

const JobCategorySelect = ({ getJobCategory, val, error, id }) => {
  const dispatch = useDispatch();
  const [Options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    val?.value && val?.label ? val : ""
  );
  const userToken = useSelector((state) => state.register.token);

  function findObjectByValue(array, valueToFind) {
    return array.find((obj) => obj.value === valueToFind);
  }

  useEffect(() => {
    const foundObject = findObjectByValue(Options, val?.value);

    if (foundObject) {
      setSelectedOption(foundObject);
      getJobCategory(foundObject);
    } else {
      setSelectedOption("");
    }
  }, [val?.value, Options]);

  useEffect(() => {
    const fetchJobCategory = async (userToken, id) => {
      try {
        setLoading(true);
        const Data = await getJobCategoryList(userToken, id);
        if (Data.status === 1) {
          const options = Data.data.map((category) => ({
            value: category.value,
            label: category.label,
          }));
          setOptions(options);
          setLoading(false);
        } else {
          setOptions([]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to get job list", error);
        setLoading(false);
      }
    };

    fetchJobCategory(userToken, id);
  }, [userToken, dispatch, id]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getJobCategory(selectedOption);
  };

  return (
    <div className="relative">
      <Select
        className="text-xs"
        isClearable={true}
        options={Options}
        value={selectedOption}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: "7px",
          boxShadow: null,
          colors: {
            ...theme.colors,
            primary25: "#526754",
            primary: "#526754",
          },
        })}
        onChange={handleChange}
      />
      {loading && (
        <div class="  absolute  top-[5px] right-[60px]">
          <img
            class="h-[25px] w-[25px]"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default JobCategorySelect;
