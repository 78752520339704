import React, { useRef, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import Modal from "../Modal/Modal";
import PhoneForm from "../Phone/Phone";
import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import { setBasicDetailsData } from "../../Redux/Actions/profileActions";
import { updateBasicDetails } from "../../api/basicDetailsApi";
import { userCheck } from "../../api/registerApi";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};

const MobileEditModal = ({ isOpen, onClose, verify }) => {
  const userToken = useSelector((state) => state.register.token);
  const basicDetails = useSelector((state) => state.profile.basicDetails);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mobile: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const schema = yup.object().shape({
    mobile: yup
      .string()
      .required("Phone number is required")
      .test("is-valid-phone", "Valid phone number required", function (value) {
        const dialCode = formData.dial_code;
        const phoneNumber = `+${dialCode}${value}`;
        return isPhoneValid(phoneNumber);
      }),
  });

  const phoneInputRef = useRef(null);
  const handlePhoneChange = ({ dial_code, mobile, country_code }) => {
    setFormData({
      ...formData,
      dial_code,
      mobile,
      country_code,
    });
    // Clear mobile number error when the user starts typing
    if (errors.mobile) {
      setErrors({ ...errors, mobile: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (errors.mobile) {
        return;
      }
      await schema.validate(formData, { abortEarly: false });

      setLoading(true);

      // If validation passes, send to api

      const response = await updateBasicDetails(userToken, formData);

      // store data to redux state in case of success
      if (response.status === 1) {
        verify(false);
        dispatch(setAlert(response.message, "success"));
        dispatch(setBasicDetailsData(response.data));
        setFormData({ ...formData, mobile: "" });
        setLoading(false);
        setErrors({});
        handleCancel();
      } else {
        setLoading(false);
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
      }
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleCancel = () => {
    setFormData({ ...formData, mobile: "" });
    setErrors({});
    onClose();
  };

  const checkUser = async (userData, userToken) => {
    try {
      const response = await userCheck(userData, userToken);
      const key = Object.keys(userData)[0];
      if (response.data.status) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: response.data.errors[key],
        }));
      }
    } catch (error) {
      console.error("Error checking user duplicate", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
        <FaMobileAlt className="text-white text-xl" />
      </div>
      <p className="my-5 text-lg font-normal text-custom text-center">
        EDIT YOUR MOBILE NUMBER
      </p>
      <div className="px-[25px]">
        <PhoneForm
          onPhoneChange={handlePhoneChange}
          Error={errors.mobile}
          checkUser={checkUser}
          val={{
            country_code: basicDetails.country_code,
            mobile: basicDetails.mobile,
            dial_code: basicDetails.dial_code,
          }}
          inputPhoneRef={phoneInputRef}
        />
        {errors.mobile && (
          <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
        )}
      </div>

      <div className="my-2 flex justify-end px-[25px]">
        <button
          className="border hover:bg-custom hover:text-white border-custom w-1/2 rounded-full   text-custom text-xs mt-4 py-2 px-4 focus:outline-none focus:shadow-outline mr-2"
          type="button"
          onClick={handleCancel}
        >
          CANCEL
        </button>
        <button
          className="bg-[#5E8862] w-1/2 text-xs rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline ml-2"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "SAVING..." : "SAVE"}
        </button>
      </div>
    </Modal>
  );
};

export default MobileEditModal;
