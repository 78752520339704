import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getQualificationList } from "../../api/registerApi";
import { TbDots } from "react-icons/tb";

const QualificationSelect = ({ getQualification, error, val }) => {

  const dispatch = useDispatch();
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    val?.value && val?.label ? val : ""
  );
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.register.token);

  function findObjectByValue(valueToFind) {
    return qualificationOptions.find((obj) => obj.label === valueToFind);
  }

  useEffect(() => {
    const foundObject = findObjectByValue(val?.label);

    if (foundObject) {
      setSelectedOption(foundObject);
      getQualification(foundObject);
    } else {
      setSelectedOption("");
    }
  }, [val?.label, qualificationOptions]);

  useEffect(() => {
    const fetchQualifications = async (userToken) => {
      try {
        setLoading(true);
        const qualificationData = await getQualificationList(userToken);
        if (qualificationData.status === 1) {
          const options = qualificationData.data.map((qualification) => ({
            value: qualification.value,
            label: qualification.label,
          }));
          setQualificationOptions(options);
          setLoading(false);
        } else {
          setQualificationOptions([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Failed to get qualification list", error);
      }
    };

    fetchQualifications(userToken);
  }, [userToken, dispatch]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getQualification(selectedOption);
  };

  return (
    <div className="relative">
      <Select
        className="text-xs"
        isClearable={true}
        options={qualificationOptions}
        value={selectedOption}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: "7px",
          boxShadow: null,
          colors: {
            ...theme.colors,
            primary25: "#526754",
            primary: "#526754",
          },
        })}
        onChange={handleChange}
      />
     {loading && (
        <div class="  absolute  top-[5px] right-[60px]">
          <img
            class="h-[25px] w-[25px]"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default QualificationSelect;
