import axios from "axios";
import {
  DELETE_OTHER_LANGUAGES,
  FETCH_OTHER_LANGUAGES,
  UPDATE_OTHER_LANGUAGES,
} from "../config/config";

// get other languages details get api call
export const getOtherLanguagesDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_OTHER_LANGUAGES, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get other languages knowledge", error);
    throw error;
  }
};

//update other language data post api call
export const updateOtherLanguagesData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_OTHER_LANGUAGES, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update other language data:", error);
    throw error;
  }
};

//update other language data post api call
export const deleteLanguagesData = async (userData, token) => {
  try {
    const response = await axios.post(DELETE_OTHER_LANGUAGES, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete other language data:", error);
    throw error;
  }
};
