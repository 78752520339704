import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const LandPhoneForm = ({ onPhoneChange, Error, val }) => {
  const [country, setCountry] = useState({
    country_code: "in",
    dial_code: "91",
  });
  const [phoneNumber, setPhoneNumber] = useState(val?.mobile ? val.mobile : "");
  const [landPhoneNumber, setLandPhoneNumber] = useState(
    val?.area_code ? val.area_code : ""
  );
  const c_code = val?.country_code?.toLowerCase();
  useEffect(() => {
    if (val?.country_code) {
      setCountry({ dial_code: val?.dial_code, country_code: c_code });
    }
  }, [val?.country_code]);
  const handlePhoneChange = (e) => {
    const { value } = e.target;

    setPhoneNumber(value);
  };

  const handleLandPhoneChange = (e) => {
    const { value } = e.target;

    setLandPhoneNumber(value);
  };

  const handleCountryChange = (value, countryData) => {
    setCountry({
      country_code: countryData.countryCode,
      dial_code: countryData.dialCode,
    });
  };

  const sentNumber = () => {
    onPhoneChange({
      dial_code: country.dial_code,
      mobile: phoneNumber,
      country_code: country.country_code,
      area_code: landPhoneNumber,
    });
  };

  useEffect(() => {
    sentNumber();
  }, [phoneNumber, landPhoneNumber, country]);

  const handleKeyPress = (e) => {
    // Allow only numeric keys
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div className="relative">
      <div className="flex">
        <div className="w-[215px] lg:w-[146px]">
          <PhoneInput
            enableSearch={true}
            country={country.country_code}
            placeholder=""
            onChange={handleCountryChange}
            inputProps={{
              readOnly: true,
              style: {
                cursor: "pointer",
              },
            }}
          />
        </div>

        <input
          className={`w-[40%] border   border-gray-400 rounded-lg px-4 ml-2 text-xs  focus:outline-none   
                   
              ${Error && " border-red-500"}`}
          type="number"
          value={landPhoneNumber}
          onChange={handleLandPhoneChange}
          placeholder="Enter area code"
          onKeyPress={handleKeyPress}
          onFocus={(e) =>
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false }
            )
          }
        />
        <input
          className={`w-[60%] border   border-gray-400 rounded-lg px-4 ml-2 text-xs  focus:outline-none    
                  
               ${Error && " border-red-500"}`}
          type="number"
          id="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneChange}
          placeholder="Enter Phone Number"
          onKeyPress={handleKeyPress}
          onFocus={(e) =>
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false }
            )
          }
        />
      </div>
    </div>
  );
};
export default LandPhoneForm;
