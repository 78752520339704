import React from "react";

const Modal = ({ isOpen, onClose, children, help, title }) => {
  const modalClasses = isOpen
    ? "fixed inset-0 flex items-center justify-center z-50 overflow-x-auto  "
    : "hidden";

  return (
    <div className={modalClasses}>
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

        <div
          className={`modal-container bg-white w-11/12 ${
            help ? " md:max-w-2xl" : "md:max-w-md"
          } mx-auto rounded shadow-lg z-50  `}
        >
          <div
            className={`modal-content  ${help? "pb-8" : "p-2 pb-4 md:p-8"}  text-left  relative`}
          >
            {help && (
              <h2 className="text-lg ps-8 py-3 font-medium text-white bg-custom">
                {title}
              </h2>
            )}
            {help && <hr className="border-gray-300 mb-4" />}
            <button
              onClick={onClose}
              className="absolute top-0 right-0 mt-4 mr-4 cursor-pointer"
            >
              <svg
                className={`fill-current ${
                  help ? "text-white" : "text-gray-500"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M6.3 9l-5.3 5.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L7 10.4l5.3 5.3c.4.4 1 .4 1.4 0s.4-1 0-1.4L8.4 9l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L7 7.6 1.7 2.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L6.3 9z" />
              </svg>
            </button>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
