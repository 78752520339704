export const REGISTER_STEP_ONE_DATA = "REGISTER_STEP_ONE_DATA";

export const UPDATE_STEP_ONE_DATA = "UPDATE_STEP_ONE_DATA";

export const SET_TOKEN = "SET_TOKEN";

export const REMOVE_TOKEN = "REMOVE_TOKEN";

export const REGISTER_STEP_TWO_DATA = "REGISTER_STEP_TWO_DATA";

export const saveStepOneData = (data) => {
  return {
    type: REGISTER_STEP_ONE_DATA,
    payload: data,
  };
};

export const setToken = (data) => {
  return {
    type: SET_TOKEN,
    payload: data,
  };
};

export const removeToken = () => ({
  type: REMOVE_TOKEN,
});

export const updateStepOneData = (data) => {
  return {
    type: UPDATE_STEP_ONE_DATA,
    payload: data,
  };
};

export const saveStepTwoData = (data) => {
  return {
    type: REGISTER_STEP_TWO_DATA,
    payload: data,
  };
};
