import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions.js";
import {
  getPublicityAddress,
  updatePublicityAddress,
} from "../../api/publicityAddress.js";
import { getMenuState } from "../../api/sideBarApi.js";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions.js";
import PublicitySkeleton from "../../components/Skeleton/PublicitySkeleton.jsx";
import Modal from "../../components/Modal/Modal.jsx";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";

const PublicityAddress = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [newAddress, setNewAddress] = useState();
  const [address, setAddress] = useState();
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchPublicityAddressDetails = async (token) => {
      try {
        const details = await getPublicityAddress(token);

        if (details.status === 1) {
          setNewAddress(details.data);
          setAddress(details.data);
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          setSkeletonLoad(false);
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to fetch public address details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error public address details  ", error);
        setSkeletonLoad(false);
      }
    };
    fetchPublicityAddressDetails(token);
  }, [token]);

  const handleSave = async () => {
    console.log("save", newAddress?.publish_address);

    try {
      setLoading(true);
      const response = await updatePublicityAddress(
        {
          publish_address: newAddress?.publish_address,
        },
        token
      );

      if (response.status === 1) {
        setLoading(false);
        setNewAddress(response.data);

        dispatch(setAlert(response.message, "success"));
        setIsEditMode(false);

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length <= 200) {
      setNewAddress({ ...newAddress, publish_address: e.target.value });
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setNewAddress(address);
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  if (skeletonLoad) {
    return <PublicitySkeleton />;
  }
  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center  md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>
        <div className="bg-white shadow-md lg:w-[65%] w-[97%] p-6 md:p-10 h-fit lg:ml-6 md:ml-2 ml-0 ">
          <div className="mb-4 flex items-center ">
            <div>
              <div className="flex items-center">
                <h2 className="text-lg text-custom">ADDRESS FOR PUBLICITY</h2>
                <button
                  className="ml-auto   flex items-center text-blue-500 text-xs"
                  onClick={() => setHelp(true)}
                  title="Help"
                >
                  <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                </button>
              </div>

              <div className="bg-custom w-12 mt-2 h-[2px] mb-4  "></div>
            </div>
          </div>

          <>
            {!isEditMode ? (
              <div className="bg-white shadow-md pt-8 border-[1px] border-gray-300 rounded-lg pb-6 px-6">
                <div className="text-xs flex text-custom pb-4 font-medium">
                  <IoLocationOutline className="mr-2 text-[15px]" />{" "}
                  {newAddress?.heading}
                </div>

                <address className="text-xs w-[90%] overflow-auto">
                  {newAddress?.address_only}
                </address>

                <hr className="border-gray-300 my-4" />
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={toggleEditMode}
                >
                  <FaRegEdit className="mr-2" />
                  Edit Address
                </button>
              </div>
            ) : (
              <div className="border border-gray-400 rounded-lg px-4 py-2 text-xs w-full ">
                <textarea
                  className="focus:outline-none w-full"
                  value={newAddress?.publish_address}
                  onChange={handleChange}
                  rows={6}
                  cols={70}
                  style={{ resize: "none" }}
                  autoComplete="off"
                />
                <br />
                <label className="flex justify-end text-custom">
                  {newAddress?.publish_address.length}/{200}
                </label>
              </div>
            )}
            <div className="text-end">
              {isEditMode && (
                <>
                  <button
                    className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleCancel}
                  >
                    CANCEL
                  </button>
                  <button
                    className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={loading}
                    onClick={handleSave}
                  >
                    {loading ? "Saving.." : "SAVE"}
                  </button>
                </>
              )}
            </div>
          </>
        </div>

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"PUBLICITY ADDRESS INFO"}
            onClose={() => setHelp(false)}
          >
            <div className=" ps-8  overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default PublicityAddress;
