import React from "react";

const Welcome = () => {
  return (
    <div className="lg:w-3/5 md:w-1/2 md:my-40  lg:mt-50 mt-20 lg:ml-10 p-4">
      <h2 className="text-[40px] font-bold text-title ">Welcome !</h2>
      <p className="text-[20px] font-bold text-title mb-4">
        CREATE YOUR ACCOUNT
      </p>
      <p className="text-[#FFFFFF] font-sans">
        We provide Education & Employment for You through Study Win. <br /> So
        Kindly Provide Proper Details of You.
        <br />
        Make More Money through Education & Entertainment.
      </p>
    </div>
  );
};

export default Welcome;
