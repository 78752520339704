import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const Accordion = ({ children }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const closeAllAccordions = () => {
    setOpenIndex(null);
  };

  return (
    <div className="shadow-md bg-white  ">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: index === openIndex,
          toggleAccordion: () => toggleAccordion(index),
          closeAllAccordions: () => closeAllAccordions(),
        });
      })}
    </div>
  );
};

const AccordionItem = ({
  isOpen,
  firstAccordion,
  closeAll,
  closeAllAccordions,
  toggleAccordion,
  title,
  children,
  onEdit,
  onDelete,
}) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit();
    if (!isOpen) {
      toggleAccordion();
    }
  };

  useEffect(() => {
    if (firstAccordion === 0) {
      toggleAccordion();
    }
  }, [firstAccordion]);
  if (closeAll) {
    closeAllAccordions();
  }

  return (
    <div>
      <div
        className={`lg:flex items-center justify-between md:px-10 px-6 py-4 cursor-pointer ${
          isOpen ? "bg-[#52675428] border-s-4 border-custom" : ""
        }`}
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <svg
            className={`w-5 h-5 mr-4 transition-transform transform  ${
              isOpen ? "rotate-0" : "-rotate-90"
            }`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9l6 6 6-6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="font-bold">
            {title}{" "}
            <span className="font-normal text-[13px] text-blue-600" title="Click to close or open the accordion">
              (Click Here)
            </span>
          </span>
        </div>
        <div className="flex items-center mt-2 lg:mt-0 space-x-2">
          <button
            className="ml-auto flex items-center text-blue-500 text-sm"
            onClick={handleEdit}
          >
            <FaRegEdit className="mr-2" />
            Edit
          </button>
          <button
            className="lg:pl-6 pl-2 flex items-center text-red-500 text-sm"
            onClick={handleDelete}
          >
            <img
              src="https://img.icons8.com/?size=15&id=nerFBdXcYDve&format=png&color=ff0000"
              alt=""
              className="mr-2"
            />
            Delete
          </button>
        </div>
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

export { Accordion, AccordionItem };
