import React, { useEffect, useState } from "react";
import {
  AddressData,
  otherCountryAddressData,
} from "../../constants/fieldData";
import { useFormik } from "formik";
import * as Yup from "yup";
import CountrySelect from "../../components/Select/Country";
import LocationAutocomplete from "../../components/Location/Location";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions.js";
import { getMenuState } from "../../api/sideBarApi.js";
import {
  saveAddressData,
  saveMenuData,
} from "../../Redux/Actions/profileActions.js";
import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneForm from "../../components/Phone/Phone.jsx";
import IdSelect from "../../components/Select/IdSelect.jsx";
import { updateAddressData } from "../../api/addressApi.js";
import Modal from "../../components/Modal/Modal.jsx";
import { BsExclamationCircleFill } from "react-icons/bs";
import { updatePermanentAddressData } from "../../api/permanentAddressApi.js";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};

const AddressForm = ({ cancel, add, val, addVal, item_order }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState({
    value: val ? val.country_code : "",
    label: val ? val.country_name : "",
    dial_code: val ? val.dial_code : "",
  });
  const [place, setPlace] = useState({
    city: val ? val.city : "",
    district: val ? val.district : "",
    state: val ? val.state : "",
    country: val ? val.country : "",
    country_name: val ? val.country_name : "",
  });
  const [addressValues, setAddressValues] = useState(val);
  const [data, setData] = useState(otherCountryAddressData);
  const [sameAsPresent, setSameAsPresent] = useState(false);
  const [sameAsPresentChecked, setSameAsPresentChecked] = useState(false);

  const validationSchema = Yup.object().shape({
    address_type: Yup.string().required("Address type is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    mobile_no: Yup.string().when(["address_type"], {
      is: (address_type) => address_type === "0",
      then: (schema) =>
        schema
          .test(
            "is-valid-phone",
            "Valid phone number required",
            function (value) {
              if (!value) return true;
              const dialCode = values.dial_code;
              const phoneNumber = `+${dialCode}${value}`;
              return isPhoneValid(phoneNumber);
            }
          )
          .required("Mobile is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleCountryChange = (country) => {
    setCountry({
      value: country?.value,
      label: country?.label,
      dial_code: country?.dial_code,
    });
    formik.setValues({
      ...formik.values,
      country: country?.value,
      country_code: country?.value,
      country_name: country?.label,
      dial_code: country?.dial_code,
      proof_id: "",
      proof_name: "",
      proof_no: "",
    });
    formik.setErrors({ ...errors, country: "" });
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      proof_id: "",
      proof_name: "",
      proof_no: "",
    });
  }, [country]);

  const handlePlaceSelected = (place) => {
    if (place?.city) {
      setCountry({
        ...country,
        value: place?.country_code,
        label: place?.country,
      });
      formik.setValues({
        ...formik.values,
        proof_id: "",
        proof_name: "",
        proof_no: "",
      });
      setPlace({
        city: place?.city,
        district: place?.district,
        state: place?.state,
        country: place?.country_code,
        country_name: place?.country,
      });
    } else {
      setPlace((prevState) => ({
        ...prevState,
        city: null,
        district: null,
        state: null,
      }));
    }
    formik.setErrors({ ...errors, country: "", city: "" });
  };

  useEffect(() => {
    const handlePlace = () => {
      formik.setValues({
        ...formik.values,
        country: place?.country,
        city: place?.city,
        district: place?.district,
        state: place?.state,
        country_code: country?.value,
        country_name: place?.country_name,
        dial_code: country?.dial_code,
      });
    };
    handlePlace();
  }, [place]);

  const handleLocationError = (error) => {
    // Clear location  error when the user starts typing
    if (!error) {
      formik.setErrors({ ...errors, city: "" });
    }
  };

  const handlePhoneChange = ({ dial_code, mobile, country_code }) => {
    formik.setValues({
      ...formik.values,
      country_code: country_code,
      dial_code: dial_code,
      mobile_no: mobile,
    });
    formik.setErrors({ ...errors, mobile_no: "" });
  };

  const handleIdSelect = (id) => {
    if (id?.value) {
      formik.setValues({
        ...formik.values,
        proof_id: id?.value,
        proof_name: id?.label,
      });
    } else {
      formik.setValues({
        ...formik.values,
        proof_id: null,
        proof_name: null,
      });
    }
  };

  // Function to handle form submission when adding a new item
  const onSubmitAdd = async (values, { setErrors }) => {
    try {
      setLoading(true);

      const response = await updateAddressData(values, token);

      if (response.status === 1) {
        setLoading(false);
        dispatch(saveAddressData(response.data));

        dispatch(setAlert(response.message, "success"));

        cancel();
        formikAdd.resetForm();

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
    }
  };

  // Function to handle form submission when editing an existing item
  const onSubmitEdit = async (values, { setErrors }) => {
    try {
      setLoading(true);

      const response = await updateAddressData(values, token);

      if (response.status === 1) {
        setLoading(false);
        dispatch(saveAddressData(response.data));

        dispatch(setAlert(response.message, "success"));

        formikAdd.resetForm();
        cancel();
        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
    }
  };

  const handleSameAsPresent = () => {
    if (!sameAsPresentChecked) {
      setSameAsPresent(true);
    }
  };

  const handleSameAsPresentAddress = async () => {
    setCountry({
      value: addVal ? addVal.country_code : "",
      label: addVal ? addVal.country_name : "",
      dial_code: addVal ? addVal.dial_code : "",
    });
    setPlace({
      city: addVal ? addVal.city : "",
      district: addVal ? addVal.district : "",
      state: addVal ? addVal.state : "",
      country: addVal ? addVal.country : "",
      country_name: addVal ? addVal.country_name : "",
    });
    formik.setValues({ ...addVal, address_type: "2", id: null });
    try {
      setLoading(true);
      const response = await updatePermanentAddressData(
        {
          same_as_present: "1",
          item_order: item_order,
        },
        token
      );

      if (response.status === 1) {
        dispatch(saveAddressData(response.data));
        setSameAsPresentChecked(true);
        dispatch(setAlert(response.message, "success"));
        setSameAsPresent(false);
        setLoading(false);
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
    }
  };

  const getDataArg = () => {
    if (values.address_type?.toString() === "0") {
      return otherCountryAddressData;
    } else {
      return AddressData;
    }
  };

  // For adding new item
  let initialValuesAdd = {};
  data.forEach((field) => {
    initialValuesAdd[field.name] = "";
  });

  const formikAdd = useFormik({
    initialValues: initialValuesAdd,
    validationSchema: validationSchema,
    onSubmit: onSubmitAdd,
    validateOnChange: false,
    validateOnBlur: false,
  });

  // For editing existing item
  const initialValuesEdit = val;

  const formikEdit = useFormik({
    initialValues: initialValuesEdit,
    validationSchema: validationSchema,
    onSubmit: onSubmitEdit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the field value
    handleChange(e);

    // Clear errors for the field
    formikAdd.setErrors({
      ...formikAdd.errors,
      [name]: undefined,
    });

    formikEdit.setErrors({
      ...formikEdit.errors,
      [name]: undefined,
    });
  };

  // Selecting the correct formik object based on whether adding new or editing
  const formik = add ? formikAdd : formikEdit;

  const { values, errors, touched, handleChange } = formik;

  const handleCancel = () => {
    setSameAsPresent(false);
    formik.setErrors({});
    formik.setValues(addressValues);
    cancel();
  };

  console.log("add value", values);
  return (
    <div>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        {getDataArg().map((field) => (
          <div key={field.name}>
            <div key={field.name} className="lg:flex flex-row items-center">
              <label className="text-gray-500 text-xs md:w-[250px]">
                {field.label}
                {field.required && <span className="text-red-500">*</span>}
              </label>
              <div className="flex items-center flex-1 mt-3 md:mt-0">
                {!add ? (
                  <div className="w-full">
                    {(() => {
                      switch (field.name) {
                        case "address_type":
                          return (
                            <div>
                              <div className="flex">
                                <label
                                  className={`inline-flex cursor-pointer w-1/3 border mr-2 text-xs rounded-full py-1 px-2 items-center justify-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    id="address_type"
                                    value="1"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type.toString() === "1"
                                    }
                                    className="form-radio  text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">
                                    Present
                                  </span>
                                </label>
                                <label
                                  className={`inline-flex cursor-pointer justify-center w-1/3 border mr-2 text-xs rounded-full py-1 px-2 items-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="2"
                                    id="address_type"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type.toString() === "2"
                                    }
                                    className="form-radio text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">
                                    Permanent
                                  </span>
                                </label>
                                <label
                                  className={`inline-flex cursor-pointer w-1/3 border justify-center  text-xs rounded-full py-1 px-2  items-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="0"
                                    id="address_type"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type.toString() === "0"
                                    }
                                    className="form-radio text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">Other</span>
                                </label>
                              </div>
                              {errors.gender && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.gender}
                                </p>
                              )}
                            </div>
                          );
                        case "city":
                          return (
                            <div className="w-full">
                              <LocationAutocomplete
                                getLocation={handlePlaceSelected}
                                locationError={handleLocationError}
                                Error={errors["city"]}
                                val={values.city}
                              />
                            </div>
                          );
                        case "country":
                          return (
                            <div className="w-full">
                              <CountrySelect
                                getCountry={handleCountryChange}
                                country={country}
                                id={"2"}
                                error={errors["country"]}
                              />
                            </div>
                          );
                        case "proof_name":
                          return (
                            <IdSelect
                              getId={handleIdSelect}
                              code={country.value}
                              id={{
                                value: values.proof_id,
                                label: values.proof_name,
                              }}
                              error={errors["proof_name"]}
                            />
                          );
                        case "mobile_no":
                          return (
                            <PhoneForm
                              onPhoneChange={handlePhoneChange}
                              val={{
                                country_code: country.value ?? "in",
                                dial_code: country.dial_code ?? "91",
                                mobile: values.mobile_no ?? "",
                              }}
                              address={true}
                              Error={errors["mobile_no"]}
                            />
                          );
                        case "zip_code":
                          return (
                            <input
                              className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                errors[field.name] && touched[field.name]
                                  ? "border-red-500"
                                  : ""
                              }`}
                              type="number"
                              id={field.name}
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleFieldChange}
                              placeholder={field.placeholder}
                            />
                          );
                        default:
                          return (
                            <input
                              className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                errors[field.name] && touched[field.name]
                                  ? "border-red-500"
                                  : ""
                              }`}
                              type="text"
                              id={field.name}
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleFieldChange}
                              placeholder={field.placeholder}
                            />
                          );
                      }
                    })()}

                    {errors[field.name] && touched[field.name] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors[field.name]}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="w-full">
                    {(() => {
                      switch (field.name) {
                        case "address_type":
                          return (
                            <div>
                              <div className="flex">
                                <label
                                  className={`inline-flex cursor-pointer justify-center w-1/3 border mr-2 text-xs rounded-full py-1 px-2  items-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="1"
                                    id="address_type"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type?.toString() === "1"
                                    }
                                    className="form-radio  text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">
                                    Present
                                  </span>
                                </label>
                                <label
                                  className={`inline-flex cursor-pointer justify-center w-1/3 border mr-2 text-xs rounded-full py-1 px-2  items-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="2"
                                    id="address_type"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type?.toString() === "2"
                                    }
                                    className="form-radio text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">
                                    Permanent
                                  </span>
                                </label>
                                <label
                                  className={`inline-flex cursor-pointer justify-center w-1/3 border  text-xs rounded-full py-1 px-2  items-center ${
                                    errors.address_type
                                      ? "border-red-500"
                                      : "border-gray-400"
                                  } `}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="0"
                                    id="address_type"
                                    onChange={handleFieldChange}
                                    checked={
                                      values.address_type?.toString() === "0"
                                    }
                                    className="form-radio text-lime-900 border-lime-900"
                                  />
                                  <span className="ml-2 text-black">Other</span>
                                </label>
                              </div>
                              {values.address_type?.toString() === "2" && (
                                <div className="flex items-center justify-start mt-3 2xl:mb-0">
                                  <input
                                    type="checkbox"
                                    id="address_check"
                                    className="mr-2 cursor-pointer"
                                    checked={sameAsPresentChecked}
                                    onChange={() => {}}
                                    onClick={handleSameAsPresent}
                                  />
                                  <label className="text-xs">
                                    Same as present address
                                  </label>
                                </div>
                              )}

                              {errors.gender && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.gender}
                                </p>
                              )}
                            </div>
                          );
                        case "city":
                          return (
                            <div className="w-full">
                              <LocationAutocomplete
                                getLocation={handlePlaceSelected}
                                locationError={handleLocationError}
                                Error={errors["city"]}
                                val={values.city}
                              />
                            </div>
                          );
                        case "country":
                          return (
                            <div className="w-full">
                              <CountrySelect
                                getCountry={handleCountryChange}
                                country={country}
                                id={"2"}
                                error={errors["country"]}
                              />
                            </div>
                          );
                        case "proof_name":
                          return (
                            <IdSelect
                              getId={handleIdSelect}
                              code={country.value}
                              id={{
                                value: values.proof_id,
                                label: values.proof_name,
                              }}
                              error={errors["proof_name"]}
                            />
                          );
                        case "mobile_no":
                          return (
                            <PhoneForm
                              onPhoneChange={handlePhoneChange}
                              val={{
                                country_code: country.value ?? "in",
                                dial_code: country.dial_code ?? "91",
                                mobile: values.mobile_no ?? "",
                              }}
                              address={true}
                              Error={errors["mobile_no"]}
                            />
                          );
                        case "zip_code":
                          return (
                            <input
                              className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                errors[field.name] && touched[field.name]
                                  ? "border-red-500"
                                  : ""
                              }`}
                              type="number"
                              id={field.name}
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleFieldChange}
                              placeholder={field.placeholder}
                            />
                          );
                        default:
                          return (
                            <input
                              className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                errors[field.name] && touched[field.name]
                                  ? "border-red-500"
                                  : ""
                              }`}
                              type="text"
                              id={field.name}
                              name={field.name}
                              value={values[field.name]}
                              onChange={handleFieldChange}
                              placeholder={field.placeholder}
                            />
                          );
                      }
                    })()}

                    {errors[field.name] && touched[field.name] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors[field.name]}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray-300 my-4" />
          </div>
        ))}

        <div className="text-end">
          <>
            <button
              className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={loading}
            >
              {loading ? "Saving.." : "SAVE"}
            </button>
          </>
        </div>
      </form>

      {sameAsPresent && (
        <Modal isOpen={sameAsPresent} onClose={() => setSameAsPresent(false)}>
          <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
            <BsExclamationCircleFill className="text-white text-xl" />
          </div>
          <p className="my-5 text-lg font-normal text-custom text-center">
            ARE YOU SURE ?
          </p>
          <p className="text-xs text-center">
            Would you like to set this same as your present address?
          </p>
          <div className="px-[25px]">
            <div className="my-2 flex justify-center">
              <button
                className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => setSameAsPresent(false)}
              >
                CANCEL
              </button>
              <button
                className="bg-[#5E8862]  hover:bg-[#4F7552]  min-w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={loading}
                onClick={handleSameAsPresentAddress}
              >
                {loading ? "Saving..." : "CONFIRM"}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddressForm;
