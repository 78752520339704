import axios from "axios";
import {
  DELETE_OTHER_COUNTRY_PROFILE,
  FETCH_IDPROOF_LIST,
  FETCH_OTHER_COUNTRY_PROFILE,
  SET_AS_PRESENT_ADDRESS,
  UPDATE_OTHER_COUNTRY_PROFILE,
} from "../config/config";

// get other country details get api call
export const getOtherCountryDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_OTHER_COUNTRY_PROFILE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get other country profiles", error);
    throw error;
  }
};

//update other country data post api call
export const updateOtherCountryData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_OTHER_COUNTRY_PROFILE, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update other country data:", error);
    throw error;
  }
};

// get id list api call
export const getIdList = async (token, country_code) => {
  try {
    const response = await axios.post(
      FETCH_IDPROOF_LIST,
      {
        limit: "0",
        status: "1",
        country_code: country_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching id list", error);
    throw error;
  }
};

//Delete other country data post api call
export const deleteOtherCountryData = async (userData, token) => {
  try {
    const response = await axios.post(DELETE_OTHER_COUNTRY_PROFILE, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete other country data", error);
    throw error;
  }
};

//make as same address  post api call
export const makeSameAsPresentAddress = async (userData, token) => {
  try {
    const response = await axios.post(SET_AS_PRESENT_ADDRESS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error seting same as present address  data", error);
    throw error;
  }
};
