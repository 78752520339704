import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FaMicrophoneAlt, FaPen, FaRegEdit } from "react-icons/fa";
import * as Yup from "yup";
import KnowledgeSelect from "../../components/Select/Knowledge";
import { FiBookOpen, FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnglishDetails,
  getSkillList,
  updateEnglishData,
} from "../../api/englishApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import { getMenuState } from "../../api/sideBarApi";
import Skeleton from "../../components/Skeleton/Skeleton";
import { BiSolidHelpCircle } from "react-icons/bi";
import Modal from "../../components/Modal/Modal";

const validationSchema = Yup.object().shape({
  speak_skill_name: Yup.string().required("Speak skill is required"),
  read_skill_name: Yup.string().required("Read skill is required"),
  write_skill_name: Yup.string().required("Write skill is required"),
});

const EnglishKnowledge = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [englishData, setEnglishData] = useState({
    speak_skill: "",
    read_skill: "",
    write_skill: "",
  });
  const [help, setHelp] = useState(false);
  const [formData, setFormData] = useState({
    speak_skill: "",
    read_skill: "",
    write_skill: "",
  });
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [errors, setErrors] = useState({});
  const [skillOptions, setSkillOptions] = useState([]);

  useEffect(() => {
    const fetchEnglishDetails = async (token) => {
      try {
        const details = await getEnglishDetails(token);
        if (details.status === 1) {
          setEnglishData(details.data);
          setFormData(details.data);
          setSkeletonLoad(false);
        } else {
          console.error("Failed to fetch english details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error basic details :", error);
        setSkeletonLoad(false);
      }
    };

    fetchEnglishDetails(token);
  }, [token]);

  useEffect(() => {
    const fetchSkills = async (token) => {
      try {
        const skillData = await getSkillList(token);
        if (skillData.status === 1) {
          const options = skillData.data.map((skills) => ({
            value: skills.value,
            label: skills.label,
          }));
          setSkillOptions(options);
        } else {
          setSkillOptions([]);
        }
      } catch (error) {
        console.error("Failed to get knowledge list");
      }
    };

    fetchSkills(token);
  }, [token, dispatch]);
  const handleSpeakChange = (speak) => {
    if (speak) {
      setFormData({
        ...formData,
        speak_skill: speak?.value,
        speak_skill_name: speak?.label,
      });
    } else {
      setFormData({
        ...formData,
        speak_skill: null,
        speak_skill_name: null,
      });
    }

    setErrors({ ...errors, speak_skill_name: "" });
  };
  const handleReadChange = (read) => {
    if (read) {
      setFormData({
        ...formData,
        read_skill: read?.value,
        read_skill_name: read?.label,
      });
    } else {
      setFormData({
        ...formData,
        read_skill: null,
        read_skill_name: null,
      });
    }

    setErrors({ ...errors, read_skill_name: "" });
  };
  const handleWriteChange = (write) => {
    if (write) {
      setFormData({
        ...formData,
        write_skill: write?.value,
        write_skill_name: write?.label,
      });
    } else {
      setFormData({
        ...formData,
        write_skill: null,
        write_skill_name: null,
      });
    }

    setErrors({ ...errors, write_skill_name: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await updateEnglishData(formData, token);

      // store data to redux state in case of success
      if (response.status === 1) {
        setLoading(false);
        dispatch(setAlert(response.message, "success"));
        setFormData(response.data);
        setEnglishData(response.data);
        setIsEditMode(false);
        setErrors({});

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
        setLoading(false);
      }
    }
  };
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setFormData(englishData);
  };

  if (skeletonLoad) {
    return <Skeleton />;
  }
  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center overflow-x-hidden md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>

        <div className=" lg:w-[65%] md:w-full  w-[97%] mb-4 ">
          <div className="bg-white shadow-lg  w-full lg:ml-6 md:mx-2 mx-0 p-6 md:p-10 mb-4">
            <div className="mb-4 md:mb-6 md:flex items-center ">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">ENGLISH KNOWLEDGE</h2>
                  <button
                    className="ml-2  flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 mt-2 h-[2px] mb-2  "></div>
              </div>
              {!isEditMode && (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={toggleEditMode}
                >
                  <FaRegEdit className="mr-2" />
                  Edit
                </button>
              )}
            </div>

            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="lg:flex flex-row items-center ">
                <label className="text-gray-500 text-xs lg:w-[250px]">
                  Speak{isEditMode && <span className="text-red-500">*</span>}
                </label>
                <div className="flex items-center flex-1 mt-3  lg:mt-0">
                  {!isEditMode ? (
                    <>
                      {" "}
                      <FaMicrophoneAlt className="text-custom mr-3   text-[15px]" />
                      <span className="text-xs font-medium">
                        {englishData.speak_skill === 0
                          ? "Add your speaking skill"
                          : englishData.speak_skill_name}
                      </span>
                    </>
                  ) : (
                    <div className="text-xs w-full  ">
                      <KnowledgeSelect
                        getKnowledge={handleSpeakChange}
                        val={{
                          label: formData.speak_skill_name,
                          value: formData.speak_skill,
                        }}
                        options={skillOptions}
                        error={errors.speak_skill_name}
                      />
                      {errors.speak_skill_name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.speak_skill_name}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-gray-300 my-4" />
              <div className="lg:flex flex-row items-center ">
                <label className="text-gray-500 text-xs lg:w-[250px]">
                  Read{isEditMode && <span className="text-red-500">*</span>}
                </label>
                <div className="flex items-center flex-1  mt-3  lg:mt-0  ">
                  {!isEditMode ? (
                    <>
                      {" "}
                      <FiBookOpen className="text-custom mr-3  text-[15px]" />
                      <span className="text-xs font-medium">
                        {englishData.read_skill === 0
                          ? "Add your reading skill"
                          : englishData.read_skill_name}
                      </span>
                    </>
                  ) : (
                    <div className="text-xs w-full    ">
                      <KnowledgeSelect
                        getKnowledge={handleReadChange}
                        error={errors.read_skill_name}
                        val={{
                          label: formData.read_skill_name,
                          value: formData.read_skill,
                        }}
                        options={skillOptions}
                      />
                      {errors.read_skill_name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.read_skill_name}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-gray-300 my-4" />
              <div className="lg:flex flex-row items-center  ">
                <label className="text-gray-500 text-xs lg:w-[250px]">
                  Write{isEditMode && <span className="text-red-500">*</span>}
                </label>
                <div className="flex items-center flex-1  mt-3  lg:mt-0 ">
                  {!isEditMode ? (
                    <>
                      {" "}
                      <FaPen className="text-custom mr-3   text-[15px]" />
                      <span className="text-xs font-medium">
                        {englishData.write_skill === 0
                          ? "Add your writing skill"
                          : englishData.write_skill_name}
                      </span>
                    </>
                  ) : (
                    <div className="text-xs w-full   ">
                      <KnowledgeSelect
                        getKnowledge={handleWriteChange}
                        error={errors.write_skill_name}
                        val={{
                          label: formData.write_skill_name,
                          value: formData.write_skill,
                        }}
                        options={skillOptions}
                      />
                      {errors.write_skill_name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.write_skill_name}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-gray-300 my-4" />

              <div className="text-end">
                {isEditMode && (
                  <>
                    <button
                      className="border-custom hover:border-none border mr-3 mt-3 text-xs hover:bg-custom hover:text-white    w-[100px] text-custom     rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving.." : "SAVE"}
                    </button>
                  </>
                )}
              </div>
            </form>

            {!isEditMode && (
              <div className="text-end">
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  onClick={toggleEditMode}
                >
                  EDIT
                </button>
              </div>
            )}
          </div>
        </div>
        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"ENGLISH KNOWLEDGE INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {englishData.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: englishData.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default EnglishKnowledge;
