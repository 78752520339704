import React, { useEffect } from "react";

const Alert = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    // Cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className={`fixed top-[80px] right-4 z-[999]  p-4 mb-4 text-sm ${
        type === "success" ? "text-green-800" : "text-red-800"
      } rounded-lg ${type === "success" ? "bg-green-50" : "bg-red-50"}  `}
      role="alert"
    >
      {message}
    </div>
  );
};

export default Alert;
