import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element: Component }) => {
  const isAuthenticated = useSelector((state) => !!state.register.token);
  const registerTwoFlag = useSelector((state) => state.login.registerTwoFlag);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (registerTwoFlag === 0) {
    return <Navigate to="/register-step-2" />;
  }

  return <Component />;
};

export default ProtectedRoute;
