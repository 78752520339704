import Welcome from "../../components/Welcome/Welcome";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { setAlert } from "../../Redux/Actions/alertActions";
import { saveForgotData } from "../../Redux/Actions/loginActions";
import { forgotPassword, verifyForgotOtp } from "../../api/loginApi";
import { TbDots } from "react-icons/tb";

const Code = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentTime, setCurrentTime] = useState(null);
  const [expireTime, setExpireTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const inputRefs = useRef([]);
  const forgotData = useSelector((state) => state.login.forgotData);

  useEffect(() => {
    const savedExpireTime = forgotData.expire_time;
    if (savedExpireTime) {
      const now = Math.floor(Date.now() / 1000);
      const expire = parseInt(savedExpireTime, 10);
      if (now < expire) {
        setExpireTime(expire);
        setIsRunning(true);
        setCurrentTime(now);
      } else {
        setIsExpired(true);
        dispatch(
          saveForgotData({ ...forgotData, expire_time: null, message: "" })
        );
      }
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isRunning && expireTime) {
      interval = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const remaining = expireTime - now;
        if (remaining <= 0) {
          clearInterval(interval);
          setTimeLeft(0);
          setIsRunning(false);
          setIsExpired(true);

          dispatch(
            saveForgotData({ ...forgotData, expire_time: null, message: "" })
          );
        } else {
          setTimeLeft(remaining);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, expireTime]);

  const handleInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);

    // Move to the next input field automatically
    if (value.length === 1 && index < otpDigits.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    setErrors({ ...errors, otp: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otp = otpDigits.join("");
    try {
      setLoading(true);
      await validationSchema.validate({ otp }, { abortEarly: false });

      const response = await verifyForgotOtp({ ...forgotData, otp: otp });

      if (response.status === 1) {
        dispatch(saveForgotData({ token: response.data.token }));
        dispatch(setAlert(response.message, "success"));
        setOtpDigits(["", "", "", ""]);
        navigate("/reset-password");
        setErrors({});
        setLoading(false);
      } else {
        setOtpDigits(["", "", "", ""]);
        setLoading(false);
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.data.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
      }
       
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        // Handle validation errors
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  const handleBack = () => {
    navigate("/login-step-2");
  };

  const handleResentOtp = async (e) => {
    e.preventDefault();

    try {
      setErrors({});
      const response = await forgotPassword(forgotData);

      if (response.data.status === 1) {
        const now = Math.floor(Date.now() / 1000);
        const expire = now + parseInt(response.data.data.expire_time);
        const remaining = expire - now;
        setExpireTime(expire);
        setIsExpired(false);
        dispatch(
          saveForgotData({
            ...forgotData,
            message: response.data.message,
            expire_time: expire,
          })
        );
        setTimeLeft(remaining);
        setIsRunning(true);
        setOtpDigits(["", "", "", ""]);
        navigate("/verify-code");
      } else {
        dispatch(setAlert(response.data.message, "error"));
      }
    } catch (error) {
      console.error("Error in reset otp", error);
    }
  };

  // Validation schema for OTP digits
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^\d{4}$/, "Code must be 4 digits")
      .required("Code is required"),
  });

  return (
    <div className="flex bg-custom-pattern justify-center items-center min-h-page-height">
      <div className="container mx-auto">
        <div className="flex flex-col md:justify-around md:flex-row">
          {/* Left Section */}
          <Welcome />
          {/* Right Section */}
          <div className="lg:w-2/5 md:w-[60%] p-4 md:my-auto">
            <div className="bg-white lg:w-11/12   rounded-lg shadow-lg p-9">
              <h2 className="text-lg text-custom text-center pb-4 my-4">
                GET YOUR CODE
              </h2>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                {/* Label for OTP Fields */}
                <label className="block text-gray-700 text-xs mb-6">
                  Please enter the code that was sent to your email address or
                  mobile number.
                </label>
                <p className="text-xs text-center text-custom mb-4">
                  {forgotData?.message}
                </p>
                {/* OTP Fields */}
                <div className="mb-4 mt-2 px-6 flex justify-center">
                  {otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      className={`border border-gray-400 rounded px-4 py-2 text-xs w-[25%] text-center focus:outline-none  ${
                        digit.length === 1 ? "bg-gray-100" : ""
                      } ${errors.otp ? "border-red-500" : ""} ${
                        index === otpDigits.length - 1 ? "" : "mr-8"
                      }`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  ))}
                </div>
                {/* Error message for OTP */}
                {errors.otp && (
                  <p className="text-red-500 text-xs mt-1 ml-6 ">
                    {errors.otp}
                  </p>
                )}

                {!isExpired && timeLeft && (
                  <div className="text-end text-xs pe-6 py-4">
                    <div className="text-xs font-medium">
                      Resend in {timeLeft} second{" "}
                    </div>{" "}
                  </div>
                )}

                {forgotData.message && !isExpired && timeLeft === null && (
                  <div className="text-end pe-6 py-0 ">
                    {" "}
                    <div class="loading  ">
                      <TbDots className=" font-bold" />
                    </div>{" "}
                  </div>
                )}

                {!forgotData.message && (
                  <div className="text-end text-xs pe-6 py-4 ">
                    {" "}
                    Don't get code?{" "}
                    <button
                      className="text-custom font-medium"
                      onClick={handleResentOtp}
                    >
                      Resend
                    </button>
                  </div>
                )}

                {/* Buttons */}
                <div className="flex justify-between mb-6">
                  <button
                    className="border hover:bg-custom hover:text-white border-custom w-1/2 rounded-full   text-custom text-xs mt-4 py-2 px-4 focus:outline-none focus:shadow-outline mr-2"
                    type="button"
                    onClick={handleBack}
                  >
                    BACK
                  </button>
                  <button
                    className="bg-[#5E8862] w-1/2 text-xs rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline ml-2"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Code;
