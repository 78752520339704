import axios from "axios";
import {
  DELETE_QUALIFICATIONS,
  FETCH_BOARD_LIST,
  FETCH_MARK_LIST,
  FETCH_QUALIFICATIONS,
  FETCH_SPECIALISATION_LIST,
  FETCH_STATUS_LIST,
  UPDATE_QUALIFICATIONS,
} from "../config/config";

// get other qualification details get api call
export const getQualificationsDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_QUALIFICATIONS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get qualifications", error);
    throw error;
  }
};

//update qualifications data post api call
export const updateQualificationData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_QUALIFICATIONS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update qualifications data:", error);
    throw error;
  }
};

// get skill level list api call
export const getStatusList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_STATUS_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching status list", error);
    throw error;
  }
};

// get specialisation list api call
export const getSpecialisationList = async (token, id) => {
  try {
    const response = await axios.post(
      FETCH_SPECIALISATION_LIST,
      {
        limit: "0",
        status: "1",
        course_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching specialisation list", error);
    throw error;
  }
};

// get mark list api call
export const getMarkList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_MARK_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mark list", error);
    throw error;
  }
};

// get mark list api call
export const getBoardList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_BOARD_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching board list", error);
    throw error;
  }
};

//Delete qualification data post api call
export const deleteQualificationnsData = async (userData, token) => {
  try {
    const response = await axios.post(DELETE_QUALIFICATIONS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete qualifiction data:", error);
    throw error;
  }
};
