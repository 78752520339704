import axios from "axios";
import {
  FETCH_BASIC_DETAILS,
  FETCH_PIC_DETAILS,
  SENT_OTP,
  UPDATE_BASIC_DETAILS,
  UPLOAD_PIC,
  VERIFY_OTP,
} from "../config/config";

// get basic details get api call
export const getBasicDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_BASIC_DETAILS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get basic details:", error);
    throw error;
  }
};

// Function to send a picture via API
export const sendPicture = async (token, pictureFile) => {
  try {
    const response = await axios.post(UPLOAD_PIC, pictureFile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error sending picture:", error);
    throw error;
  }
};

// Function to fetch profile pics via API
export const getProfilePicture = async (token) => {
  try {
    const response = await axios.get(FETCH_PIC_DETAILS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error getting picture:", error);
    throw error;
  }
};

// Function to basic details via API
export const updateBasicDetails = async (token, data) => {
  try {
    const response = await axios.post(UPDATE_BASIC_DETAILS, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error in update details", error);
    throw error;
  }
};

// Function to SEND OTP POST  API
export const sentOtp = async (token, data) => {
  try {
    const response = await axios.post(SENT_OTP, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("sent otp  API", response);
    return response.data;
  } catch (error) {
    console.error("Error in sent otp", error);
    throw error;
  }
};

// Function to verify OTP POST  API
export const verifyOtp = async (token, data) => {
  try {
    const response = await axios.post(VERIFY_OTP, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("verify otp  API", response);
    return response.data;
  } catch (error) {
    console.error("Error in verify otp", error);
    throw error;
  }
};
