import {
  FORGOT_DATA,
  LOGIN_STEP_ONE_DATA,
  REGISTER_TWO_FLAG,
} from "../Actions/loginActions";

// Retrieve data from local storage if available

const LoginOneData = JSON.parse(localStorage.getItem("LoginOneData"));

const ForgotData = JSON.parse(localStorage.getItem("ForgotData"));

const initialState = {
  stepOneData: LoginOneData
    ? LoginOneData
    : {
        inputtype: "",
        username: "",
        country_code: "",
        dial_code: "",
      },
  registerTwoFlag: JSON.parse(localStorage.getItem("registerTwoFlag")),

  forgotData: ForgotData
    ? ForgotData
    : {
        inputtype: "",
        username: "",
        country_code: "",
        dial_code: "",
      },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STEP_ONE_DATA:
      localStorage.setItem("LoginOneData", JSON.stringify(action.payload));
      return {
        ...state,
        stepOneData: action.payload,
      };
    case FORGOT_DATA:
      localStorage.setItem("ForgotData", JSON.stringify(action.payload));
      return {
        ...state,
        forgotData: action.payload,
      };
    case REGISTER_TWO_FLAG:
      // Save data to local storage
      localStorage.setItem("registerTwoFlag", JSON.stringify(action.payload));

      return {
        ...state,
        registerTwoFlag: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
