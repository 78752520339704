import axios from "axios";
import {
  FETCH_PUBLICITY_ADDRESS,
  UPDATE_PUBLICITY_ADDRESS,
} from "../config/config";

// get publicity details get api call
export const getPublicityAddress = async (token) => {
  try {
    const response = await axios.get(FETCH_PUBLICITY_ADDRESS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get publicity address", error);
    throw error;
  }
};

//update publicity address  data post api call
export const updatePublicityAddress = async (data, token) => {
  try {
    const response = await axios.post(UPDATE_PUBLICITY_ADDRESS, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update publicity address", error);
    throw error;
  }
};
