export const BASIC_DETAILS_DATA = "BASIC_DETAILS_DATA";

export const ADDRESS_DATA = "ADDRESS_DATA";

export const BASIC_DETAILS = "BASIC_DETAILS";

export const SET_TOGGLE = "SET_TOGGLE";

export const SAVE_MENU_DATA = "SAVE_MENUE_DATA";

export const saveBasicDetailsData = (data) => {
  return {
    type: BASIC_DETAILS_DATA,
    payload: data,
  };
};

export const saveAddressData = (data) => {
  return {
    type: ADDRESS_DATA,
    payload: data,
  };
};

export const setSidebarToggle = (data) => {
  return {
    type: SET_TOGGLE,
    payload: data,
  };
};

export const saveMenuData = (data) => {
  return {
    type: SAVE_MENU_DATA,
    payload: data,
  };
};

export const setBasicDetailsData = (data) => {
  return {
    type: BASIC_DETAILS,
    payload: data,
  };
};
