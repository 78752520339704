import React, { useState } from "react";
import Select from "react-select";

const DurationSelect = ({ getDuration, error, valYear, valMonth }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  // Initial state for day, month, and year

  const [selectedMonth, setSelectedMonth] = useState(
    valMonth ? valMonth : null
  );

  const [selectedYear, setSelectedYear] = useState(valYear ? valYear : null);

  const monthOptions = [
    { value: 0, label: "0 Month" },
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Months" },
    { value: 3, label: "3 Months" },
    { value: 4, label: "4 Months" },
    { value: 5, label: "5 Months" },
    { value: 6, label: "6 Months" },
    { value: 7, label: "7 Months" },
    { value: 8, label: "8 Months" },
    { value: 9, label: "9 Months" },
    { value: 10, label: "10 Months" },
    { value: 11, label: "11 Months" },
  ];

  const yearOptions = [
    { value: 0, label: "0 Year" },
    { value: 1, label: "1 Year" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10 Years" },
    { value: 11, label: "11 Years" },
  ];

  // Function to handle change in month selection
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    passDate(selectedOption, selectedYear);
  };

  // Function to handle change in year selection
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    passDate(selectedMonth, selectedOption);
  };

  // Function to pass the selected date
  const passDate = (month, year) => {
    if (year) {
      getDuration({
        month: month?.value,
        year: year.value,
        month_name: month?.label,
        year_name: year.label,
      });
    } else {
      getDuration(null);
    }
  };

  return (
    <>
      <div className="flex">
        {/* Year */}
        <Select
          className={`w-1/2 mr-2 text-xs`}
          options={yearOptions}
          onChange={handleYearChange}
          isClearable={true}
          value={selectedYear}
          styles={customStyles}
          placeholder={"Select Year"}
          theme={(theme) => ({
            ...theme,
            borderRadius: "7px",
            boxShadow: null,
            colors: {
              ...theme.colors,
              primary25: "#526754",
              primary: "#526754",
            },
          })}
        />
        {/* Month */}
        <Select
          className={`w-1/2  text-xs`}
          options={monthOptions}
          onChange={handleMonthChange}
          isClearable={true}
          value={selectedMonth}
          placeholder={"Select Month"}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: "7px",
            boxShadow: null,
            colors: {
              ...theme.colors,
              primary25: "#526754",
              primary: "#526754",
            },
          })}
        />
      </div>
    </>
  );
};

export default DurationSelect;
