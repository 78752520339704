import React, { useEffect, useRef, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import Modal from "../Modal/Modal";
import * as yup from "yup";
import { sentOtp, verifyOtp } from "../../api/basicDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";

const EmailVerifyModal = ({ isOpen, onClose, verify }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [otpMessage, setOtpMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const inputRefs = useRef([]);

  useEffect(() => {
    const fetchOtp = async (token) => {
      try {
        const emailOtp = await sentOtp(token, {
          otp_type: 3,
        });
        if (emailOtp.status === 1) {
          setOtpMessage(emailOtp.message);
        } else {
          setOtpMessage(emailOtp.message);
          console.error("Failed to get email otp ");
        }
      } catch (error) {
        console.error("Error in getting email otp", error);
      }
    };

    fetchOtp(token);
    inputRefs.current[0].focus();
  }, []);

  const handleInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);
    setErrors({});
    // Move to the next input field automatically
    if (value.length === 1 && index < otpDigits.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otp = otpDigits.join("");
    try {
      setLoading(true);
      await validationSchema.validate({ otp }, { abortEarly: false });

      const response = await verifyOtp(token, {
        otp: otp,
        otp_type: "3",
      });
  
      if (response.status === 1) {
        verify(true);
        dispatch(setAlert(response.message, "success"));
        setErrors({});
        setOtpDigits(["", "", "", ""]);
        setLoading(false);
        onClose();
      } else {
        setErrors({ otp: response.message });
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        // Handle validation errors
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  // Validation schema for OTP digits
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d{4}$/, "OTP must be 4 digits")
      .required("OTP is required"),
  });

  const handleCancel = () => {
    setOtpDigits(["", "", "", ""]);
    setErrors({});
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
        <FaEnvelope className="text-white text-xl" />
      </div>
      <p className="my-5 text-lg font-normal text-custom text-center">
        VERIFY YOUR EMAIL ADDRESS
      </p>
      <p className="mb-5 text-sm font-normal   text-center">{otpMessage}</p>
      <div className="px-[25px]">
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          {/* OTP Fields */}
          <div className="mb-4 mt-2 flex justify-center">
            {otpDigits.map((digit, index) => (
              <input
                key={index}
                ref={(ref) => (inputRefs.current[index] = ref)}
                className={`border border-gray-400 rounded px-4 py-2 text-xs w-[25%] text-center focus:outline-none  ${
                  digit.length === 1 ? "bg-gray-100" : ""
                } ${errors.otp ? "border-red-500" : ""} ${
                  index === otpDigits.length - 1 ? "" : "mr-6"
                }`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
            ))}
          </div>
          {/* Error message for OTP */}
          {errors.otp && (
            <p className="text-red-500 text-xs mt-1 ">{errors.otp}</p>
          )}
          {/* Buttons */}
          <div className="flex justify-end  ">
            <button
              className="border hover:bg-custom hover:text-white border-custom w-1/2 rounded-full   text-custom text-xs mt-4 py-2 px-4 focus:outline-none focus:shadow-outline mr-2"
              type="button"
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className="bg-[#5E8862] w-1/2 text-xs rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline ml-2"
              type="submit"
              disabled={loading}
            >
              {loading ? "Verifying..." : "VERIFY"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailVerifyModal;
