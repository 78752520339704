import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "../../components/Accordion/Accordion";
import { IoLocationOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaCalendarAlt, FaPlus } from "react-icons/fa";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import Modal from "../../components/Modal/Modal";
import { BsExclamationCircleFill } from "react-icons/bs";
import { professionalData } from "../../constants/fieldData";
import LocationAutocomplete from "../../components/Location/Location";
import OccupationSelect from "../../components/Select/Occupation";
import JobCategorySelect from "../../components/Select/JobCategory";
import DesignationSelect from "../../components/Select/Designation";
import JobSpecialisationSelect from "../../components/Select/JobSpecialisation";
import FromToSelect from "../../components/Select/FromTo";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
import {
  deleteProfessionData,
  getProfessionalDetails,
  updateProfessionData,
} from "../../api/professionApi";
import { getMenuState } from "../../api/sideBarApi";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import AccordionSkeleton from "../../components/Skeleton/AccordionSkeleton";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";

const ProfessionalDetails = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [addNew, setAddNew] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [city, setCity] = useState({
    employer_city: "",
    employer_country: "",
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [closeAccordion, setCloseAccordion] = useState(false);
  const [isCurrentWork, setIsCurrentWork] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteProfession, setDeleteProfession] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchProfessionalDetails = async (token) => {
      try {
        const details = await getProfessionalDetails(token);

        if (details.status === 1) {
          setProfessions(details.data);
          setSkeletonLoad(false);
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to fetch profession details details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error profession details details :", error);
        setSkeletonLoad(false);
      }
    };
    fetchProfessionalDetails(token);
  }, [token]);

  const handleEdit = (index) => {
    formikEdit.setValues(professions[index]);
    setEditIndex(index);
    handleAddNewCancel();
  };

  const handleDelete = (index) => {
    setDeleteProfession(index);
    setDeleteModal(true);
  };

  const handleProfessionDelete = async () => {
    const delete_id = professions[deleteProfession].id;
    try {
      setLoading(true);
      const response = await deleteProfessionData(
        {
          id: delete_id,
        },
        token
      );
      if (response.status === 1) {
        setLoading(false);
        setProfessions(response.data);

        dispatch(setAlert(response.message, "success"));
        setDeleteModal(false);

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error dleteing professions ", error.message);

      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditIndex(null);
  };

  const handleAddNew = () => {
    setAddNew(true);
    setIsCurrentWork(false);
    setEditIndex(null);
    setCloseAccordion(true);
    setTimeout(() => {
      setCloseAccordion(false);
    }, 200);
  };
  const validationSchema = Yup.object().shape({
    occupation_name: Yup.string().required("Occupation name is required"),
    profession_name: Yup.string().required("Profession name is required"),
    designation_name: Yup.string().required("Designation name is required"),
    specialization_name: Yup.string().required(
      "Specialization name is required"
    ),
    specialization_id_other: Yup.string().when(
      "specialization_name",
      (disciplineName, schema) => {
        return disciplineName[0] === "Other"
          ? schema.required("Specialised Area is required")
          : schema.notRequired();
      }
    ),
    occupation_id_other: Yup.string().when(
      "occupation_name",
      (occupation_name, schema) => {
        return occupation_name[0] === "Other"
          ? schema.required("Occupation name is required")
          : schema.notRequired();
      }
    ),
    profession_id_other: Yup.string().when(
      "profession_name",
      (profession_name, schema) => {
        return profession_name[0] === "Other"
          ? schema.required("Profession name is required")
          : schema.notRequired();
      }
    ),
    designation_id_other: Yup.string().when(
      "designation_name",
      (designation_name, schema) => {
        return designation_name[0] === "Other"
          ? schema.required("Designation name is required")
          : schema.notRequired();
      }
    ),
    employer_name: Yup.string().required("Employer name is required"),
    employer_city: Yup.string().required(
      "Employer city and country are required"
    ),
    from_date: Yup.string()
      .required("Duration is required")
      .test("is-to-date-required", "Duration is required", function (value) {
        const { to_date } = this.parent;
        if (!isCurrentWork && !to_date) {
          return false;
        }
        return true;
      }),
    to_date: Yup.string()
      .nullable()
      .test("is-to-date-required", "Duration is required", function (value) {
        if (!isCurrentWork) {
          return !!value;
        }
        return true;
      }),
  });

  const handleCityError = (error) => {
    // Clear location  error when the user starts typing
    if (!error) {
      formik.setErrors({ ...formik.errors, employer_city: "" });
    }
  };

  const handleCitySelected = (location) => {
    if (location?.city) {
      setCity({
        employer_city:
          location && `${location.city},${location.state},${location.country}`,
        employer_country: location && location.country_code,
      });
    } else {
      setCity({
        employer_city: null,
        employer_country: null,
      });
    }
  };

  useEffect(() => {
    const handleCity = () => {
      if (city) {
        formik.setValues({
          ...formik.values,
          employer_city: city?.employer_city,
          employer_country: city?.employer_country,
        });
      } else {
        formik.setValues({
          ...formik.values,
          employer_city: null,
          employer_country: null,
        });
      }
    };
    handleCity();
  }, [city]);

  const handleOccupationSelect = (occupation) => {
    if (occupation) {
      formik.setValues({
        ...formik.values,
        occupation_name: occupation?.label,
        occupation_id: occupation?.value,
        profession_name: null,
        profession_id: null,
        designation_name: null,
        designation_id: null,
        specialization_name: null,
        specialization_id: null,
      });
    } else {
      formik.setValues({
        ...formik.values,
        occupation_name: null,
        occupation_id: null,
        profession_name: null,
        profession_id: null,
        designation_name: null,
        designation_id: null,
        specialization_name: null,
        specialization_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      occupation_name: "",
      occupation_id_other: "",
    });
  };

  const handleJobCategory = (category) => {
    if (category) {
      formik.setValues({
        ...formik.values,
        profession_name: category?.label,
        profession_id: category?.value,
        designation_name: null,
        designation_id: null,
        specialization_name: null,
        specialization_id: null,
      });
    } else {
      formik.setValues({
        ...formik.values,
        profession_name: null,
        profession_id: null,
        designation_name: null,
        designation_id: null,
        specialization_name: null,
        specialization_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      profession_name: "",
      profession_id_other: "",
    });
  };

  const handleDesignation = (designation) => {
    if (designation) {
      formik.setValues({
        ...formik.values,
        designation_name: designation?.label,
        designation_id: designation?.value,
      });
    } else {
      formik.setValues({
        ...formik.values,
        designation_name: null,
        designation_id: null,
        specialization_name: null,
        specialization_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      designation_name: "",
      designation_id_other: "",
    });
  };

  const handleFromDate = (from) => {
    if (from?.month && from?.year) {
      formik.setValues({
        ...formik.values,
        from_month: from?.month,
        from_year: from?.year,
        from_month_name: from?.month_name,
        from_year_name: from?.year_name,
        from_date: from && `${from.month_name}-${from.year_name}`,
      });

      setFromDate(`${from.month_name}-${from.year_name}`);
    } else {
      formik.setValues({
        ...formik.values,
        from_month: null,
        from_year: null,
        from_month_name: null,
        from_year_name: null,
        from_date: null,
      });
    }

    formik.setErrors({ ...formik.errors, from_date: "", to_date: "" });
  };

  const handleToDate = (to) => {
    if (to?.month && to?.year) {
      formik.setValues({
        ...formik.values,
        to_month: to?.month,
        to_year: to?.year,
        to_month_name: to?.month_name,
        to_year_name: to?.year_name,
        to_date: to && `${to.month_name}-${to.year_name}`,
      });

      setToDate(`${to.month_name}-${to.year_name}`);
    } else {
      formik.setValues({
        ...formik.values,
        to_month: null,
        to_year: null,
        to_month_name: null,
        to_year_name: null,
        to_date: null,
      });
    }

    formik.setErrors({ ...formik.errors, to_date: "", from_date: "" });
  };

  useEffect(() => {
    // Calculate the difference in years and months
    let yearDifference = values?.to_year - values?.from_year;
    let monthDifference = values?.to_month - values?.from_month;

    // Adjust the year difference if the month difference is negative
    if (monthDifference < 0) {
      yearDifference--;
      monthDifference += 12;
    }

    // Calculate the total experience in years as a decimal

    let totalExperience = `${yearDifference}.${monthDifference}`;
    if (totalExperience && !isCurrentWork) {
      formik.setValues({
        ...formik.values,

        exp_in_year: totalExperience,
      });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (isCurrentWork) {
      // Get the current date
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();

      let yearDifference = currentYear - values?.from_year;
      let monthDifference = currentMonth - values?.from_month;

      // Adjust the year difference if the month difference is negative
      if (monthDifference < 0) {
        yearDifference--;
        monthDifference += 12;
      }
      let totalExperience = `${yearDifference}.${monthDifference}`;
      formik.setValues({
        ...formik.values,

        exp_in_year: totalExperience,
      });
    }
  }, [isCurrentWork, fromDate]);

  const handleSpecialisationSelect = (specialisation) => {
    if (specialisation) {
      formik.setValues({
        ...formik.values,
        specialization_name: specialisation?.label,
        specialization_id: specialisation?.value,
      });
    } else {
      formik.setValues({
        ...formik.values,
        specialization_name: null,
        specialization_id: null,
      });
    }

    formik.setErrors({
      ...formik.errors,
      specialization_name: "",
      specialization_id_other: "",
    });
  };

  // Function to handle form submission when adding a new item
  const onSubmitAdd = async (values, { setErrors }) => {
    try {
      setLoading(true);
      const response = await updateProfessionData(values, token);

      if (response.status === 1) {
        setLoading(false);
        setProfessions(response.data);
        dispatch(setAlert(response.message, "success"));
        setAddNew(false);
        formikAdd.resetForm();

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  // Function to handle form submission when editing an existing item
  const onSubmitEdit = async (values, { setErrors }) => {
    const edit_id = professions[editIndex].id;
    const editValues = { ...values, id: edit_id };

    try {
      setLoading(true);
      const response = await updateProfessionData(editValues, token);

      if (response.status === 1) {
        setLoading(false);
        setProfessions(response.data);
        dispatch(setAlert(response.message, "success"));
        setEditIndex(null);

        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        setErrors(response.errors);
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      setLoading(false);
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the field value
    formikAdd.handleChange(e);

    // Clear errors for the field
    formikAdd.setErrors({
      ...formikAdd.errors,
      [name]: undefined,
    });

    formikEdit.setValues({
      ...formikEdit.values,
      [name]: value,
    });

    formikEdit.setErrors({
      ...formikEdit.errors,
      [name]: undefined,
    });
  };

  const handleAddNewCancel = () => {
    setAddNew(false);
    formikAdd.resetForm();
  };

  // For adding new item
  const initialValuesAdd = {};
  professionalData.forEach((field) => {
    initialValuesAdd[field.name] = "";
  });

  const formikAdd = useFormik({
    initialValues: initialValuesAdd,
    validationSchema: validationSchema,
    onSubmit: onSubmitAdd,
    validateOnChange: false,
    validateOnBlur: true,
  });

  // For editing existing item
  const initialValuesEdit = {};

  const formikEdit = useFormik({
    initialValues: initialValuesEdit,
    validationSchema: validationSchema,
    onSubmit: onSubmitEdit,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const handleCurrentWorkToggle = () => {
    setIsCurrentWork(!isCurrentWork);
    formik.setValues({
      ...formik.values,
      to_month: null,
      to_year: null,
      to_month_name: null,
      to_year_name: null,
      to_date: null,
      exp_in_year: null,
    });
    if (!isCurrentWork) {
      // Get the current date
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();

      let yearDifference = currentYear - values?.from_year;
      let monthDifference = currentMonth - values?.from_month;

      // Adjust the year difference if the month difference is negative
      if (monthDifference < 0) {
        yearDifference--;
        monthDifference += 12;
      }
      let totalExperience = `${yearDifference}.${monthDifference}`;
      formik.setValues({
        ...formik.values,

        exp_in_year: totalExperience,
      });
    }
  };

  useEffect(() => {
    if (isCurrentWork) {
      formik.setValues({ ...formik.values, to_month: "", to_year: "" });
    }
  }, [isCurrentWork]);

  useEffect(() => {
    if (professions[editIndex]?.to_date_formated === "Still") {
      setIsCurrentWork(true);
    } else {
      setIsCurrentWork(false);
    }
  }, [professions, editIndex]);

  useEffect(() => {
    setTimeout(() => {
      formikEdit.setValues(professions[editIndex]);
    }, 500);
  }, [editIndex]);

  // Selecting the correct formik object based on whether adding new or editing
  const formik = addNew ? formikAdd : formikEdit;

  const { values, errors, touched } = formik;

  if (skeletonLoad) {
    return <AccordionSkeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center  md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>
        <div className="lg:w-[65%] w-[97%] mb-4  h-fit lg:ml-6 md:ml-2 ml-0">
          <div className="bg-white shadow-md">
            <div className="mb-4 md:flex items-center p-6 md:p-10 pb-4">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">PROFESSIONAL DETAILS</h2>
                  <button
                    className="ml-2  flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 mt-2 h-[2px] mb-2"></div>
              </div>
              {!addNew && (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2" />
                  Add new
                </button>
              )}
            </div>
          </div>

          {professions.map((profession, index) => (
            <div className="mt-6" key={index}>
              <Accordion>
                <AccordionItem
                  title={profession.heading_name}
                  onEdit={() => handleEdit(index)}
                  onDelete={() => handleDelete(index)}
                  firstAccordion={index}
                  closeAll={closeAccordion}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="md:ps-8 ps-6 pe-4"
                  >
                    {professionalData.map((field) => (
                      <React.Fragment key={field.name}>
                        <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                          {editIndex === index
                            ? (() => {
                                switch (field.name) {
                                  case "profession_name":
                                    return (
                                      values["occupation_name"] && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );
                                  case "designation_name":
                                    return (
                                      values["profession_name"] && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );
                                  case "specialization_name":
                                    return (
                                      values["profession_name"] &&
                                      values["designation_name"] && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );

                                  case "specialization_id_other":
                                    return (
                                      values["specialization_id"] === "-1" && (
                                        <label className="text-gray-500 text-xs lg:w-[250px]">
                                          {field.label}
                                          {editIndex === index &&
                                            field.required && (
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            )}
                                        </label>
                                      )
                                    );
                                  case "to_date":
                                    return "";

                                  default:
                                    return (
                                      <label className="text-gray-500 text-xs lg:w-[250px]">
                                        {field.label}
                                        {editIndex === index &&
                                          field.required && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                      </label>
                                    );
                                }
                              })()
                            : (() => {
                                switch (field.name) {
                                  case "specialization_id_other":
                                    return "";
                                  case "to_date":
                                    return "";
                                  default:
                                    return (
                                      <label className="text-gray-500 text-xs lg:w-[250px]">
                                        {field.label}
                                        {editIndex === index &&
                                          field.required && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                      </label>
                                    );
                                }
                              })()}

                          <div className="text-xs w-full mt-3 lg:mt-0 flex items-center">
                            {editIndex !== index ? (
                              <>
                                {(() => {
                                  switch (field.name) {
                                    case "from_date":

                                    case "exp_in_year":
                                      return (
                                        <FaCalendarAlt className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "employer_country":
                                      return (
                                        <IoLocationOutline className="text-custom mr-3 text-[15px]" />
                                      );
                                    case "specialization_id_other":
                                      return "";
                                    case "occupation_id_other":
                                      return "";
                                    case "profession_id_other":
                                      return "";
                                    case "designation_id_other":
                                      return "";
                                    case "to_date":
                                      return "";
                                    default:
                                      return (
                                        <PiSuitcaseSimpleFill className="text-custom mr-3 text-[15px]" />
                                      );
                                  }
                                })()}
                                {(() => {
                                  switch (field.name) {
                                    case "from_date":
                                      return (
                                        <span className="text-xs">
                                          {profession.from_date_formated}
                                        </span>
                                      );
                                    case "to_date":
                                      return "";

                                    default:
                                      return (
                                        <span className="text-xs font-medium">
                                          {profession[field.name]}
                                        </span>
                                      );
                                  }
                                })()}
                              </>
                            ) : (
                              <div className="text-xs w-full">
                                {(() => {
                                  switch (field.name) {
                                    case "occupation_name":
                                      return (
                                        <OccupationSelect
                                          getOccupation={handleOccupationSelect}
                                          error={errors["occupation_name"]}
                                          val={{
                                            value: values.occupation_id,
                                            label: values.occupation_name,
                                          }}
                                        />
                                      );

                                    case "profession_name":
                                      return (
                                        values["occupation_name"] && (
                                          <JobCategorySelect
                                            getJobCategory={handleJobCategory}
                                            id={values["occupation_id"]}
                                            error={errors["profession_name"]}
                                            val={{
                                              value: values.profession_id,
                                              label: values.profession_name,
                                            }}
                                          />
                                        )
                                      );
                                    case "designation_name":
                                      return (
                                        values["profession_name"] && (
                                          <DesignationSelect
                                            getDesignation={handleDesignation}
                                            id={values["profession_id"]}
                                            error={errors["designation_name"]}
                                            val={{
                                              value: values.designation_id,
                                              label: values.designation_name,
                                            }}
                                          />
                                        )
                                      );
                                    case "specialization_name":
                                      return (
                                        values["profession_name"] &&
                                        values["designation_name"] && (
                                          <JobSpecialisationSelect
                                            getSpecialisation={
                                              handleSpecialisationSelect
                                            }
                                            error={
                                              errors["specialization_name"]
                                            }
                                            val={{
                                              value: values.specialization_id,
                                              label: values.specialization_name,
                                            }}
                                            id={values["profession_id"]}
                                          />
                                        )
                                      );

                                    case "specialization_id_other":
                                      return (
                                        values["specialization_id"] ===
                                          "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name] &&
                                              touched[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "occupation_id_other":
                                      return (
                                        values["occupation_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "profession_id_other":
                                      return (
                                        values["profession_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "designation_id_other":
                                      return (
                                        values["designation_id"] === "-1" && (
                                          <input
                                            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                              errors[field.name]
                                                ? "border-red-500"
                                                : ""
                                            }`}
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            value={values[field.name]}
                                            onChange={handleFieldChange}
                                            placeholder={field.placeholder}
                                          />
                                        )
                                      );
                                    case "employer_city":
                                      return (
                                        <LocationAutocomplete
                                          getLocation={handleCitySelected}
                                          locationError={handleCityError}
                                          Error={errors["employer_city"]}
                                          val={values.employer_city}
                                        />
                                      );
                                    case "from_date":
                                      return (
                                        <>
                                          <div className="lg:flex">
                                            <div className="mr-1 w-[50%]">
                                              <FromToSelect
                                                getDuration={handleFromDate}
                                                error={errors["from_date"]}
                                                valMonth={{
                                                  value: values.from_month,
                                                  label:
                                                    values.from_date_formated,
                                                }}
                                                valYear={{
                                                  value: values.from_year,
                                                  label:
                                                    values.from_date_formated,
                                                }}
                                                title={"From Date"}
                                              />
                                            </div>
                                            <div className="w-[50%]">
                                              <FromToSelect
                                                getDuration={handleToDate}
                                                error={errors["to_date"]}
                                                valMonth={{
                                                  value: values.to_month,
                                                  label:
                                                    values.to_date_formated,
                                                }}
                                                valYear={{
                                                  value: values.to_year,
                                                  label:
                                                    values.to_date_formated,
                                                }}
                                                title={"To Date"}
                                                isCurrentWork={isCurrentWork}
                                              />
                                            </div>
                                          </div>
                                          {/* Checkbox for present address */}
                                          <div className="flex items-center mt-3">
                                            <input
                                              type="checkbox"
                                              id="currentWork"
                                              checked={isCurrentWork}
                                              onChange={handleCurrentWorkToggle}
                                              className="mr-2"
                                            />
                                            <label
                                              htmlFor="currentWork"
                                              className="text-xs text-gray-500"
                                            >
                                              Currently working
                                            </label>
                                          </div>
                                        </>
                                      );
                                    case "to_date":
                                      return "";
                                    case "exp_in_year":
                                      return (
                                        <span className="text-xs">
                                          {values[field.name]}
                                        </span>
                                      );

                                    default:
                                      return (
                                        <input
                                          className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                            errors[field.name]
                                              ? "border-red-500"
                                              : ""
                                          }`}
                                          type="text"
                                          id={field.name}
                                          name={field.name}
                                          value={values[field.name]}
                                          onChange={handleFieldChange}
                                          placeholder={field.placeholder}
                                        />
                                      );
                                  }
                                })()}

                                {errors[field.name] &&
                                  (() => {
                                    switch (field.name) {
                                      case "profession_name":
                                        return (
                                          values["occupation_name"] && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors["profession_name"]}
                                            </div>
                                          )
                                        );
                                      case "designation_name":
                                        return (
                                          values["profession_name"] && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors["designation_name"]}
                                            </div>
                                          )
                                        );
                                      case "specialization_name":
                                        return (
                                          values["profession_name"] &&
                                          values["designation_name"] && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors["designation_name"]}
                                            </div>
                                          )
                                        );
                                      case "specialization_id_other":
                                        return (
                                          values["specialization_id"] ===
                                            "-1" && (
                                            <div className="text-red-500 text-xs mt-1">
                                              {errors[field.name]}
                                            </div>
                                          )
                                        );
                                      case "to_date":
                                        return "";

                                      default:
                                        return (
                                          <div className="text-red-500 text-xs mt-1">
                                            {errors[field.name]}
                                          </div>
                                        );
                                    }
                                  })()}
                              </div>
                            )}
                          </div>
                        </div>
                        {editIndex === index
                          ? (() => {
                              switch (field.name) {
                                case "profession_name":
                                  return (
                                    values["occupation_name"] && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "designation_name":
                                  return (
                                    values["profession_name"] && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "specialization_name":
                                  return (
                                    values["profession_name"] &&
                                    values["designation_name"] && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "specialization_id_other":
                                  return (
                                    values["specialization_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "occupation_id_other":
                                  return (
                                    values["occupation_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "profession_id_other":
                                  return (
                                    values["profession_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "designation_id_other":
                                  return (
                                    values["designation_id"] === "-1" && (
                                      <hr className="border-gray-300 my-4" />
                                    )
                                  );
                                case "to_date":
                                  return "";

                                default:
                                  return (
                                    <hr className="border-gray-300 my-4" />
                                  );
                              }
                            })()
                          : (() => {
                              switch (field.name) {
                                case "specialization_id_other":
                                  return "";
                                case "occupation_id_other":
                                  return "";
                                case "profession_id_other":
                                  return "";
                                case "designation_id_other":
                                  return "";
                                case "to_date":
                                  return "";
                                default:
                                  return (
                                    <hr className="border-gray-300 my-4" />
                                  );
                              }
                            })()}
                      </React.Fragment>
                    ))}

                    {editIndex === index && (
                      <div className="text-end">
                        <>
                          <button
                            className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCancel}
                          >
                            CANCEL
                          </button>
                          <button
                            className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Saving..." : "SAVE"}
                          </button>
                        </>
                      </div>
                    )}
                  </form>
                </AccordionItem>
              </Accordion>
            </div>
          ))}

          {!addNew && professions.length > 0 && (
            <div className="w-full flex justify-center">
              <div className="bg-white shadow-lg text-center flex   w-max my-6 text-xs py-3   rounded-full    px-6 focus:outline-none focus:shadow-outline">
                Do you have more professional experience ?
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2 ml-4" />
                  Add new
                </button>
              </div>
            </div>
          )}
          {addNew && (
            <div className="bg-white shadow-lg mt-6 pt-10">
              <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="px-10 pb-10"
              >
                {professionalData.map((field, index) => (
                  <React.Fragment key={index}>
                    <div className="lg:flex items-center flex-1 mt-3 lg:mt-0">
                      {(() => {
                        switch (field.name) {
                          case "profession_name":
                            return (
                              values["occupation_name"] && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "designation_name":
                            return (
                              values["profession_name"] && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "specialization_name":
                            return (
                              values["profession_name"] &&
                              values["designation_name"] && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "specialization_id_other":
                            return (
                              values["specialization_id"] === "-1" && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );
                          case "to_date":
                            return "";
                          case "exp_in_year":
                            return (
                              ((values["from_date"] && values["to_date"]) ||
                                (values["from_date"] && isCurrentWork)) && (
                                <label className="text-gray-500 text-xs lg:w-[250px]">
                                  {field.label}
                                  {field.required && (
                                    <span className="text-red-500">*</span>
                                  )}
                                </label>
                              )
                            );

                          default:
                            return (
                              <label className="text-gray-500 text-xs lg:w-[250px]">
                                {field.label}
                                {field.required && (
                                  <span className="text-red-500">*</span>
                                )}
                              </label>
                            );
                        }
                      })()}

                      <div className="text-xs w-full mt-3 lg:mt-0">
                        {(() => {
                          switch (field.name) {
                            case "occupation_name":
                              return (
                                <OccupationSelect
                                  getOccupation={handleOccupationSelect}
                                  error={errors["occupation_name"]}
                                />
                              );

                            case "profession_name":
                              return (
                                values["occupation_name"] && (
                                  <JobCategorySelect
                                    getJobCategory={handleJobCategory}
                                    id={values["occupation_id"]}
                                    error={errors["profession_name"]}
                                    val={{
                                      value: values.profession_id,
                                      label: values.profession_name,
                                    }}
                                  />
                                )
                              );
                            case "designation_name":
                              return (
                                values["profession_name"] && (
                                  <DesignationSelect
                                    getDesignation={handleDesignation}
                                    id={values["profession_id"]}
                                    error={errors["designation_name"]}
                                    val={{
                                      value: values.designation_id,
                                      label: values.designation_name,
                                    }}
                                  />
                                )
                              );
                            case "specialization_name":
                              return (
                                values["profession_name"] &&
                                values["designation_name"] && (
                                  <JobSpecialisationSelect
                                    getSpecialisation={
                                      handleSpecialisationSelect
                                    }
                                    error={errors["specialization_name"]}
                                    id={values["profession_id"]}
                                    val={{
                                      value: values.specialization_id,
                                      label: values.specialization_name,
                                    }}
                                  />
                                )
                              );
                            case "specialization_id_other":
                              return (
                                values["specialization_id"] === "-1" && (
                                  <input
                                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                      errors[field.name] && touched[field.name]
                                        ? "border-red-500"
                                        : ""
                                    }`}
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    value={values[field.name]}
                                    onChange={handleFieldChange}
                                    placeholder={field.placeholder}
                                  />
                                )
                              );
                            case "occupation_id_other":
                              return (
                                values["occupation_id"] === "-1" && (
                                  <input
                                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                      errors[field.name] ? "border-red-500" : ""
                                    }`}
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    value={values[field.name]}
                                    onChange={handleFieldChange}
                                    placeholder={field.placeholder}
                                  />
                                )
                              );
                            case "profession_id_other":
                              return (
                                values["profession_id"] === "-1" && (
                                  <input
                                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                      errors[field.name] ? "border-red-500" : ""
                                    }`}
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    value={values[field.name]}
                                    onChange={handleFieldChange}
                                    placeholder={field.placeholder}
                                  />
                                )
                              );
                            case "designation_id_other":
                              return (
                                values["designation_id"] === "-1" && (
                                  <input
                                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                      errors[field.name] ? "border-red-500" : ""
                                    }`}
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    value={values[field.name]}
                                    onChange={handleFieldChange}
                                    placeholder={field.placeholder}
                                  />
                                )
                              );
                            case "employer_city":
                              return (
                                <LocationAutocomplete
                                  getLocation={handleCitySelected}
                                  locationError={handleCityError}
                                  Error={errors["employer_city"]}
                                />
                              );
                            case "from_date":
                              return (
                                <>
                                  <div className="lg:flex">
                                    <div className="mr-1 w-full">
                                      <FromToSelect
                                        getDuration={handleFromDate}
                                        error={errors["from_date"]}
                                        title={"From Date"}
                                      />
                                    </div>

                                    <div className="mr-1 w-full">
                                      <FromToSelect
                                        getDuration={handleToDate}
                                        error={errors["to_date"]}
                                        title={"To Date"}
                                        isCurrentWork={isCurrentWork}
                                      />
                                    </div>
                                  </div>
                                  {/* Checkbox for present address */}
                                  <div className="flex items-center mt-3">
                                    <input
                                      type="checkbox"
                                      id="currentWork"
                                      checked={isCurrentWork}
                                      onChange={handleCurrentWorkToggle}
                                      className="mr-2"
                                    />
                                    <label
                                      htmlFor="currentWork"
                                      className="text-xs text-gray-500"
                                    >
                                      Currently working
                                    </label>
                                  </div>
                                </>
                              );
                            case "to_date":
                              return "";
                            case "exp_in_year":
                              return (
                                ((values["from_date"] && values["to_date"]) ||
                                  (values["from_date"] && isCurrentWork)) && (
                                  <span className="text-xs">
                                    {values[field.name]}
                                  </span>
                                )
                              );

                            default:
                              return (
                                <input
                                  className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                                    errors[field.name] ? "border-red-500" : ""
                                  }`}
                                  type="text"
                                  id={field.name}
                                  name={field.name}
                                  value={values[field.name]}
                                  onChange={handleFieldChange}
                                  placeholder={field.placeholder}
                                />
                              );
                          }
                        })()}

                        {errors[field.name] &&
                          (() => {
                            switch (field.name) {
                              case "profession_name":
                                return (
                                  values["occupation_name"] && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors["profession_name"]}
                                    </div>
                                  )
                                );
                              case "designation_name":
                                return (
                                  values["profession_name"] && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors["designation_name"]}
                                    </div>
                                  )
                                );
                              case "specialization_name":
                                return (
                                  values["profession_name"] &&
                                  values["designation_name"] && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors["designation_name"]}
                                    </div>
                                  )
                                );
                              case "specialization_id_other":
                                return (
                                  <div className="text-red-500 text-xs mt-1">
                                    {errors["specialization_id_other"]}
                                  </div>
                                );
                              case "to_date":
                                return "";
                              case "exp_in_year":
                                return (
                                  ((values["from_date"] && values["to_date"]) ||
                                    (values["from_date"] && isCurrentWork)) && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {errors["exp_in_year"]}
                                    </div>
                                  )
                                );

                              default:
                                return (
                                  <div className="text-red-500 text-xs mt-1">
                                    {errors[field.name]}
                                  </div>
                                );
                            }
                          })()}
                      </div>
                    </div>

                    {(() => {
                      switch (field.name) {
                        case "profession_name":
                          return (
                            values["occupation_name"] && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "designation_name":
                          return (
                            values["profession_name"] && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "specialization_name":
                          return (
                            values["profession_name"] &&
                            values["designation_name"] && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "specialization_id_other":
                          return (
                            values["specialization_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "occupation_id_other":
                          return (
                            values["occupation_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "profession_id_other":
                          return (
                            values["profession_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "designation_id_other":
                          return (
                            values["designation_id"] === "-1" && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );
                        case "to_date":
                          return "";
                        case "exp_in_year":
                          return (
                            ((values["from_date"] && values["to_date"]) ||
                              (values["from_date"] && isCurrentWork)) && (
                              <hr className="border-gray-300 my-4" />
                            )
                          );

                        default:
                          return <hr className="border-gray-300 my-4" />;
                      }
                    })()}
                  </React.Fragment>
                ))}

                <div className="text-end">
                  <>
                    <button
                      className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleAddNewCancel}
                    >
                      CANCEL
                    </button>
                    <button
                      className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                  </>
                </div>
              </form>
            </div>
          )}
        </div>

        {deleteModal && (
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to delete this profession ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleProfessionDelete}
                >
                  {loading ? "DELETING..." : "DELETE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"PROFESSIONAL INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ProfessionalDetails;
