import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";
import Content from "../../components/Readmore/ReadMore";
import {
  IoMdCheckboxOutline,
  IoMdCheckmarkCircleOutline,
} from "react-icons/io";
import { Menu } from "@headlessui/react";
import "./address.css";
const AddressItem = ({
  type,
  title,
  content,
  addressType,
  edit,
  setDefault,
  data,
  deleteItem,
  isPermanent,
  defaultAddress,
  setAsPreset,
  setSameAddress,
}) => {
  const handleEdit = () => {
    edit(data);
  };

  const handleDefault = () => {
    setDefault(data);
  };

  const handleDelete = () => {
    deleteItem(data);
  };

  const handleSetAsPresent = () => {
    setAsPreset(data);
  };

  const handleSetSameAddress = () => {
    setSameAddress(data);
  };

  return (
    <div className="bg-white relative shadow-md rounded-lg border-[1px] border-gray-300   pt-6 pb-6 px-8 mb-4 xl:mb-0">
      {defaultAddress === "Yes" && <div className="ribbon">Default</div>}
      <div className="xl:min-h-[170px] relative">
        <div className="absolute -top-[20px] left-[99%]">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium  "
                title="Actions"
              >
                <HiDotsVertical className="text-lg font-bold" />
              </Menu.Button>
            </div>
            <Menu.Items className="absolute right-[35px] top-0 w-max mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      } group flex rounded-md items-center w-full px-3 py-2 text-xs`}
                      onClick={handleEdit}
                    >
                      Edit Address
                    </button>
                  )}
                </Menu.Item>
                {type === 0 && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } group flex rounded-md items-center w-full px-3 py-2 text-xs`}
                        onClick={handleDelete}
                      >
                        Delete Address
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Menu>
        </div>

        <div
          className={`${type === 1 && "bg-[#B7D7EC]"}  ${
            type === 0 && "bg-[#FFEAA5]"
          }   ${
            type === 2 && "bg-[#8AEBFF]"
          }   rounded-full text-[13px] w-max px-3 py-2  mb-4`}
        >
          {addressType}
        </div>

        <div className="text-xs flex text-custom pb-4 xl:pb-2 font-medium">
          <IoLocationOutline className="mr-2 text-[15px]" />
          {title}
        </div>
        <div className="text-xs w-[90%] overflow-auto ml-6 ">
          <Content content={content} />
        </div>
      </div>
      <hr className="border-gray-300 xl:mt-0 my-4 " />
      <div className="md:flex justify-between   items-center">
        {defaultAddress !== "Yes" && (
          <button
            className="md:flex items-center mb-3 md:mb-0  md:w-fit md:text-custom text-xs  bg-gray-300 md:bg-transparent md:hover:bg-transparent     w-full  text-center  text-black rounded-full  md:py-0 md:px-0 py-2 px-6 focus:outline-none focus:shadow-outline"
            onClick={handleDefault}
          >
            <IoMdCheckboxOutline className="hidden md:block mr-2 mb-0.5" />
            Set default
          </button>
        )}

        {type === 0 && (
          <button
            className="md:flex items-center    md:w-fit md:text-blue-500 md:ml-auto text-xs  bg-gray-300 md:bg-transparent  md:hover:bg-transparent     w-full  text-center  text-black rounded-full md:py-0 md:px-0  py-2 px-6 focus:outline-none focus:shadow-outline"
            onClick={handleSetAsPresent}
          >
            <IoMdCheckmarkCircleOutline className=" hidden md:block mr-2 ml-2 mb-0.5" />
            Set as present address
          </button>
        )}

        {type === 1 && isPermanent && (
          <button
            className="md:flex items-center   md:w-fit md:text-[#035184] md:ml-auto text-xs  bg-gray-300 md:bg-transparent md:hover:bg-transparent     w-full  text-center  text-black rounded-full md:py-0 md:px-0  py-2 px-6 focus:outline-none focus:shadow-outline"
            onClick={handleSetSameAddress}
          >
            <IoMdCheckmarkCircleOutline className="hidden md:block mr-2 ml-2 mb-0.5" />
            Set same as permanent
          </button>
        )}
        {type === 2 && (
          <button
            className="md:flex items-center   md:w-fit md:text-[#00ACCE] md:ml-auto text-xs  bg-gray-300 md:bg-transparent md:hover:bg-transparent     w-full  text-center  text-black rounded-full md:py-0 md:px-0  py-2 px-6 focus:outline-none focus:shadow-outline"
            onClick={handleSetSameAddress}
          >
            <IoMdCheckmarkCircleOutline className="hidden md:block mr-2 ml-2 mb-0.5" />
            Set same as present
          </button>
        )}
      </div>
    </div>
  );
};

export default AddressItem;
