import axios from "axios";
import {
  COURSE,
  DESIGNATION,
  LANGUAGE,
  OCCUPATION,
  QUALIFICATION,
  REFRESH_TOKEN,
  REGISTER_LEVEL_ONE,
  REGISTER_LEVEL_TWO,
  UPDATE_REGISTER_LEVEL_ONE,
  USER_CHECK,
} from "../config/config";

// register level one post api call
export const registerLevelOne = async (userData) => {
  try {
    const response = await axios.post(REGISTER_LEVEL_ONE, userData);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error registering user:", error);
    throw error;
  }
};

// user check post api call
export const userCheck = async (userData, token) => {
  try {
    const response = await axios.post(USER_CHECK, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error checking user", error);
    throw error;
  }
};

// register level two post api call
export const registerLevelTwo = async (userData, token) => {
  try {
    const response = await axios.post(REGISTER_LEVEL_TWO, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error registering user:", error);
    throw error;
  }
};

//update register level one post api call
export const updateRegisterLevelOne = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_REGISTER_LEVEL_ONE, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating or updating register one data:", error);
    throw error;
  }
};

// Function to refresh token
export const refreshToken = async (token) => {
  try {
    const response = await axios.post(REFRESH_TOKEN, "hi", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.authorisation.token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// get qualification list api call
export const getQualificationList = async (token) => {
  try {
    const response = await axios.post(
      QUALIFICATION,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching qualification list:", error);
    throw error;
  }
};

// get course list api call
export const getCourseList = async (token, id) => {
  try {
    const response = await axios.post(
      COURSE,
      {
        limit: "0",
        status: "1",
        qualification_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching course list:", error);
    throw error;
  }
};

// get language list api call
export const getLanguageList = async (token, is_default) => {
  try {
    const response = await axios.post(
      LANGUAGE,
      {
        limit: "0",
        status: "1",
        is_default: is_default,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching language list:", error);
    throw error;
  }
};

// get occupation list api call
export const getOccupationList = async (token) => {
  try {
    const response = await axios.post(
      OCCUPATION,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching occupation list:", error);
    throw error;
  }
};

// get language list api call
export const getDesignationList = async (token, id) => {
  console.log("des id", id);
  try {
    const response = await axios.post(
      DESIGNATION,
      {
        limit: "0",
        status: "1",
        profession_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching designation list:", error);
    throw error;
  }
};
