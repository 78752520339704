export const LOGIN_STEP_ONE_DATA = "LOGIN_STEP_ONE_DATA";

export const REGISTER_TWO_FLAG = "REGISTER_TWO_FLAG";

export const FORGOT_DATA = "FORGOT_DATA";

export const saveStepOneData = (data) => {
  return {
    type: LOGIN_STEP_ONE_DATA,
    payload: data,
  };
};

export const saveRegisterTwoFlag = (data) => {
  return {
    type: REGISTER_TWO_FLAG,
    payload: data,
  };
};

export const saveForgotData = (data) => {
  return {
    type: FORGOT_DATA,
    payload: data,
  };
};
