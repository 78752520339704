import axios from "axios";
import {
  FETCH_PERMANENT_ADDRESS,
  UPDATE_PERMANENT_ADDRESS,
} from "../config/config";


// get present address details get api call
export const getPermanentAddressDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_PERMANENT_ADDRESS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get permanent adddress", error);
    throw error;
  }
};

//update prsent address  data post api call
export const updatePermanentAddressData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_PERMANENT_ADDRESS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update permanent address data:", error);
    throw error;
  }
};
