import React, { useEffect, useState } from "react";
import Select from "react-select";

const GenericSelect = ({ onChange, option, val }) => {
  const [options, setOptions] = useState(option ? option : "");
  const [selectedOption, setSelectedOption] = useState(val ? val : "");

  function findObjectByValue(array, valueToFind) {
    return array.find((obj) => obj.label === valueToFind);
  }

  useEffect(() => {
    const foundObject = findObjectByValue(options, val && val);
    if (foundObject) {
      setSelectedOption(foundObject);
    }
  }, [val]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",
      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <Select
      className="text-xs"
      isClearable={true}
      options={options}
     
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: "7px",
        boxShadow: null,
        colors: {
          ...theme.colors,
          primary25: "#526754",
          primary: "#526754",
        },
      })}
      value={selectedOption}
      onChange={handleChange}
    />
  );
};

export default GenericSelect;
