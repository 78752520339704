import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";

const LocationAutocomplete = ({ getLocation, locationError, Error, val }) => {
  
  const [location, setLocation] = useState({});
  const handlePlaceSelected = (place) => {
     
    if (place.geometry) {
      const {
        address_components,
        geometry: { location },
      } = place;

      let newLocationState = {
        city: "",
        country: "",
        country_code: "",
        state: "",
        district: "",
        latitude: location.lat(),
        longitude: location.lng(),
      };

      address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          newLocationState.city = component.long_name;
        } else if (component.types.includes("country")) {
          newLocationState.country = component.long_name;
          newLocationState.country_code = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          newLocationState.state = component.long_name;
        } else if (component.types.includes("administrative_area_level_3")) {
          newLocationState.district = component.long_name;
        }
      });

      setLocation(newLocationState);
    } else {
      setLocation(null);
    }
  };

  useEffect(() => {
    if (location === null || location?.city) {
      getLocation(location);
    }
  }, [location]);

  const handlePlace = () => {
    locationError(false);
    setLocation(null);
  };
 
  return (
    <Autocomplete
      className={`border bg-white text-xs border-gray-400 rounded-lg px-4 py-2 w-full focus:outline-none  
    ${Error && "border-red-500"}  `}
      apiKey={"AIzaSyAtPaw6b77SoM_w2PB6L-ypRr2ec2yEmWs"}
      onPlaceSelected={handlePlaceSelected}
      onChange={handlePlace}
      types={["(regions)"]}
      placeholder="Enter city and country"
      defaultValue={val && val}
    />
  );
};

export default LocationAutocomplete;
