import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { Menu, Transition } from "@headlessui/react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../Redux/Actions/registerActions";

const Navbar = () => {
  const dispatch = useDispatch();
  const [nav, setNav] = useState(false);
  const token = useSelector((state) => state.register.token);
  const [navItems, setNavItems] = useState([]);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = () => {
    dispatch(removeToken());
  };

  useEffect(() => {
    const items = [
      { id: 1, text: "STUDY & WIN", link: "/study-win" },
      { id: 2, text: "WIN MY GIFTS", link: "/win-my-gifts" },
      { id: 3, text: "GIFTING SHOPS", link: "/gifting-shops" },
      { id: 4, text: "BUY & SELL", link: "/buy-sell" },
      { id: 5, text: "WINNERS", link: "/winners" },
    ];

    if (token) {
      items.push({
        id: 6,
        text: "MY ACCOUNT",
        dropdown: [
          { dropdown: "My Profile", link: "/profile/basic-details" },
          { dropdown: "Logout", link: "/", action: handleLogout },
        ],
      });
    } else {
      items.push(
        {
          id: 6,
          text: "LOGIN",
          dropdown: [
            { dropdown: "Student Login", link: "/" },
            { dropdown: "Business Login", link: "business" },
          ],
        },
        {
          id: 7,
          text: "REGISTER",
          dropdown: [
            { dropdown: "Student Register", link: "register-step-1" },
            { dropdown: "Business Register", link: "business" },
          ],
        }
      );
    }

    setNavItems(items);
  }, [token]);

  return (
    <div className="bg-custom flex justify-between items-center w-full h-16 border-b border-gray-400 mx-auto px-4 fixed z-50 text-white">
      <h1 className="lg:w-[25%] w-max text-2xl text-white">
        <NavLink to={"/"}>STUDYWIN</NavLink>
      </h1>
      <ul className="lg:flex lg:flex-auto justify-end hidden items-center">
        {navItems.map((item) => (
          <li
            key={item.id}
            className="relative ml-[35px] cursor-pointer duration-300"
          >
            {item.dropdown ? (
              <Menu
                as="div"
                className="relative inline-block mb-[4px] text-left"
              >
                <div>
                  <Menu.Button className="inline-flex text-sm justify-center">
                    {item.text}
                    <FiChevronDown
                      className="-mr-1 ms-2   h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 min-w-[150px]">
                      {item.dropdown.map((option, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <Link
                              to={option.link}
                              onClick={option.action ? option.action : null}
                              className={`block px-4 py-2 w-full text-sm ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              }`}
                            >
                              {option.dropdown}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : (
              <NavLink to={item.link} className="w-full text-sm block">
                {item.text}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center h-16   lg:hidden   ml-auto px-4 text-white">
        <div onClick={handleNav} className="block lg:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul
          className={
            nav
              ? "fixed lg:hidden left-0 top-16 w-[55%] md:w-[350px] h-full   bg-custom ease-in-out duration-500"
              : "ease-in-out w-[55%] md:w-[350px] duration-500 fixed top-16 bottom-0 left-[-100%]"
          }
        >
          {navItems.map((item) => (
            <li
              key={item.id}
              className="relative  border-b border-gray-400 text-sm duration-300 cursor-pointer  "
            >
              {item.dropdown ? (
                <Menu
                  as="div"
                  className="relative inline-block w-full text-left"
                >
                  <div>
                    <Menu.Button className="inline-flex w-full p-4 justify-start">
                      {item.text}
                      <FiChevronDown
                        className="-mr-1 ms-5 h-5   w-5 text-white"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute  mx-4 z-10 mt-2 w-44 md:w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {item.dropdown.map((option, index) => (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <Link
                                to={option.link}
                                onClick={option.action ? option.action : null}
                                className={`block px-4 py-2 text-sm ${
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700"
                                }`}
                              >
                                {option.dropdown}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <Link to={item.link} className="block w-auto p-4">
                  {item.text}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
