import React, { useEffect, useState, useCallback, useRef } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FaPlus, FaRegEdit, FaSearch } from "react-icons/fa";
import YesOrNo from "../../components/Select/Generic";
import { useDispatch, useSelector } from "react-redux";
import {
  getGenericDetails,
  getSurveyQuestions,
  updateGenericData,
  updateSurveyQuestions,
} from "../../api/genericApi";
import { removeAlert, setAlert } from "../../Redux/Actions/alertActions";
import { getMenuState } from "../../api/sideBarApi";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import Modal from "../../components/Modal/Modal";
import Skeleton from "../../components/Skeleton/Skeleton";
import { BiSolidHelpCircle } from "react-icons/bi";
import { MdOutlineCancel, MdOutlineEdit } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import GenericSelect from "../../components/Select/Generic";

const GenericInfo = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState([]);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [surveyModal, setSurveyModal] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setAnimate(true);

    const timer = setTimeout(() => {
      setAnimate(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [surveyData]);

  // Function to escape special characters in the search term
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  // Highlight function to wrap search term in a span with blue color
  const highlightSearchTerm = (text) => {
    if (!searchTerm) return text;
    const escapedSearchTerm = escapeRegExp(searchTerm);
    const parts = text.split(new RegExp(`(${escapedSearchTerm})`, "gi"));
    
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span
          key={index}
          style={{ color: "rgb(59 130 246)", fontWeight: "500" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const fetchGenericDetails = useCallback(async () => {
    // Set the page based on the formData array
    const skipNo = page === 1 ? 0 : formData.length;
    // Input data object
    const inputData = {
      limit: "14",
      search: searchTerm,
      page: page,
      type: "0",
      skip: skipNo,
    };

    try {
      const details = await getGenericDetails(inputData, token);

      if (details.status === 1) {
        // // Simulate a 1-second delay
        // await new Promise((resolve) => setTimeout(resolve, 2000));

        setFormData((prevData) => [...prevData, ...details.data]);

        if (details.data.length < 7) {
          setHasMore(false);
        }

        setPage((prev) => prev + 1);

        setSkeletonLoad(false);
        setInfo({
          info_title: details.info_title,
          info_details: details.info_details,
        });
      } else {
        setHasMore(false);
        setInfo({
          info_title: details.info_title,
          info_details: details.info_details,
        });
        console.error("Failed to fetch generic details ");
        dispatch(setAlert(details.message, "Note"));
        setSkeletonLoad(false);
      }
    } catch (error) {
      console.error("Error fetching generic details", error);
      setSkeletonLoad(false);
    }
  }, [searchTerm, page, token, dispatch]);

  const debouncedFetch = useCallback(_.debounce(fetchGenericDetails, 500), [
    fetchGenericDetails,
  ]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
    setFormData([]);
    setHasMore(true);
  };

  const handleSearchCancel = () => {
    setPage(1);
    setFormData([]);
    setHasMore(true);
    setSearchTerm("");
  };

  useEffect(() => {
    debouncedFetch();
    return debouncedFetch.cancel;
  }, [searchTerm]);

  const handleAnswerChange = (e) => {
    setValue(e.target.value);
  };
  const handleChange = async (question_id, option) => {
    // handleScroll();
    // setPage(1);
    dispatch(removeAlert());
    if (surveyModal) {
      setSelectedOption(option.value);
    }
    setLoading(true);
    try {
      const response = await updateGenericData(
        question_id,
        option.value,
        value,
        token
      );

      if (response.status === 1) {
        setEditData(null);
        if (editData?.option_type === 3 || surveyData?.option_type === 3) {
          setFormData((prevData) =>
            prevData.map((obj) =>
              obj.question_id === parseInt(question_id)
                ? { ...obj, answer: value }
                : obj
            )
          );
        } else {
          setFormData((prevData) =>
            prevData.map((obj) =>
              obj.question_id === parseInt(question_id)
                ? { ...obj, answer: option.label, option_no: option.value }
                : obj
            )
          );
        }

        setValue("");
        dispatch(setAlert(response.message, "success"));
    
        if (response.data.length > 0) {
          setSelectedOption(null);
          setSurveyModal(true);
          setFormData((prevData) => {
            // Create a set of existing IDs
            const existingIds = new Set(
              prevData.map((item) => item.question_id)
            );

            // Append only the new items that do not have an existing ID in prevData
            const newData = response.data.filter(
              (item) => !existingIds.has(item.question_id)
            );
            
            // Return the updated array
            return [...newData, ...prevData];
          });

          setSurveyData(response.data[0]);
        } else {
          if (surveyModal) {
            // setFormData(response.data);
            // setPage(2);
            setSurveyModal(false);
            // setHasMore(true);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(setAlert(response.message, "error"));
      }
    } catch (error) {
      setLoading(false);
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
    }
  };

  const handleSurveyAdd = async () => {
    setSelectedOption(null);
    setValue("");
    setPage(1);
    try {
      const details = await getSurveyQuestions(token);

      if (details.status === 1) {
        setSurveyData(details.data);

        setAddNew(true);
      } else {
        console.error("Failed to fetch survey details ");
        dispatch(
          setAlert(
            "No more questions available at the moment, Try again later.",
            "Note"
          )
        );
        setAddNew(false);
      }
    } catch (error) {
      console.error("Error survey details", error);
    }
  };

  const handleSurveyOptionChange = async (q_id, optionValue) => {
    dispatch(removeAlert());
    setSelectedOption(optionValue);
    setLoading(true);
    try {
      const response = await updateSurveyQuestions(
        q_id,
        optionValue,
        value,
        token
      );

      if (response.status === 1) {
        setLoading(false);
        setValue("");
        if (response.data.length >= 1) {
          setSelectedOption(null);
          setSurveyData(response.data);
        } else {
          setFormData([]);
          fetchGenericDetails();
          setAddNew(false);
          setHasMore(true);
        }
        if (optionValue !== 0) {
          // Handle the error if the response is not okay
          dispatch(setAlert(response.message, "success"));
        }
      } else {
        setLoading(false);
        dispatch(setAlert(response.message, "error"));
      }
    } catch (error) {
      setLoading(false);
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);
      // Optionally, you can display an error message to the user
    }
  };

  // const toggleEditMode = () => {
  //   setIsEditMode(true);
  // };

  // const handleSave = async () => {
  //   fetchGenericDetails();
  //   setIsEditMode(false);
  //   //fetch the menu state
  //   const details = await getMenuState(token);

  //   if (details.status === 1) {
  //     dispatch(saveMenuData(details.data));
  //   } else {
  //     console.error("Failed to get menu state");
  //     dispatch(setAlert(details.message, "note"));
  //   }
  // };

  const handleEditClick = (data) => {
    // setSurveyModal(true);
    // setSurveyData(data);
    // setSelectedOption(data.option_no);
    setEditData(data);
    if (data.answer !== "-") {
      setValue(data.answer);
    } else {
      setValue("");
    }
  };

  const handleEditClose = () => {
    setEditData(null);
    setValue("");
  };

  const handleAddSurveyClose = () => {
    setFormData([]);
    setHasMore(true);
    setAddNew(false);
    fetchGenericDetails();
  };

  const handleSurveyClose = () => {
    setSurveyModal(false);
    // fetchGenericDetails();
  };

 
  if (skeletonLoad) {
    return <Skeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2"
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center md:justify-normal relative">
        <div>
          <Sidebar />
        </div>
        <div className="bg-white shadow-md md:mb-[40px] mb-4 lg:w-[65%] w-[97%] p-6 md:p-10   h-fit  lg:ml-6 md:ml-2 ml-0">
          <div className="mb-4 md:mb-6 md:flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <h2 className="text-lg text-custom">GENERAL INFORMATION</h2>
                <button
                  className="ml-2 flex items-center text-blue-500 text-xs"
                  onClick={() => setHelp(true)}
                >
                  <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                </button>
              </div>

              <div className="bg-custom w-12 mt-2 h-[2px] mb-2 "></div>
            </div>
            <div className="flex items-center justify-end">
              <button
                className="ml-2 flex items-center text-blue-500 text-xs"
                onClick={handleSurveyAdd}
              >
                <FaPlus className="mr-2" />
                Add
              </button>
            </div>
          </div>

          <div className="w-[60%] mb-4 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 mb-3 placeholder-size border rounded-lg border-gray-400 px-3 py-2 text-xs w-full focus:outline-none"
              placeholder="Search Questions..."
            />
            {!searchTerm ? (
              <FaSearch className="absolute top-[10px] right-[10px] text-gray-500" />
            ) : (
              <button onClick={handleSearchCancel}>
                <IoClose className="absolute top-[8px] text-xl right-[10px] text-red-600" />
              </button>
            )}
          </div>
          <div>
            <InfiniteScroll
              dataLength={formData.length}
              next={fetchGenericDetails}
              hasMore={hasMore}
              loader={
                <div className="flex justify-center items-center">
                  {" "}
                  <img
                    className="h-[30px] w-[30px]"
                    src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
                    alt=""
                  />
                </div>
              }
              endMessage={
                <p className="text-center text-xs pt-4 text-gray-500">
                  {formData.length === 0
                    ? "Nothing to show"
                    : " Yay! You have seen it all"}
                </p>
              }
            >
              {formData.map((data, index) => (
                <div key={index}>
                  <div className="md:flex min-h-[35px] flex-row items-center">
                    <p className="text-gray-700 text-[13px] md:w-[60%]  ">
                      {index + 1}. {highlightSearchTerm(data.question)}?
                    </p>
                    <div className="text-custom hidden md:block   md:mr-6 mr-3 ml-0 font-extrabold text-[20px]">
                      :
                    </div>

                    {editData?.question_id === data.question_id ? (
                      <div className=" relative flex-1 md:mt-0 mt-3">
                        <div className="w-[90%]">
                          {(() => {
                            switch (editData.option_type) {
                              case 3:
                                return (
                                  <div className="relative">
                                    <input
                                      type="text"
                                      value={value}
                                      onChange={handleAnswerChange}
                                      className="border border-gray-300 rounded-lg ps-3 pe-[35px] py-2 text-xs w-full focus:outline-none"
                                    />
                                    {value && (
                                      <button
                                        className="absolute top-0 h-[34px] w-[34px] right-0     rounded-e-lg"
                                        title="submit"
                                        onClick={() =>
                                          handleChange(editData.question_id, 0)
                                        }
                                        disabled={value.length === 0}
                                      >
                                        <div className="h-[34px] flex justify-center items-center">
                                          <IoMdSend className=" text-lg text-blue-500" />
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                );

                              default:
                                return (
                                  <GenericSelect
                                    option={editData.options}
                                    val={editData.answer}
                                    onChange={(option) =>
                                      handleChange(editData.question_id, option)
                                    }
                                  />
                                );
                            }
                          })()}
                        </div>

                        <div>
                          <button
                            className="absolute top-[7px]  right-0"
                            onClick={handleEditClose}
                          >
                            <IoClose className="text-blue-500 ml-2 text-xl font-extrabold" title="Close" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between flex-1 md:mt-0 mt-3">
                        <span className="text-xs font-medium">
                          {data.answer}
                        </span>
                        <button
                          className="  flex items-center  text-blue-500 "
                          onClick={() => handleEditClick(data)}
                          title="Edit"
                        >
                          <MdOutlineEdit className="text-blue-500 ml-auto" />
                        </button>
                      </div>
                    )}
                  </div>
                  <hr className="border-gray-300 my-4" />
                </div>
              ))}
            </InfiniteScroll>
          </div>
          {/* 
          <div className="text-end">
            {isEditMode && (
              <>
                <button
                  className="border-custom border mr-3 mt-3 text-xs hover:bg-custom w-[100px] text-custom hover:text-white rounded-full py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleSave}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862] hover:bg-[#4F7552] w-[100px] mt-3 text-xs text-white rounded-full py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleSave}
                >
                  SAVE
                </button>
              </>
            )}
          </div>

          {!isEditMode && formData[0] && (
            <div className="text-end">
              <button
                className="bg-[#5E8862] hover:bg-[#4F7552] w-[100px] mt-3 text-xs text-white rounded-full py-2 px-6 focus:outline-none focus:shadow-outline"
                onClick={toggleEditMode}
              >
                EDIT
              </button>
            </div>
          )} */}
        </div>

        {surveyModal && (
          <Modal isOpen={surveyModal} onClose={handleSurveyClose}>
            <div className="p-4 md:p-0">
              {loading ? (
                <div className="flex justify-center items-center min-h-[120px]">
                  {" "}
                  <img
                    className="h-[30px] w-[30px]"
                    src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
                    alt=""
                  />
                </div>
              ) : (
                <div>
                  <h3
                    className={`pb-4 text-xs  ${
                      animate ? "animate-bounce" : ""
                    }`}
                  >
                    {surveyData.question}?
                  </h3>
                  {(() => {
                    switch (surveyData.option_type) {
                      case 3:
                        return (
                          <div className="relative mb-4">
                            <input
                              type="text"
                              value={value}
                              onChange={handleAnswerChange}
                              className="border border-gray-400 rounded-lg ps-3 pe-[35px] py-2 text-xs w-full focus:outline-none"
                            />
                            {value && (
                              <button
                                className="absolute top-0 h-[34px] w-[34px] right-0     rounded-e-lg"
                                title="submit"
                                onClick={() =>
                                  handleChange(surveyData.question_id, 0)
                                }
                                disabled={value.length === 0}
                              >
                                <div className="h-[34px] flex justify-center items-center">
                                  <IoMdSend className=" text-lg text-blue-500" />
                                </div>
                              </button>
                            )}
                          </div>
                        );
                      case 2:
                        return (
                          <div className="mb-4">
                            <GenericSelect
                              option={surveyData.options}
                              val={surveyData.answer}
                              onChange={(option) =>
                                handleChange(surveyData.question_id, option)
                              }
                            />
                          </div>
                        );
                      default:
                        return surveyData.options.map((item) => (
                          <label key={item.value} className="block mb-2">
                            <input
                              type="radio"
                              name="option"
                              value={item.value}
                              checked={selectedOption === item.value}
                              onChange={() =>
                                handleChange(surveyData.question_id, item)
                              }
                              className="mr-2"
                            />
                            {item.label}
                          </label>
                        ));
                    }
                  })()}

                  <div className="  flex justify-end">
                    <button
                      className="border-custom border   text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleSurveyClose}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}

        {addNew && (
          <Modal isOpen={addNew} onClose={handleAddSurveyClose}>
            <div className="p-4 md:p-0">
              {loading ? (
                <div className="flex justify-center items-center min-h-[120px]">
                  {" "}
                  <img
                    className="h-[30px] w-[30px]"
                    src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
                    alt=""
                  />
                </div>
              ) : (
                <div>
                  {surveyData.map((option, index) => (
                    <div key={index}>
                      <h3
                        className={`pb-4 text-xs   ${
                          animate ? "animate-bounce" : ""
                        }`}
                      >
                        {option.question}?
                      </h3>
                      {(() => {
                        switch (option.option_type) {
                          case 3:
                            return (
                              <div className="relative mb-4">
                                <input
                                  type="text"
                                  value={value}
                                  onChange={handleAnswerChange}
                                  className="border border-gray-400 rounded-lg ps-3 pe-[35px] py-2 text-xs w-full focus:outline-none"
                                />
                                {value && (
                                  <button
                                    className="absolute top-0 h-[34px] w-[34px] right-0     rounded-e-lg"
                                    title="submit"
                                    onClick={() =>
                                      handleSurveyOptionChange(
                                        option.question_id,
                                        0
                                      )
                                    }
                                    disabled={value.length === 0}
                                  >
                                    <div className="h-[34px] flex justify-center items-center">
                                      <IoMdSend className=" text-lg text-blue-500" />
                                    </div>
                                  </button>
                                )}
                              </div>
                            );
                          case 2:
                            return (
                              <div className="mb-4">
                                <GenericSelect
                                  option={option.options}
                                  val={option.answer}
                                  onChange={(opt) =>
                                    handleSurveyOptionChange(
                                      option.question_id,
                                      opt.value
                                    )
                                  }
                                />
                              </div>
                            );
                          default:
                            return option.options.map((item) => (
                              <label key={item.value} className="block mb-2">
                                <input
                                  type="radio"
                                  name="option"
                                  value={item.value}
                                  checked={selectedOption === item.value}
                                  onChange={() =>
                                    handleSurveyOptionChange(
                                      option.question_id,
                                      item.value
                                    )
                                  }
                                  className="mr-2"
                                />
                                {item.label}
                              </label>
                            ));
                        }
                      })()}
                      <div className="  flex justify-end">
                        <button
                          className="border-custom border text-xs hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
                          type="button"
                          onClick={() =>
                            handleSurveyOptionChange(option.question_id, 0)
                          }
                        >
                          SKIP
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Modal>
        )}

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"GENERAL INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default GenericInfo;
