import axios from "axios";
import {
  DELETE_OTHER_COUNTRY_PROFILE,
  FETCH_ADDRESS_DETAILS,
  FETCH_IDPROOF_LIST,
  SET_AS_DEFAULT_ADDRESS,
  SET_AS_PRESENT_ADDRESS,
  SET_SAME_ADDRESS,
  UPDATE_ADDRESS_DETAILS,
} from "../config/config";

// get other country details get api call
export const getAddressDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_ADDRESS_DETAILS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get all address details", error);
    throw error;
  }
};

//update other country data post api call
export const updateAddressData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_ADDRESS_DETAILS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update address data:", error);
    throw error;
  }
};

// get id list api call
export const getIdList = async (token, country_code) => {
  try {
    const response = await axios.post(
      FETCH_IDPROOF_LIST,
      {
        limit: "0",
        status: "1",
        country_code: country_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching id list", error);
    throw error;
  }
};

//Delete other country data post api call
export const deleteOtherCountryData = async (userData, token) => {
  try {
    const response = await axios.post(DELETE_OTHER_COUNTRY_PROFILE, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete other country data", error);
    throw error;
  }
};

//make as same address  post api call
export const makeSameAsPresentAddress = async (userData, token) => {
  try {
    const response = await axios.post(SET_AS_PRESENT_ADDRESS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error seting same as present address  data", error);
    throw error;
  }
};


//make as default address  post api call
export const makeAsDefaultAddress = async (userData, token) => {
  try {
    const response = await axios.post(SET_AS_DEFAULT_ADDRESS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error seting default address  data", error);
    throw error;
  }
};


//make as present as permanent address  post api call
export const makeSameAddress = async (userData, token) => {
  try {
    const response = await axios.post(SET_SAME_ADDRESS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error making same address  data", error);
    throw error;
  }
};