import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RegisterProtect = ({ element: Component }) => {
  const isAuthenticated = useSelector((state) => !!state.register.token);
  const registerTwoFlag = useSelector((state) => state.login.registerTwoFlag);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (registerTwoFlag === 1) {
    return <Navigate to="/profile/basic-details" />;
  }

  return <Component />;
};

export default RegisterProtect;
