import React, { useEffect, useState } from "react";
import AddressItem from "./AddressItem";
import SidebarNav from "../../components/Sidebar/Sidebar";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import Modal from "../../components/Modal/Modal";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import AddressForm from "./AddressForm";
import {
  getAddressDetails,
  makeAsDefaultAddress,
  makeSameAddress,
  makeSameAsPresentAddress,
} from "../../api/addressApi";
import PublicitySkeleton from "../../components/Skeleton/PublicitySkeleton";
import { setAlert } from "../../Redux/Actions/alertActions";
import { deleteOtherCountryData } from "../../api/otherCountryApi";
import {
  saveAddressData,
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import { getMenuState } from "../../api/sideBarApi";
import { FiMenu } from "react-icons/fi";

const AddressComponent = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const addresData = useSelector((state) => state.profile.addressData);
  const [addNew, setAddNew] = useState(false);
  const [isPresentAddress, setIsPresentAddress] = useState(false);
  const [isPermanent, setAsPermanent] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [editAddress, setEditAddress] = useState({});
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    const fetchAddressDetails = async (token) => {
      try {
        const details = await getAddressDetails(token);

        if (details.status === 1) {
          dispatch(saveAddressData(details.data));
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
            item_order: details.item_order,
          });
          setSkeletonLoad(false);
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to to get address details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error to get address details :", error);
        setSkeletonLoad(false);
      }
    };
    fetchAddressDetails(token);
  }, [token]);

  const handleAddNew = () => {
    setAddNew(true);
  };

  const handleEdit = (data) => {
    setEditAddress(data);
    setEdit(true);
  };

  const handleDefault = (data) => {
    setEditAddress(data);
    setDefaultModal(true);
  };
  const handleDelete = (data) => {
    setEditAddress(data);
    setDeleteModal(true);
  };

  const handleSetAsPresent = (data) => {
    setEditAddress(data);
    setIsPresentAddress(true);
  };

  const handleSameAddress = (data) => {
    setEditAddress(data);
    setAsPermanent(true);
  };

  const handleAddNewCancel = () => {
    setAddNew(false);
  };

  const handleEditCancel = () => {
    setEdit(false);
  };

  const handleAddressDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteOtherCountryData(
        {
          id: editAddress.id,
        },
        token
      );
      if (response.status === 1) {
        setLoading(false);
        dispatch(saveAddressData(response.data));
        // Handle the error if the response is not okay
        dispatch(setAlert(response.message, "success"));
        setDeleteModal(false);
        setInfo({
          info_title: response.info_title,
          info_details: response.info_details,
          item_order: response.item_order,
        });
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error dleteing language ", error.message);
      // Optionally, you can display an error message to the user
      setLoading(false);
    }
  };

  const handleSetPresentAddress = async () => {
    try {
      setLoading(true);
      const response = await makeSameAsPresentAddress(
        {
          id: editAddress.id,
          item_order: info.item_order,
        },
        token
      );

      if (response.status === 1) {
        setIsPresentAddress(false);
        setLoading(false);
        dispatch(saveAddressData(response.data));
        setInfo({
          info_title: response.info_title,
          info_details: response.info_details,
          item_order: response.item_order,
        });
        dispatch(setAlert(response.message, "success"));
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error setting same as present address ", error.message);

      setLoading(false);
    }
  };

  const handleSetDefaultAddress = async () => {
    try {
      setLoading(true);
      const response = await makeAsDefaultAddress(
        {
          id: editAddress.id,
          item_order: info.item_order,
        },
        token
      );

      if (response.status === 1) {
        setDefaultModal(false);
        setLoading(false);
        dispatch(saveAddressData(response.data));

        dispatch(setAlert(response.message, "success"));

        setInfo({
          info_title: response.info_title,
          info_details: response.info_details,
          item_order: response.item_order,
        });
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error setting same as present address ", error.message);

      setLoading(false);
    }
  };

  const handleSetSameAddress = async (flag) => {
    try {
      if (flag === "0") {
        setLoading(true);
      } else {
        setRemoveLoading(true);
      }

      const response = await makeSameAddress(
        {
          id: editAddress.id,
          item_order: info.item_order,
          delete_old: flag,
        },
        token
      );

      if (response.status === 1) {
        dispatch(saveAddressData(response.data));
        dispatch(setAlert(response.message, "success"));
        setAsPermanent(false);
        if (flag === "0") {
          setLoading(false);
        } else {
          setRemoveLoading(false);
        }

        setInfo({
          info_title: response.info_title,
          info_details: response.info_details,
          item_order: response.item_order,
        });
        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
        setRemoveLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error submitting form:", error.message);

      setLoading(false);
      setRemoveLoading(false);
    }
  };

  let presentAddress = null;
  if (addresData.length > 0) {
    presentAddress = addresData.filter(
      (address) => address.address_type?.toString() === "1"
    );
  }

  let permanentAddress = null;
  if (addresData.length > 0) {
    permanentAddress = addresData.filter(
      (address) => address.address_type?.toString() === "2"
    )[0]?.heading;
  }

  if (skeletonLoad) {
    return <PublicitySkeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>
      <div className="flex justify-center  md:justify-normal relative ">
        <div>
          <SidebarNav />
        </div>
        <div className="lg:w-[65%] w-[97%] mb-4  h-fit lg:ml-6 md:ml-2 ml-0">
          <div className="bg-white shadow-md md:p-10 p-6 ">
            <div className="mb-4 md:mb-6 md:flex items-center ">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">ADDRESS DETAILS</h2>
                  <button
                    className="ml-2 flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 mt-2 h-[2px] mb-2"></div>
              </div>
              {!addNew && !edit && (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2" />
                  Add new
                </button>
              )}
            </div>
            {!addNew && !edit && (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                {addresData.map((address, index) => (
                  <div key={index}>
                    <AddressItem
                      key={index}
                      type={address.address_type}
                      addressType={address.address_type_name}
                      title={address.heading}
                      content={address.address}
                      data={address.fields}
                      same_as_present={address.same_as_present}
                      defaultAddress={address.default}
                      isPermanent={permanentAddress}
                      edit={handleEdit}
                      setDefault={handleDefault}
                      setSameAddress={handleSameAddress}
                      deleteItem={handleDelete}
                      setAsPreset={handleSetAsPresent}
                    />
                  </div>
                ))}
              </div>
            )}

            {addNew && (
              <AddressForm
                cancel={handleAddNewCancel}
                add={true}
                addVal={presentAddress[0].fields}
                item_order={info.item_order}
              />
            )}

            {edit && (
              <AddressForm
                cancel={handleEditCancel}
                add={false}
                val={editAddress}
              />
            )}
          </div>
          {!addNew && !edit && addresData.length > 0 && (
            <div className="w-full flex justify-center">
              <div className="bg-white shadow-lg text-center flex   w-max my-6 text-xs py-3   rounded-full    px-6 focus:outline-none focus:shadow-outline">
                Do you have more addresses ?
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={handleAddNew}
                >
                  <FaPlus className="mr-2 ml-4" />
                  Add new
                </button>
              </div>
            </div>
          )}
        </div>

        {deleteModal && (
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to delete this address ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleAddressDelete}
                >
                  {loading ? "DELETING..." : "DELETE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {isPresentAddress && (
          <Modal
            isOpen={isPresentAddress}
            onClose={() => setIsPresentAddress(false)}
          >
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to set this as your present address ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setIsPresentAddress(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  min-w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleSetPresentAddress}
                >
                  {loading ? "Saving..." : "CONFIRM"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {defaultModal && (
          <Modal isOpen={defaultModal} onClose={() => setDefaultModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to set this as your default address ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDefaultModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  min-w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleSetDefaultAddress}
                >
                  {loading ? "Saving..." : "CONFIRM"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {isPermanent && (
          <Modal isOpen={isPermanent} onClose={() => setAsPermanent(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Would you like to keep this address or remove it?
            </p>
            <div className="px-[20px]">
              <div
                className="my-2 flex 
                   justify-between"
              >
                <button
                  className="border-custom border   mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setAsPermanent(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552] ml-0  min-w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading || removeLoading}
                  onClick={() => handleSetSameAddress("0")}
                >
                  {loading ? "Saving..." : "KEEP"}
                </button>

                <button
                  className="bg-red-500   hover:bg-red-400  min-w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={removeLoading || loading}
                  onClick={() => handleSetSameAddress("1")}
                >
                  {removeLoading ? "Saving..." : "REMOVE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"OTHER LANGUAGES INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AddressComponent;
