import axios from "axios";
import { FETCH_MENU_STATE } from "../config/config";

// get menu state api call
export const getMenuState = async (token) => {
  try {
    const response = await axios.get(FETCH_MENU_STATE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get menu state", error);
    throw error;
  }
};
