import React, { useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { FaRegUser, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarToggle } from "../../Redux/Actions/profileActions";
import { FiGift } from "react-icons/fi";

const styles = {
  ["& > ." + menuClasses.button]: {
    backgroundColor: "#f1f1f1",
    color: "#9f0099",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#d9d9d9 !important",
    },
    "&.ps-open": {
      backgroundColor: "#526754 !important ",
      color: " white !important",
      borderBottom: "none",
    },
  },
  ["." + menuClasses.subMenuContent]: {
    backgroundColor: "#F8F8F8",
    fontSize: "13px",
    borderBottom: "10px solid white",
    ["& ." + menuClasses.button]: {
      paddingLeft: "35px !important",
      paddingRight: "15px !important",
      "&.ps-active": {
        paddingLeft: "30px !important",
        paddingRight: "15px !important",
        backgroundColor: "#52675429 !important ",
        borderLeft: "5px solid #526754",
      },
      "&:hover": {
        backgroundColor: "#45c55029 !important ",
      },
    },
  },
};

const SidebarNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.profile.menuData);
  const toggled = useSelector((state) => state.profile.sideBarToggle);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterMenuItems = (label) => {
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const renderSubMenuItems = (items) => {
    return items
      .filter(({ label }) => filterMenuItems(label))
      .map(({ label, link, key, icon }) => (
        <MenuItem
          key={key}
          component={<Link to={link} />}
          active={location.pathname === link}
        >
          {label}
          {menuState[key] === 0 && <p className="text-blue-500 text-xs" title="More Details To Add">Add</p>}
        </MenuItem>
      ));
  };

  const profileMenuItems = [
    { label: "Basic Details", link: "/profile/basic-details", key: "basic" },
    {
      label: "Personal Details",
      link: "/profile/personal-details",
      key: "personal",
    },
    { label: "Profile Images", link: "/profile/profile-pics", key: "photo" },
    {
      label: "English Knowledge",
      link: "/profile/english-knowledge",
      key: "english",
    },
    {
      label: "Other Languages",
      link: "/profile/other-languages",
      key: "otherlang",
    },
    {
      label: "Qualification Details",
      link: "/profile/qualification-details",
      key: "qualifications",
    },
    {
      label: "Professional Details",
      link: "/profile/professional-details",
      key: "professional",
    },
    {
      label: "Address Details",
      link: "/profile/address-details",
      key: "address",
    },
    {
      label: "Address For Publicity",
      link: "/profile/publicity-address",
      key: "publicaddress",
    },
    {
      label: "General Information",
      link: "/profile/generic-information",
      key: "generic",
    },
    {
      label: "Activate Reseller Role",
      link: "/profile/reseller",
      key: "reseller",
    },
    { label: "Become A Sponsor", link: "/profile/sponsor", key: "sponsor" },
  ];

  const biddingMenuItems = [
    { label: "My Bid", link: "/bidding/my-bid", key: "bid" },
  ];

  const filteredProfileMenuItems = renderSubMenuItems(profileMenuItems);
  const filteredBiddingMenuItems = renderSubMenuItems(biddingMenuItems);

  return (
    <div className="h-fit">
      <Sidebar
        onBackdropClick={() => dispatch(setSidebarToggle(false))}
        toggled={toggled}
        breakPoint="sm"
      >
        <h2 className="text-xl text-custom font-semibold text-center mb-4 mt-3">
          MY ACCOUNT
        </h2>
        <div className="mx-2 relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 mb-3 border border-gray-400 px-2 py-2 text-xs w-full focus:outline-none"
            placeholder="Search Menus..."
          />
          <FaSearch className="absolute top-[10px] right-[10px] text-gray-500" />
        </div>
        <Menu iconShape="square" transitionDuration={500}>
          <div className="bg-[#F9F9F9] min-h-[82vh] m-2">
            {filteredProfileMenuItems.length === profileMenuItems.length && (
              <SubMenu
                label="Profile"
                icon={<FaRegUser />}
                defaultOpen={location.pathname.startsWith("/profile")}
                rootStyles={styles}
              >
                {filteredProfileMenuItems}
              </SubMenu>
            )}
            {filteredProfileMenuItems.length < profileMenuItems.length &&
              filteredProfileMenuItems.length > 0 && (
                <SubMenu
                  label="Profile"
                  icon={<FaRegUser />}
                  defaultOpen={
                    filteredProfileMenuItems.length < profileMenuItems.length
                  }
                  rootStyles={styles}
                >
                  {filteredProfileMenuItems}
                </SubMenu>
              )}
            {filteredBiddingMenuItems.length === biddingMenuItems.length &&
              !searchTerm && (
                <SubMenu
                  label="Bidding"
                  defaultOpen={location.pathname.startsWith("/up-coming")}
                  icon={<FiGift />}
                  rootStyles={styles}
                >
                  {filteredBiddingMenuItems}
                </SubMenu>
              )}
            {searchTerm && filteredBiddingMenuItems.length > 0 && (
              <SubMenu
                label="Bidding"
                defaultOpen={
                  filteredBiddingMenuItems.length <= biddingMenuItems.length
                }
                icon={<FiGift />}
                rootStyles={styles}
              >
                {filteredBiddingMenuItems}
              </SubMenu>
            )}

            {filteredProfileMenuItems.length === 0 &&
              filteredBiddingMenuItems.length === 0 && (
                <p className="text-xs text-gray-600 text-center p-3">
                  Nothing to show
                </p>
              )}
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarNav;
