import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getIdList } from "../../api/otherCountryApi";
import { FiLoader } from "react-icons/fi";

const IdSelect = ({ getId, id, error, code }) => {
  const [Options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(id?.value ? id : null);
  const userToken = useSelector((state) => state.register.token);

  function findObjectByValue(array, valueToFind) {
    return array.find((obj) => obj.value === valueToFind);
  }

  useEffect(() => {
    const foundObject = findObjectByValue(Options, id?.value);
    if (foundObject && foundObject.label) {
      setSelectedOption(foundObject);
      getId(foundObject);
    }
  }, [id?.value]);

  useEffect(() => {
    if (id?.value) {
      setSelectedOption(id);
    } else {
      setSelectedOption(null);
    }
  }, [code]);

  useEffect(() => {
    getId(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    const fetchIds = async (userToken) => {
      try {
        setLoading(true);
        const Data = await getIdList(userToken, code);
        if (Data.status === 1) {
          const options = Data.data.map((id) => ({
            value: id.value,
            label: id.label,
          }));
          setOptions(options);

          setTimeout(() => {
            setLoading(false);
          }, 2500);
        } else {
          setOptions([]);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Failed to get id list", error);
      }
    };

    fetchIds(userToken);
  }, [userToken, code]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getId(selectedOption);
  };

  return (
    <div className="relative">
      <Select
        className="text-xs"
        isClearable={true}
        options={Options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: "7px",
          boxShadow: null,
          colors: {
            ...theme.colors,
            primary25: "#526754",
            primary: "#526754",
          },
        })}
        value={
          selectedOption && Object.keys(selectedOption).length === 0
            ? ""
            : selectedOption
        }
        onChange={handleChange}
      />
      {loading && (
        <div class="  absolute  top-[5px] right-[60px]">
          <img
            class="h-[25px] w-[25px]"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default IdSelect;
