import React, { useEffect, useState } from "react";
import Select from "react-select";

const FromToSelect = ({
  getDuration,
  error,
  valYear,
  valMonth,
  title,
  isCurrentWork,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };
  console.log("from too", valMonth, valYear);
  const monthLabel = valMonth?.label.split(" ")[0];

  const Month = {
    value: valMonth?.value,
    label: monthLabel !== "Still" && monthLabel,
  };
  const Year = { value: valYear?.value, label: valYear?.label.split(" ")[1] };

  // Initial state for day, month, and year

  const [selectedMonth, setSelectedMonth] = useState(
    valMonth?.value && valMonth?.label ? monthLabel !== "Still" && Month : ""
  );
  const [selectedYear, setSelectedYear] = useState(
    valYear?.value && valYear?.label ? monthLabel !== "Still" && Year : ""
  );
  
  const monthOptions = [
    { value: 1, label: "JAN" },
    { value: 2, label: "FEB" },
    { value: 3, label: "MAR" },
    { value: 4, label: "APR" },
    { value: 5, label: "MAY" },
    { value: 6, label: "JUN" },
    { value: 7, label: "JUL" },
    { value: 8, label: "AUG" },
    { value: 9, label: "SEP" },
    { value: 10, label: "OCT" },
    { value: 11, label: "NOV" },
    { value: 12, label: "DEC" },
  ];

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];

    for (let i = 0; i <= 100; i++) {
      const year = currentYear - i;
      options.push({ value: year, label: `${year}` });
    }

    return options;
  };

  // Function to handle change in month selection
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    passDate(selectedOption, selectedYear);
  };

  // Function to handle change in year selection
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    passDate(selectedMonth, selectedOption);
  };

  // Function to pass the selected date
  const passDate = (month, year) => {
    getDuration({
      month: month?.value,
      year: year?.value,
      month_name: month?.label,
      year_name: year?.label,
    });
  };

  useEffect(() => {
    if (isCurrentWork) {
      setSelectedMonth(null);
      setSelectedYear(null);
      getDuration({
        month: null,
        year: null,
        month_name: null,
        year_name: null,
      });
    }
  }, [isCurrentWork]);

  return (
    <>
      <fieldset className="border border-gray-400 rounded-lg px-4 py-4   text-xs w-full focus:outline-none">
        <legend className="text-xs text-gray-700  ">{title}</legend>
        <div className="flex">
          {/* Month */}
          <Select
            className={`w-1/2 mr-2 text-xs`}
            options={monthOptions}
            onChange={handleMonthChange}
            isDisabled={isCurrentWork}
            isClearable={true}
            value={selectedMonth}
            styles={customStyles}
            placeholder={"Month"}
            theme={(theme) => ({
              ...theme,
              borderRadius: "7px",
              boxShadow: null,
              colors: {
                ...theme.colors,
                primary25: "#526754",
                primary: "#526754",
              },
            })}
          />
          {/* Year */}
          <Select
            className={`w-1/2 text-xs`}
            options={generateYearOptions()}
            onChange={handleYearChange}
            value={selectedYear}
            isDisabled={isCurrentWork}
            isClearable={true}
            placeholder={"Year"}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: "7px",
              boxShadow: null,
              colors: {
                ...theme.colors,
                primary25: "#526754",
                primary: "#526754",
              },
            })}
          />
        </div>
        {/* Error message */}
      </fieldset>
    </>
  );
};

export default FromToSelect;
