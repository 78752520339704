import {
  ADDRESS_DATA,
  BASIC_DETAILS,
  BASIC_DETAILS_DATA,
  SAVE_MENU_DATA,
  SET_TOGGLE,
} from "../Actions/profileActions";

const profile_pic = JSON.parse(localStorage.getItem("profile_pic"));
const menu_state = JSON.parse(localStorage.getItem("menu_state"));
const initialState = {
  pic: profile_pic ? profile_pic : "",
  basicDetails: {},
  menuData: menu_state ? menu_state : {},
  sideBarToggle: false,
  addressData: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_DETAILS_DATA:
      localStorage.setItem("profile_pic", JSON.stringify(action.payload));
      return {
        ...state,
        pic: action.payload,
      };
    case BASIC_DETAILS:
      return {
        ...state,
        basicDetails: action.payload,
      };
    case ADDRESS_DATA:
      return {
        ...state,
        addressData: action.payload,
      };
    case SET_TOGGLE:
      return {
        ...state,
        sideBarToggle: action.payload,
      };
    case SAVE_MENU_DATA:
      localStorage.setItem("menu_state", JSON.stringify(action.payload));
      return {
        ...state,
        menuData: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
