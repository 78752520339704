import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import RegisterOne from "./pages/Register/RegisterOne";
import RegisterTwo from "./pages/Register/RegisterTwo";
import LoginOne from "./pages/Login/LoginOne";
import LoginTwo from "./pages/Login/LoginTwo";
import Code from "./pages/Login/Code";
import ResetPassword from "./pages/Login/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { refreshTokenPeriodically } from "./Redux/Reducers/registerReducers";
import { useEffect } from "react";
import Alert from "./components/Alert/Alert";
import { removeAlert } from "./Redux/Actions/alertActions";
import PersonalDetails from "./pages/Personal/PersonalDetails";
import BasicDetails from "./pages/Basic/BasicDetails";
import GenericInfo from "./pages/Generic/GenericInfo";
import EnglishKnowledge from "./pages/English/EnglishKnowledge";
import OtherLanguages from "./pages/Other Languages/OtherLanguages";
import Qualification from "./pages/Qualification/Qualification";
import ProfessionalDetails from "./pages/Professional/ProfessionalDetails";
import PublicityAddress from "./pages/Publicity Address/PublicityAddress";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import NotFound from "./components/NotFound/NotFound";
import { getMenuState } from "./api/sideBarApi";
import { removeToken } from "./Redux/Actions/registerActions";
import { saveMenuData } from "./Redux/Actions/profileActions";
import RegisterProtect from "./components/ProtectedRoutes/RegisterProtect";
import ProtectedLogin from "./components/ProtectedRoutes/ProtectedLogin";
import ProfilePhotos from "./pages/ProfilePics/ProfilePhotos";
import AddressDetails from "./pages/Address/AddressDetails";

function App() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert.alert);
  const token = useSelector((state) => state.register.token);

  useEffect(() => {
    const fetchMenuState = async (token) => {
      try {
        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          dispatch(removeToken());
          console.error("Failed to menu state");
        }
      } catch (error) {
        console.error("Error to get menu state", error);
      }
    };
    fetchMenuState(token);
  }, [token]);

  useEffect(() => {
    setInterval(() => {
      refreshTokenPeriodically(dispatch);
    }, 30 * 60 * 1000);

    // Call refresh token immediately when app loads
    setTimeout(() => {
      refreshTokenPeriodically(dispatch);
    }, 1 * 1000);
  }, [dispatch]);

  const handleCloseAlert = () => {
    dispatch(removeAlert());
  };
  return (
    <div>
      <Navbar />
      {/* Render the alert component if there's an alert */}
      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={handleCloseAlert}
        />
      )}

      <Routes>
        <Route path="/" element={<ProtectedLogin element={LoginOne} />} />
        <Route
          path="/register-step-1"
          element={<ProtectedLogin element={RegisterOne} />}
        />
        <Route
          path="/login-step-2"
          element={<ProtectedLogin element={LoginTwo} />}
        />
        <Route
          path="/verify-code"
          element={<ProtectedLogin element={Code} />}
        />
        <Route
          path="/reset-password"
          element={<ProtectedLogin element={ResetPassword} />}
        />
        <Route
          path="/register-step-2"
          element={<RegisterProtect element={RegisterTwo} />}
        />

        <Route
          path="/profile/basic-details"
          element={<ProtectedRoute element={BasicDetails} />}
        />
        <Route
          path="/profile/personal-details"
          element={<ProtectedRoute element={PersonalDetails} />}
        />
        <Route
          path="/profile/generic-information"
          element={<ProtectedRoute element={GenericInfo} />}
        />
        <Route
          path="/profile/english-knowledge"
          element={<ProtectedRoute element={EnglishKnowledge} />}
        />
        <Route
          path="/profile/address-details"
          element={<ProtectedRoute element={AddressDetails} />}
        />
        <Route
          path="/profile/other-languages"
          element={<ProtectedRoute element={OtherLanguages} />}
        />

        <Route
          path="/profile/qualification-details"
          element={<ProtectedRoute element={Qualification} />}
        />
        <Route
          path="/profile/professional-details"
          element={<ProtectedRoute element={ProfessionalDetails} />}
        />
        <Route
          path="/profile/publicity-address"
          element={<ProtectedRoute element={PublicityAddress} />}
        />
        <Route
          path="/profile/profile-pics"
          element={<ProtectedRoute element={ProfilePhotos} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
