import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getDesignationList } from "../../api/registerApi";
import { TbDots } from "react-icons/tb";

const DesignationSelect = ({ getDesignation, id, val, error }) => {
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    val?.value && val?.label ? val : ""
  );
  const userToken = useSelector((state) => state.register.token);

  function findObjectByValue(array, valueToFind) {
    return array.find((obj) => obj.value === valueToFind);
  }

  useEffect(() => {
    const foundObject = findObjectByValue(designationOptions, val?.value);
    if (foundObject) {
      setSelectedOption(foundObject);
      getDesignation(foundObject);
    } else {
      setSelectedOption("");
    }
  }, [val?.value, designationOptions]);

  useEffect(() => {
    const fetchDesignations = async (userToken, id) => {
      try {
        setLoading(true);
        const designationData = await getDesignationList(userToken, id);
        if (designationData.status === 1) {
          const options = designationData.data.map((designation) => ({
            value: designation.value,
            label: designation.label,
          }));
          setDesignationOptions(options);
          setLoading(false);
        } else {
          setDesignationOptions([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Failed to get designation list", error);
      }
    };

    fetchDesignations(userToken, id);
  }, [userToken, dispatch, id]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      border: error ? "1px solid red" : "1px solid rgb(156 163 175)",
      height: "34px",
      minHeight: "34px",
      paddingLeft: "6px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#526754" : null,
      color: state.isFocused ? "white" : "black",
    }),

    indicatorSeparator: (state) => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      paddingTop: "5px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "#cf0505",

      padding: "2px",
    }),
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    getDesignation(selectedOption);
  };

  return (
    <div className="relative">
      <Select
        className="text-xs"
        isClearable={true}
        options={designationOptions}
        value={selectedOption}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: "7px",
          boxShadow: null,
          colors: {
            ...theme.colors,
            primary25: "#526754",
            primary: "#526754",
          },
        })}
        onChange={handleChange}
      />
       {loading && (
        <div class="  absolute  top-[5px] right-[60px]">
          <img
            class="h-[25px] w-[25px]"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default DesignationSelect;
