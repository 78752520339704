import axios from "axios";
import {
  DELETE_PROFESSIONS,
  FETCH_JOB_CATEGORY_LIST,
  FETCH_JOB_SPECIALISATION_LIST,
  FETCH_PROFESSIONS,
  UPDATE_PROFESSIONS,
} from "../config/config";

// get other professions details get api call
export const getProfessionalDetails = async (token) => {
  try {
    const response = await axios.get(FETCH_PROFESSIONS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get professions", error);
    throw error;
  }
};

//update profession data post api call
export const updateProfessionData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_PROFESSIONS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update profession data:", error);
    throw error;
  }
};

//Delete professions data post api call
export const deleteProfessionData = async (userData, token) => {
  try {
    const response = await axios.post(DELETE_PROFESSIONS, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error delete professions data:", error);
    throw error;
  }
};

// get job category list api call
export const getJobCategoryList = async (token, id) => {
  try {
    const response = await axios.post(
      FETCH_JOB_CATEGORY_LIST,
      {
        limit: "0",
        status: "1",
        occupation_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job category list", error);
    throw error;
  }
};

// get specialisation list api call
export const getJobSpecialisationList = async (token, id) => {
  try {
    const response = await axios.post(
      FETCH_JOB_SPECIALISATION_LIST,
      {
        limit: "0",
        status: "1",
        profession_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job specialisation list", error);
    throw error;
  }
};
