import { refreshToken } from "../../api/registerApi";
import {
  REGISTER_STEP_ONE_DATA,
  REGISTER_STEP_TWO_DATA,
  REMOVE_TOKEN,
  SET_TOKEN,
  UPDATE_STEP_ONE_DATA,
} from "../Actions/registerActions";

// Retrieve data from local storage if available
const storedData = localStorage.getItem("registerStepOneData");
const userToken = JSON.parse(localStorage.getItem("userToken"));
const initialState = {
  stepOneData: storedData
    ? JSON.parse(storedData)
    : {
        firstname: "",
        lastname: "",
        email: "",
        username: "",
        password: "",
        mobile: "",
        dial_code: "",
        country_code: "",
        gsid: "",
      },
  token: userToken ? userToken.slice(0, -5) : "",
  stepTwoData: {
    gender: "",
    day: "",
    month: "",
    year: "",
    country: "",
    motherTongue: "",
    qualification: "",
    courseName: "",
    occupation: "",
    destination: "",
    confirm: false,
  },
};

const generateUniqueId = () => {
  const characters = "AbCd0e4fgHijklm3nopq7rtU9vw2xySz";
  let uniqueId = "";
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters[randomIndex];
  }
  return uniqueId;
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_STEP_ONE_DATA:
      // Save data to local storage
      localStorage.setItem(
        "registerStepOneData",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        stepOneData: action.payload,
      };
    case UPDATE_STEP_ONE_DATA:
      // Save data to local storage
      localStorage.setItem(
        "registerStepOneData",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        stepOneData: action.payload,
      };
    case SET_TOKEN:
      // Generate a 5-character unique ID
      const uniqueId = generateUniqueId();
      // Append the unique ID to the token
      const tokenWithId = `${action.payload}${uniqueId}`;
      // Save the modified token to local storage
      localStorage.setItem("userToken", JSON.stringify(tokenWithId));

      return {
        ...state,
        token: action.payload,
      };
    case REMOVE_TOKEN:
      localStorage.removeItem("userToken");
      return { ...state, token: null };
    case REGISTER_STEP_TWO_DATA:
      return {
        ...state,
        stepTwoData: action.payload,
      };
    default:
      return state;
  }
};

// Function to refresh token every 15 minutes
export const refreshTokenPeriodically = (dispatch) => {
  const token = JSON.parse(localStorage.getItem("userToken"));

  if (token) {
    const userToken = token.slice(0, -5);
    // Call refresh token API
    refreshToken(userToken)
      .then((newToken) => {
        // Dispatch action to update token in Redux state
        dispatch({ type: SET_TOKEN, payload: newToken });
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
      });
  }
};

export default registerReducer;
