import { combineReducers } from "redux";
import registerReducer from "./Reducers/registerReducers";
import alertReducer from "./Reducers/alertReducers";
import loginReducer from "./Reducers/loginReducers";
import profileReducer from "./Reducers/profileReducers";

const rootReducer = combineReducers({
  register: registerReducer,
  alert: alertReducer,
  login: loginReducer,
  profile: profileReducer,
});

export default rootReducer;
