import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedLogin = ({ element: Component }) => {
  const isAuthenticated = useSelector((state) => !!state.register.token);

  if (isAuthenticated) {
    return <Navigate to="/profile/basic-details" />;
  }

  return <Component />;
};

export default ProtectedLogin;
