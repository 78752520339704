import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Welcome from "../../components/Welcome/Welcome";
import { saveStepOneData, setToken } from "../../Redux/Actions/registerActions";
import PhoneForm from "../../components/Phone/Phone";
import { registerLevelOne, userCheck } from "../../api/registerApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import { FaEye, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import { PhoneNumberUtil } from "google-libphonenumber";
import { saveRegisterTwoFlag } from "../../Redux/Actions/loginActions";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};

const RegisterOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.register.token);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    mobile: "",
    country_code: "in",
    dial_code: "91",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [showPwdTooltip, setShowPwdTooltip] = useState(false);

  const inputRef = useRef(null);
  const phoneInputRef = useRef(null);

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("First Name is required")
      .max(20, "The firstname field must not be greater than 20 characters."),
    lastname: Yup.string()
      .required("Last Name is required")
      .max(20, "The last field must not be greater than 20 characters."),
    email: Yup.string()
      .notRequired()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        { message: "Invalid email", excludeEmptyString: true }
      ),
    mobile: Yup.string()
      .test("is-valid-phone", "Valid phone number required", function (value) {
        const dialCode = formData.dial_code;
        const phoneNumber = `+${dialCode}${value}`;
        return isPhoneValid(phoneNumber);
      })
      .required("Phone number is required"),
    username: Yup.string()
      .matches(
        /^(?![0-9])[a-zA-Z0-9]+$/,
        "Username should be in a valid format"
      )
      .required("Studywin ID is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        "Password must include at least one lowercase letter, one uppercase letter, one number, and one symbol"
      )
      .required("Password is required"),
  });

  // Initialize state for validation errors
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userToken) {
      navigate("/basic-details");
    }
  }, []);

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const lowerCaseValue = name === "email" ? value.toLowerCase() : value;
    setFormData({
      ...formData,
      [name]: lowerCaseValue,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handlePhoneChange = ({ dial_code, mobile, country_code }) => {
    setFormData({
      ...formData,
      dial_code,
      mobile,
      country_code,
    });

    // Clear mobile number error when the user starts typing
    if (errors.mobile) {
      setErrors({ ...errors, mobile: "" });
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (errors.username || errors.mobile || errors.email) {
        return;
      }
      setLoading(true);

      await validationSchema.validate(formData, { abortEarly: false });

      const response = await registerLevelOne(formData);

      if (response.status === 1) {
        dispatch(saveRegisterTwoFlag(0));
        dispatch(saveStepOneData(response.data));
        dispatch(setToken(response.authorisation.token));
        dispatch(setAlert(response.message, "success"));
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          username: "",
          password: "",
        });
        setLoading(false);
        navigate("/register-step-2");
        setErrors({});
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        // If validation fails, set errors state with validation errors
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
        setLoading(false);
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value) {
      checkUser({ [name]: value }, event.target);
    }
  };

  const checkUser = async (userData, target) => {
    try {
      const response = await userCheck(userData);
      const key = Object.keys(userData)[0];
      if (response.data.status) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: response.data.errors[key],
        }));
        target.focus();
      }
    } catch (error) {
      console.error("Error checking user duplicate", error);
    }
  };

  return (
    <div className="flex bg-custom-pattern justify-center min-h-page-height items-center">
      <div className="container mx-auto ">
        <div className="flex flex-col md:justify-between md:flex-row   lg:pt-20">
          {/* Left Section */}

          <Welcome />

          {/* Right Section */}
          <div className="lg:w-2/5 md:w-[60%] p-4">
            <div className="bg-white lg:w-11/12 rounded-lg  shadow-lg p-9">
              <h2 className="text-lg text-custom text-center mb-4">
                STUDENT REGISTRATION
              </h2>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="firstname"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none  ${
                      errors.firstname ? "border-red-500" : ""
                    }`}
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    placeholder="Enter your first name"
                  />
                  {errors.firstname && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.firstname}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="lastname"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>

                  <input
                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none  ${
                      errors.lastname ? "border-red-500" : ""
                    }`}
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Enter your last name"
                    value={formData.lastname}
                    onChange={handleInputChange}
                  />
                  {errors.lastname && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.lastname}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs   mb-2"
                    htmlFor="email"
                  >
                    Email ID
                  </label>
                  <input
                    className={`  border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.email ? "border-red-500" : ""
                    }`}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    ref={inputRef}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                  )}
                </div>
                <div className="mb-4    ">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="country"
                  >
                    Mobile No <span className="text-red-500">*</span>
                  </label>
                  {/* Country Dropdown */}
                  <PhoneForm
                    onPhoneChange={handlePhoneChange}
                    Error={errors.mobile}
                    inputPhoneRef={phoneInputRef}
                    val={{
                      country_code: formData.country_code,
                      dial_code: formData.dial_code,
                      mobile: formData.mobile,
                    }}
                    checkUser={checkUser}
                  />
                  {errors.mobile && (
                    <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
                  )}
                </div>

                <div className="mb-4 relative">
                  <div className="flex">
                    <label
                      className="block text-gray-700 text-xs   mb-2"
                      htmlFor="id"
                    >
                      Create Your Studywin ID{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <a
                      className="flex items-center mb-2 text-blue-500"
                      onClick={() => setShowUserTooltip(!showUserTooltip)}
                      onMouseEnter={() => setShowUserTooltip(true)}
                      onMouseLeave={() => setShowUserTooltip(false)}
                    >
                      <FaInfoCircle className="text-blue-500 cursor-pointer ml-2" />
                    </a>
                    {showUserTooltip && (
                      <div className="absolute  -top-[110px]  -left-[50%] transform -translate-x-[-65%] w-[250px] text-[12px] mb-2 p-4 bg-gray-700 text-white   rounded shadow-none z-10">
                        Username must contain only combination of letters and
                        numbers or letters only, and cannot start with a number.
                      </div>
                    )}
                  </div>

                  <input
                    className={`border  border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.username ? "border-red-500" : ""
                    }`}
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter your studywin id (username)"
                    value={formData.username}
                    ref={inputRef}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />

                  {errors.username && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.username}
                    </p>
                  )}
                </div>
                <div className="mb-4 relative">
                  <div className="flex">
                    <label
                      className="block text-gray-700 text-xs   mb-2"
                      htmlFor="password"
                    >
                      Password For Studywin ID{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <a
                      className="flex items-center mb-2 text-blue-500"
                      onClick={() => setShowPwdTooltip(!showPwdTooltip)}
                      onMouseEnter={() => setShowPwdTooltip(true)}
                      onMouseLeave={() => setShowPwdTooltip(false)}
                    >
                      <FaInfoCircle className="text-blue-500 cursor-pointer ml-2" />
                    </a>
                    {showPwdTooltip && (
                      <div className="absolute -top-[100px]  -left-[50%] transform -translate-x-[-68%] w-[250px] text-[12px] mb-2 p-4 bg-gray-700 text-white   rounded shadow-none z-10">
                        Password must include at least one lowercase letter, one
                        uppercase letter, one number, and one symbol.
                      </div>
                    )}
                  </div>
                  <input
                    className={`border   border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.password ? "border-red-500" : ""
                    }`}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {/* Eye Icons */}
                  <button
                    className="absolute right-2 top-9"
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-custom" title="Hide" />
                    ) : (
                      <FaEye className="text-custom" title="Show"/>
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.password}
                    </p>
                  )}
                </div>

                <button
                  className="bg-[#5E8862] w-full rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "NEXT"}
                </button>
                <p className="text-xs my-4">
                  Already Registered?{" "}
                  <NavLink to="/" className="text-custom font-bold">
                    Login
                  </NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterOne;
