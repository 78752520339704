import React, { useState } from "react";
import * as Yup from "yup";
import Welcome from "../../components/Welcome/Welcome";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../api/loginApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import { FaEye, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import { saveForgotData } from "../../Redux/Actions/loginActions";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const forgotData = useSelector((state) => state.login.forgotData);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPwdTooltip, setShowPwdTooltip] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        "Password must include at least one lowercase letter, one uppercase letter, one number, and one symbol"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await validationSchema.validate(formData, { abortEarly: false });
      const response = await resetPassword(forgotData.token, formData);
      if (response.status === 1) {
        dispatch(setAlert(response.message, "success"));
        dispatch(saveForgotData(null));
        setFormData({ password: "", confirm_password: "" });
        navigate("/login-step-2");
        setErrors({});
        setLoading(false);
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="flex bg-custom-pattern justify-center items-center min-h-page-height">
      <div className="container mx-auto ">
        <div className="flex flex-col md:flex-row">
          {/* Left Section */}
          <Welcome />
          {/* Right Section */}
          <div className="lg:w-2/5 md:w-[60%] p-4 md:my-auto">
            <div className="bg-white lg:w-11/12   rounded-lg shadow-lg p-9">
              <h2 className="text-lg text-custom text-center my-4">
                RESET PASSWORD
              </h2>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                <p className="block text-gray-700 text-xs my-6">
                  Your new password must be different from previously used
                  password.
                </p>
                {/* Password Field */}
                <div className="mb-4 relative">
                  <div className="flex">
                    <label
                      className="block text-gray-700 text-xs   mb-2"
                      htmlFor="password"
                    >
                      Password For Studywin ID{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <a
                      className="flex items-center mb-2 text-blue-500"
                      onClick={() => setShowPwdTooltip(!showPwdTooltip)}
                      onMouseEnter={() => setShowPwdTooltip(true)}
                      onMouseLeave={() => setShowPwdTooltip(false)}
                    >
                      <FaInfoCircle className="text-blue-500 cursor-pointer ml-2" />
                    </a>
                    {showPwdTooltip && (
                      <div className="absolute -top-[110px]  -left-[50%] transform -translate-x-[-68%] w-[250px] text-[12px] mb-2 p-4 bg-gray-700 text-white   rounded shadow-none z-10">
                        Password must include at least one lowercase letter, one
                        uppercase letter, one number, and one symbol.
                      </div>
                    )}
                  </div>
                  <input
                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.password ? "border-red-500" : ""
                    }`}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Enter your new password"
                  />
                  {/* Eye Icons */}
                  <button
                    className="absolute right-2 top-9"
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-custom" title="Hide"/>
                    ) : (
                      <FaEye className="text-custom" title="Show"/>
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.password}
                    </p>
                  )}
                </div>
                {/* Confirm Password Field */}
                <div className="mb-4 relative">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="confirm_password"
                  >
                    Confirm Password
                  </label>
                  <input
                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.confirm_password ? "border-red-500" : ""
                    }`}
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleInputChange}
                    placeholder="Re-enter your new password"
                  />
                  {/* Eye Icons */}
                  <button
                    className="absolute right-2 top-9"
                    type="button"
                    onClick={handleToggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <FaEyeSlash className="text-custom" title="Hide"/>
                    ) : (
                      <FaEye className="text-custom" title="Show"/>
                    )}
                  </button>
                  {errors.confirm_password && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.confirm_password}
                    </p>
                  )}
                </div>
                {/* Submit Button */}
                <button
                  className="bg-custom w-full rounded-full hover:bg-lime-950 text-white my-4 py-2 px-4 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Resetting..." : "RESET"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
