import React, { useState } from "react";

const ReadMore = ({ contentToAdd }) => {
  const contentWithDiv = contentToAdd.replaceAll("<div>", "");
  const content = contentWithDiv.replaceAll("</div>", "!!");
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const renderContent = () => {
    if (content.length > 90) {
      const content2 = content.replaceAll("!!", "<br/>");
      if (isReadMore) {
        let truncatedContent = content2.slice(0, 90);
        const last4 = truncatedContent.slice(
          truncatedContent.length - 4,
          truncatedContent.length
        );
        if (last4.indexOf("<") > -1) {
          truncatedContent = content2.slice(0, 86) + "...";
        } else {
          truncatedContent = truncatedContent + "...";
        }
        return (
          <div
            dangerouslySetInnerHTML={{ __html: truncatedContent }}
            onClick={toggleReadMore}
            className="text-xs"
            style={{ cursor: "pointer" }}
          ></div>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: content2 }}
            className="text-xs"
            style={{ cursor: "pointer" }}
          ></div>
        );
      }
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: contentToAdd }}
          className="text-xs"
          style={{ cursor: "pointer" }}
        ></div>
      );
    }
  };

  return (
    <div>
      {renderContent()}
      {content.length > 90 && (
        <span
          onClick={toggleReadMore}
          className="text-blue-500 cursor-pointer text-[12px]"
        >
          {isReadMore ? "Read more" : " Read less"}
        </span>
      )}
    </div>
  );
};

const Content = ({ content }) => {
  return (
    <div>
      <ReadMore contentToAdd={content}></ReadMore>
    </div>
  );
};

export default Content;
