export const personalData = [
  {
    label: "First Name",
    name: "firstname",
    placeholder: "Enter your first name",
    required: true,
  },
  {
    label: "Last Name",
    name: "lastname",
    placeholder: "Enter your lastname",
    required: true,
  },
  {
    label: "Date Of Birth",
    name: "dob",
    placeholder: "Enter your date of birth",
    required: true,
  },
  {
    label: "Place Of Birth",
    name: "place_of_birth",
    placeholder: "Enter your place of birth",
    required: true,
  },
  {
    label: "Country Of Birth",
    name: "country_of_birth_name",
    placeholder: "Enter your country of birth",
    required: true,
  },
  {
    label: "Current Nationality",
    name: "nationality_name",
    placeholder: "Enter your nationality",
    required: true,
  },
  {
    label: "Mother Tongue",
    name: "mother_tongue_name",
    placeholder: "Enter your mother tongue",
    required: true,
  },
  {
    label: "Father's Name",
    name: "fathers_name",
    placeholder: "Enter your father's name",
    required: true,
  },
  {
    label: "Mother's Name",
    name: "mothers_name",
    placeholder: "Enter your mother's name",
    required: true,
  },
  {
    label: "Marital status",
    name: "marital_status_name",
    placeholder: "Enter your marital status",
    required: true,
  },
  {
    label: "Spouse's Name",
    name: "spouse_name",
    placeholder: "Enter your spouse name",
    required: true,
  },
  {
    label: "Gender",
    name: "gender_name",
    placeholder: "Enter your gender",
    required: true,
  },
  {
    label: "Blood Group",
    name: "blood_group_name",
    placeholder: "Enter your blood group",
  },
  {
    label: "Religion",
    name: "religion_name",
    placeholder: "Enter your religion",
  },
  {
    label: "",
    name: "religion_id_other",
    placeholder: "Enter your religion",
  },
  {
    label: "Whatsapp Number",
    name: "whatsapp_number",
    placeholder: "Enter your whatsapp number",
  },
  {
    label: "Land Phone Number",
    name: "land_area_number",
    placeholder: "Enter your land phone number",
  },
  {
    label: "Additional Email ID",
    name: "secondary_email",
    placeholder: "Enter your secondary email",
  },
  {
    label: "2nd mobile number",
    name: "secondary_mob_number",
    placeholder: "Enter your secondary mobile number",
  },
  {
    label: "My Favorite Job",
    name: "favorite_job",
    placeholder: "Enter your favorite job",
    required: true,
  },
];

//other languages

export const otherLanguagesData = [
  {
    label: "Language",
    name: "language_name",
    placeholder: "Enter your language name",
    required: true,
  },
  {
    label: "Speak",
    name: "speak_skill_name",
    placeholder: "Enter your speak skill ",
    required: true,
  },
  {
    label: "Read",
    name: "read_skill_name",
    placeholder: "Enter your read skill ",
    required: true,
  },
  {
    label: "Write",
    name: "write_skill_name",
    placeholder: "Enter your write skill ",
    required: true,
  },
];

//other country fields

export const otherCountryData = [

  {
    label: "House / Flat Name",
    name: "house_name",
    placeholder: "Enter your house/flat name",
  },
  {
    label: "House / Flat Number",
    name: "house_no",
    placeholder: "Enter your house/flat number",
  },
  {
    label: "Building Name",
    name: "building_name",
    placeholder: "Enter your building name",
  },
  {
    label: "Building Number",
    name: "building_no",
    placeholder: "Enter your building number",
  },
  { label: "Street", name: "street", placeholder: "Enter your street" },
  { label: "Place", name: "place", placeholder: "Enter your place" },
  { label: "Location", name: "location", placeholder: "Enter your location" },
  {
    label: "City",
    name: "city",
    placeholder: "Enter your city",
    required: true,
  },
  { label: "District", name: "district", placeholder: "Enter your district" },
  { label: "State", name: "state", placeholder: "Enter your state" },
  {
    label: "Country",
    name: "country",
    placeholder: "Enter your country",
    required: true,
  },
  {
    label: "Pincode / Zipcode",
    name: "zip_code",
    placeholder: "Enter your pincode/zipcode",
  },
  {
    label: "Land Mark - 01",
    name: "land_mark1",
    placeholder: "Enter your landmark",
  },
  {
    label: "Land Mark - 02",
    name: "land_mark2",
    placeholder: "Enter your landmark",
  },
  {
    label: "Nearest Police Station",
    name: "nearest_police_station",
    placeholder: "Enter your nearest police station",
  },
  {
    label: "ID Proof",
    name: "proof_name",
    placeholder: "Enter your ID proof",
  },
  {
    label: "ID Number",
    name: "proof_no",
    placeholder: "Enter your ID number",
  },
  {
    label: "Mobile Number",
    name: "mobile_no",
    placeholder: "Enter your mobile number",
    required: true,
  },
  
];

//qualification data for completed

export const qualificationDataCompleted = [
  {
    label: "Status Of Education",
    name: "qualification_status",
    placeholder: "Enter your qualification status",
    required: true,
  },
  {
    label: "Qualification Level",
    name: "qualification_name",
    placeholder: "Enter your qualification level",
    required: true,
  },
  {
    label: "",
    name: "qualification_id_other",
    placeholder: "Enter your qualification level",
     
  },
  {
    label: "Course & Subject",
    name: "course_name",
    placeholder: "Enter your course name",
    required: true,
  },
  {
    label: "",
    name: "course_id_other",
    placeholder: "Enter your course name",
   
  },
  {
    label: "Specialised Area",
    name: "discipline_name",
    placeholder: "Enter your specialization",
    required: true,
  },
  {
    label: "",
    name: "discipline_id_other",
    placeholder: "Enter your specialization",
    
  },
  {
    label: "Duration Of Course",
    name: "duration_course",
    placeholder: "Enter your course duration",
    required: true,
  },
  {
    label: "Medium Of Teaching",
    name: "medium_study_name",
    placeholder: "Enter your medium of teaching",
    required: true,
  },
  {
    label: "Year Of Completion",
    name: "year_of_pass",
    placeholder: "Enter your year of completion",
    required: true,
  },
  {
    label: "Marks/Grade in Last Year",
    name: "mark_range_name",
    placeholder: "Enter your grade in last year",
    required: true,
  },
  {
    label: "Certificate Issuing Authority",
    name: "board_name",
    placeholder: "Enter your board name",
    required: true,
  },
  {
    label: "",
    name: "board_id_other",
    placeholder: "Enter your board name",
  },
  {
    label: "City & Country Of The Authority",
    name: "board_city",
    placeholder: "Enter your board city",
    required: true,
  },
  {
    label: "Name Of Studied Institution",
    name: "institution",
    placeholder: "Enter your institute name",
    required: true,
  },
  {
    label: "City & Country Of The Institution",
    name: "institution_city",
    placeholder: "Enter your city and country of the institution",
    required: true,
  },
];

//qualification data for not educated

export const qualificationDataNotEducated = [
  {
    label: "Status Of Education",
    name: "qualification_status",
    placeholder: "Enter your qualification status",
    required: true,
  },
];
//qualification data for not completed

export const qualificationDataNotCompleted = [
  {
    label: "Status Of Education",
    name: "qualification_status",
    placeholder: "Enter your qualification status",
    required: true,
  },
  {
    label: "Qualification Level",
    name: "qualification_name",
    placeholder: "Enter your qualification level",
    required: true,
  },
  {
    label: "",
    name: "qualification_id_other",
    placeholder: "Enter your qualification level",
     
  },
  {
    label: "Course & Subject",
    name: "course_name",
    placeholder: "Enter your course name",
    required: true,
  },
  {
    label: "",
    name: "course_id_other",
    placeholder: "Enter your course name",
   
  },
  {
    label: "Specialised Area",
    name: "discipline_name",
    placeholder: "Enter your specialization",
    required: true,
  },
  {
    label: "",
    name: "discipline_id_other",
    placeholder: "Enter your specialization",
    
  },
  {
    label: "Duration Of Course",
    name: "duration_course",
    placeholder: "Enter your course duration",
    required: true,
  },
  {
    label: "Medium Of Teaching",
    name: "medium_study_name",
    placeholder: "Enter your medium of teaching",
    required: true,
  },
  {
    label: "Year Of Break/Stopping",
    name: "year_of_pass",
    placeholder: "Enter your year of break/stop",
    required: true,
  },
  {
    label: "How Many Years Completed",
    name: "studing_years",
    placeholder: "Enter  number of years completed",
    required: true,
  },
  {
    label: "Marks/Grade in Last Year",
    name: "mark_range_name",
    placeholder: "Enter your grade in last year",
    required: true,
  },
  {
    label: "Certificate Issuing Authority",
    name: "board_name",
    placeholder: "Enter your board name",
    required: true,
  },
  {
    label: "",
    name: "board_id_other",
    placeholder: "Enter your board name",
  },
  {
    label: "City & Country Of The Authority",
    name: "board_city",
    placeholder: "Enter your board city",
    required: true,
  },
  {
    label: "Name Of Studied Institution",
    name: "institution",
    placeholder: "Enter your institute name",
    required: true,
  },
  {
    label: "City & Country Of The Institution",
    name: "institution_city",
    placeholder: "Enter your city and country of the institution",
    required: true,
  },
];

//qualification data for pass/fali

export const qualificationDataPassFail = [
  {
    label: "Status Of Education",
    name: "qualification_status",
    placeholder: "Enter your qualification status",
    required: true,
  },
  {
    label: "Qualification Level",
    name: "qualification_name",
    placeholder: "Enter your qualification level",
    required: true,
  },
  {
    label: "",
    name: "qualification_id_other",
    placeholder: "Enter your qualification level",
     
  },
  {
    label: "Course & Subject",
    name: "course_name",
    placeholder: "Enter your course name",
    required: true,
  },
  {
    label: "",
    name: "course_id_other",
    placeholder: "Enter your course name",
   
  },
  {
    label: "Specialised Area",
    name: "discipline_name",
    placeholder: "Enter your specialization",
    required: true,
  },
  {
    label: "",
    name: "discipline_id_other",
    placeholder: "Enter your specialization",
    
  },
  {
    label: "Duration Of Course",
    name: "duration_course",
    placeholder: "Enter your course duration",
    required: true,
  },
  {
    label: "Medium Of Teaching",
    name: "medium_study_name",
    placeholder: "Enter your medium of teaching",
    required: true,
  },
  {
    label: "Year Of Completion",
    name: "year_of_pass",
    placeholder: "Enter your year of completion",
    required: true,
  },
  {
    label: "Marks/Grade in Final Result",
    name: "mark_range_name",
    placeholder: "Enter your grade in final result",
    required: true,
  },
  {
    label: "Certificate Issuing Authority",
    name: "board_name",
    placeholder: "Enter your board name",
    required: true,
  },
  {
    label: "",
    name: "board_id_other",
    placeholder: "Enter your board name",
  },
  {
    label: "City & Country Of The Authority",
    name: "board_city",
    placeholder: "Enter your board city",
    required: true,
  },
  {
    label: "Name Of Studied Institution",
    name: "institution",
    placeholder: "Enter your institute name",
    required: true,
  },
  {
    label: "City & Country Of The Institution",
    name: "institution_city",
    placeholder: "Enter your city and country of the institution",
    required: true,
  },
];

//professional data

export const professionalData = [
  {
    label: "Occupation Category",
    name: "occupation_name",
    placeholder: "Enter your occupation name",
    required: true,
  },
  {
    label: "",
    name: "occupation_id_other",
    placeholder: "Enter your occupation name",
  },
  {
    label: "Job Category",
    name: "profession_name",
    placeholder: "Enter your profession",
    required: true,
  },
  {
    label: "",
    name: "profession_id_other",
    placeholder: "Enter your profession",
  },
  {
    label: "Designation",
    name: "designation_name",
    placeholder: "Enter your designation",
    required: true,
  },
  {
    label: "",
    name: "designation_id_other",
    placeholder: "Enter your designation",
  },
  {
    label: "Specialised Area",
    name: "specialization_name",
    placeholder: "Enter your specialization",
    required: true,
  },
  {
    label: "",
    name: "specialization_id_other",
    placeholder: "Enter your specialization",
  },
  {
    label: "Employer",
    name: "employer_name",
    placeholder: "Enter the employer name",
    required: true,
  },
  {
    label: "City and Country",
    name: "employer_city",
    placeholder: "Enter the employer city and country",
    required: true,
  },
  {
    label: "Duration",
    name: "from_date",
    placeholder: "Enter Your Job Duration",
    required: true,
  },
  { label: "To", name: "to_date", placeholder: "Enter To", required: true },
  {
    label: "Total Years",
    name: "exp_in_year",
    placeholder: "Enter your total years of experience",
    required: true,
  },
];

//present address data

export const presentAddressData = [
  {
    label: "House / Flat Name",
    name: "house_name",
    placeholder: "Enter your house/flat name",
  },
  {
    label: "House / Flat Number",
    name: "house_no",
    placeholder: "Enter your house/flat number",
  },
  {
    label: "Building Name",
    name: "building_name",
    placeholder: "Enter your building name",
  },
  {
    label: "Building Number",
    name: "building_no",
    placeholder: "Enter your building number",
  },
  { label: "Street", name: "street", placeholder: "Enter your street" },
  { label: "Place", name: "place", placeholder: "Enter your place" },
  { label: "Location", name: "location", placeholder: "Enter your location" },
  { label: "City", name: "city", placeholder: "Enter your city" },
  { label: "District", name: "district", placeholder: "Enter your district" },
  { label: "State", name: "state", placeholder: "Enter your state" },
  {
    label: "Country",
    name: "country",
    placeholder: "Enter your country",
  },
  {
    label: "Pincode / Zipcode",
    name: "zip_code",
    placeholder: "Enter your pincode/zipcode",
  },
  {
    label: "Land Mark - 01",
    name: "land_mark1",
    placeholder: "Enter your landmark",
  },
  {
    label: "Land Mark - 02",
    name: "land_mark2",
    placeholder: "Enter your landmark",
  },
  {
    label: "Nearest Police Station",
    name: "nearest_police_station",
    placeholder: "Enter your nearest police station",
  },
];

//permanent address

export const permanentAddressData = [
  {
    label: "House / Flat Name",
    name: "house_name",
    placeholder: "Enter your house/flat name",
  },
  {
    label: "House / Flat Number",
    name: "house_no",
    placeholder: "Enter your house/flat number",
  },
  {
    label: "Building Name",
    name: "building_name",
    placeholder: "Enter your building name",
  },
  {
    label: "Building Number",
    name: "building_no",
    placeholder: "Enter your building number",
  },
  { label: "Street", name: "street", placeholder: "Enter your street" },
  { label: "Place", name: "place", placeholder: "Enter your place" },
  { label: "Location", name: "location", placeholder: "Enter your location" },
  { label: "City", name: "city", placeholder: "Enter your city" },
  { label: "District", name: "district", placeholder: "Enter your district" },
  { label: "State", name: "state", placeholder: "Enter your state" },
  {
    label: "Country",
    name: "country",
    placeholder: "Enter your country",
  },
  {
    label: "Pincode / Zipcode",
    name: "zip_code",
    placeholder: "Enter your pincode/zipcode",
  },
  {
    label: "Land Mark - 01",
    name: "land_mark1",
    placeholder: "Enter your landmark",
  },
  {
    label: "Land Mark - 02",
    name: "land_mark2",
    placeholder: "Enter your landmark",
  },
  {
    label: "Nearest Police Station",
    name: "nearest_police_station",
    placeholder: "Enter your nearest police station",
  },
];



// address data

export const AddressData = [
  {
    label: "Address Type",
    name: "address_type",
    placeholder: "Enter your house/flat name",
    required : true
  },
  {
    label: "House / Flat Name and Number",
    name: "house_name",
    placeholder: "Enter your house/flat name and number",
  },
  {
    label: "Floor Name and Number",
    name: "house_no",
    placeholder: "Enter your floor name and number",
  },
  {
    label: "Building Name and Number",
    name: "building_name",
    placeholder: "Enter your building name and number",
  },
  { label: "Street", name: "street", placeholder: "Enter your street" },
  { label: "Place", name: "place", placeholder: "Enter your place" },
  { label: "Location", name: "location", placeholder: "Enter your location" },
  { label: "City", name: "city", placeholder: "Enter your city" ,required : true},
  { label: "District", name: "district", placeholder: "Enter your district" },
  { label: "State", name: "state", placeholder: "Enter your state" },
  {
    label: "Country",
    name: "country",
    placeholder: "Enter your country",
    required : true
  },
  {
    label: "Pincode / Zipcode",
    name: "zip_code",
    placeholder: "Enter your pincode/zipcode",
  },
  {
    label: "Land Mark - 01",
    name: "land_mark1",
    placeholder: "Enter your landmark",
  },
  {
    label: "Land Mark - 02",
    name: "land_mark2",
    placeholder: "Enter your landmark",
  },
  {
    label: "Nearest Police Station",
    name: "nearest_police_station",
    placeholder: "Enter your nearest police station",
  },
];



//other country fields

export const otherCountryAddressData = [
  {
    label: "Address Type",
    name: "address_type",
    placeholder: "Enter your house/flat name",
    required : true
  },
  {
    label: "House / Flat Name and Number",
    name: "house_name",
    placeholder: "Enter your house/flat name and number",
  },
  {
    label: "Floor Name and Number",
    name: "house_no",
    placeholder: "Enter your floor name and number",
  },
  {
    label: "Building Name and Number",
    name: "building_name",
    placeholder: "Enter your building name and number",
  },
  { label: "Street", name: "street", placeholder: "Enter your street" },
  { label: "Place", name: "place", placeholder: "Enter your place" },
  { label: "Location", name: "location", placeholder: "Enter your location" },
  {
    label: "City",
    name: "city",
    placeholder: "Enter your city",
    required: true,
  },
  { label: "District", name: "district", placeholder: "Enter your district" },
  { label: "State", name: "state", placeholder: "Enter your state" },
  {
    label: "Country",
    name: "country",
    placeholder: "Enter your country",
    required: true,
  },
  {
    label: "Pincode / Zipcode",
    name: "zip_code",
    placeholder: "Enter your pincode/zipcode",
  },
  {
    label: "Land Mark - 01",
    name: "land_mark1",
    placeholder: "Enter your landmark",
  },
  {
    label: "Land Mark - 02",
    name: "land_mark2",
    placeholder: "Enter your landmark",
  },
  {
    label: "Nearest Police Station",
    name: "nearest_police_station",
    placeholder: "Enter your nearest police station",
  },
  {
    label: "ID Proof",
    name: "proof_name",
    placeholder: "Enter your ID proof",
  },
  {
    label: "ID Number",
    name: "proof_no",
    placeholder: "Enter your ID number",
  },
  {
    label: "Mobile Number",
    name: "mobile_no",
    placeholder: "Enter your mobile number",
    required: true,
  },
  
];
