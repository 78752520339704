import React, { useState } from "react";
import * as Yup from "yup";
import ViewForm from "./EditForm";
import { useDispatch, useSelector } from "react-redux";
import { saveStepTwoData } from "../../Redux/Actions/registerActions";
import { useNavigate } from "react-router-dom";
import LocationAutocomplete from "../../components/Location/Location";
import LanguageSelect from "../../components/Select/Language";
import DesignationSelect from "../../components/Select/Designation";
import OccupationSelect from "../../components/Select/Occupation";
import QualificationSelect from "../../components/Select/Qualification";
import CourseSelect from "../../components/Select/Course";
import DatePicker from "../../components/Select/Date";
import { registerLevelTwo } from "../../api/registerApi";
import { setAlert } from "../../Redux/Actions/alertActions";
import { saveRegisterTwoFlag } from "../../Redux/Actions/loginActions";
import Modal from "../../components/Modal/Modal";
import coming from "../../assets/comingsoon.svg";
import { COMING_SOON_URL } from "../../constants/constants";

const RegisterTwo = () => {
  const userToken = useSelector((state) => state.register.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    gender: "",
    dob: "",
    country: "",
    mother_tongue: "0",
    occupation_id: "0",
    designation_id: "0",
    qualification_id: "0",
    course_id: "0",
    confirm: false,
  });
  const [conditions, setConditions] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string()
      .test("is-valid-date", "Invalid date", function (value) {
        // Validate if the date is a valid calendar date
        if (!value) return true;
        const parts = value.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        const date = new Date(year, month, day);
        return (
          date.getDate() === day &&
          date.getMonth() === month &&
          date.getFullYear() === year
        );
      })
      .required("Valid date of birth is required"),

    country: Yup.string().required("Country is required"),
    confirm: Yup.boolean()
      .oneOf([true], "Please accept terms and conditions")
      .required("Please accept terms and conditions"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handlePlaceSelected = (place) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...place }));
    setErrors({ ...errors, country: "" });
  };

  const handleLocationError = (error) => {
    // Clear location  error when the user starts typing
    if (!error) {
      setErrors({ ...errors, country: "" });
    }
  };

  const handleQualification = (qualification) => {
    setFormData({ ...formData, qualification_id: qualification?.value });
  };

  const handleCourse = (course) => {
    setFormData({ ...formData, course_id: course?.value });
  };
  const handleLanguage = (Language) => {
    setFormData({ ...formData, mother_tongue: Language?.value });
  };

  const handleOccupation = (occupation) => {
    setFormData({ ...formData, occupation_id: occupation?.value });
  };
  const handleDesignation = (designation) => {
    setFormData({ ...formData, designation_id: designation?.value });
  };

  const handleDob = (dob) => {
    setFormData({
      ...formData,
      ...dob,
      dob: `${dob.dob_day}/${dob.dob_month}/${dob.dob_year}`,
    });
    setErrors({ ...errors, dob: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await validationSchema.validate(formData, { abortEarly: false });
      const response = await registerLevelTwo(formData, userToken);
      if (response.status === 1) {
        dispatch(saveStepTwoData(formData));
        dispatch(saveRegisterTwoFlag(response.data.second_register));
        dispatch(setAlert(response.message, "success"));
        setFormData({
          gender: "",
          dob: "",
          country: "",
          mother_tongue: "0",
          occupation_id: "0",
          designation_id: "0",
          qualification_id: "0",
          course_id: "0",
          confirm: false,
        });
        navigate("/profile/basic-details");
        setErrors({});
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
        setLoading(false);
      }
    }
  };

  const handleConfirm = (e) => {
    if (!conditions) {
      setFormData({
        ...formData,
        confirm: e.target.checked,
      });
      setErrors({ ...errors, confirm: "" });
    }
  };

  const handleCondition = () => {
    setConditions(true);
  };

  return (
    <div className="flex justify-center items-center bg-custom-pattern min-h-page-height bg-cover">
      <div className="container mx-auto  ">
        <div className="flex flex-col md:flex-row">
          {/* Single Card with Left and Right Sections */}
          <div className="lg:w-[80%] w-[90%] mx-auto lg:mt-[8%] md:mt-20 mt-[20%] mb-8 bg-gray-100 rounded-lg shadow-lg">
            <div className="flex flex-col md:flex-row">
              {/* Left Section - View Details */}
              <div className="md:w-1/2 px-6 md:ps-12 m-auto">
                <h2 className="text-lg text-custom  my-4">
                  YOU'RE ALMOST THERE !
                </h2>
                <p className="text-gray-700 text-xs mb-12">
                  Just few more <br />
                  details to fill in to reach the final step
                </p>
                <ViewForm />
              </div>

              {/* Right Section - Form */}
              <div className="md:w-1/2 ">
                <div className="bg-white rounded-lg shadow-lg px-9 py-9">
                  <h2 className="text-lg text-custom text-center mb-4">
                    COMPLETE YOUR REGISTRATION
                  </h2>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="mb-4">
                      <label className="block  text-gray-700 text-xs mb-2">
                        Gender <span className="text-red-500">*</span>
                      </label>
                      <div className="flex">
                        <label
                          className={`inline-flex cursor-pointer w-1/3 border mr-2 text-xs rounded-full py-1 px-2  items-center ${
                            errors.gender ? "border-red-500" : "border-gray-400"
                          } `}
                        >
                          <input
                            type="radio"
                            name="gender"
                            id="address_type"
                            value="1"
                            onChange={handleInputChange}
                            checked={formData.gender === "1"}
                            className="form-radio  text-lime-900 border-lime-900"
                          />
                          <span className="ml-2 text-black">Male</span>
                        </label>
                        <label
                          className={`inline-flex cursor-pointer w-1/3 border mr-2 text-xs rounded-full py-1 px-2  items-center ${
                            errors.gender ? "border-red-500" : "border-gray-400"
                          } `}
                        >
                          <input
                            type="radio"
                            name="gender"
                            value="2"
                            id="address_type"
                            onChange={handleInputChange}
                            checked={formData.gender === "2"}
                            className="form-radio text-lime-900 border-lime-900"
                          />
                          <span className="ml-2 text-black">Female</span>
                        </label>
                        <label
                          className={`inline-flex cursor-pointer w-1/3 border  text-xs rounded-full py-1 px-2  items-center ${
                            errors.gender ? "border-red-500" : "border-gray-400"
                          } `}
                        >
                          <input
                            type="radio"
                            name="gender"
                            value="3"
                            id="address_type"
                            onChange={handleInputChange}
                            checked={formData.gender === "3"}
                            className="form-radio text-lime-900 border-lime-900"
                          />
                          <span className="ml-2 text-black">Other</span>
                        </label>
                      </div>
                      {errors.gender && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.gender}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        DOB <span className="text-red-500">*</span>
                      </label>
                      <DatePicker getDob={handleDob} error={errors.dob} />
                      {/* Error messages */}
                      <div className="flex ">
                        {errors.dob ? (
                          <p className="w-1/2 text-red-500 text-xs mt-1">
                            {errors.dob}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        City & Country <span className="text-red-500">*</span>
                      </label>
                      <LocationAutocomplete
                        getLocation={handlePlaceSelected}
                        locationError={handleLocationError}
                        Error={errors.country}
                      />
                      {errors.country && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.country}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        Mother Tongue
                      </label>
                      <LanguageSelect getLanguage={handleLanguage} />
                      {errors.motherTongue && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.motherTongue}
                        </p>
                      )}
                    </div>
                    {/* Qualification Dropdown */}
                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        Qualification
                      </label>
                      <QualificationSelect
                        getQualification={handleQualification}
                      />
                      {errors.qualification && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.qualification}
                        </p>
                      )}
                    </div>

                    {/* Course Name Dropdown */}
                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        Course Name
                      </label>
                      <CourseSelect
                        getCourse={handleCourse}
                        id={formData.qualification_id}
                        courseId={formData.qualificationId}
                      />
                      {errors.courseName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.courseName}
                        </p>
                      )}
                    </div>

                    {/* Occupation Dropdown */}
                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        Occupation
                      </label>
                      <OccupationSelect getOccupation={handleOccupation} />
                      {errors.occupation && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.occupation}
                        </p>
                      )}
                    </div>

                    {/* Destination Dropdown */}
                    <div className="mb-4">
                      <label className="block text-gray-700 text-xs mb-2">
                        Designation
                      </label>
                      <DesignationSelect getDesignation={handleDesignation} />
                      {errors.destination && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.destination}
                        </p>
                      )}
                    </div>

                    <label className="flex">
                      <input
                        type="checkbox"
                        id="confirm"
                        name="confirm"
                        checked={formData.confirm}
                        onChange={(e) => handleConfirm(e)}
                        className="form-radio mr-2  text-xs text-lime-900 border-lime-900"
                      />
                      <p c>
                        I accept the{" "}
                        <a
                          className="text-custom cursor-pointer font-bold"
                          onClick={handleCondition}
                        >
                          terms and conditions
                        </a>
                      </p>
                    </label>

                    {errors.confirm && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.confirm}
                      </p>
                    )}

                    <button
                      className="bg-[#5E8862] w-full rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "SUBMIT"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {conditions && (
        <Modal isOpen={conditions} onClose={() => setConditions(false)}>
          <div className="flex justify-center mt-4">
            <img
              src={`${COMING_SOON_URL}${coming}`}
              alt=""
              height="200px"
              width="200px"
            />
          </div>
          <p className="my-5 text-xl font-medium text-custom text-center">
            COMING SOON
          </p>
        </Modal>
      )}
    </div>
  );
};

export default RegisterTwo;
