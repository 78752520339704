import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { HiEye, HiEyeOff } from "react-icons/hi";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaRegEnvelope,
  FaRegUser,
} from "react-icons/fa";

import { BiSolidHelpCircle } from "react-icons/bi";
import { MdOutlinePhone } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { IoFingerPrintSharp } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { GiMedal } from "react-icons/gi";
import { getBasicDetails } from "../../api/basicDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import {
  saveMenuData,
  setBasicDetailsData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import MobileEditModal from "../../components/Popups/MobileEdItModal";
import EmailEditModal from "../../components/Popups/EmailEditModal";
import EmailVerifyModal from "../../components/Popups/EmailVerifyModal";
import MobileVerifyModal from "../../components/Popups/MobileVerifyModal";
import Modal from "../../components/Modal/Modal";
import coming from "../../assets/comingsoon.svg";
import { getMenuState } from "../../api/sideBarApi";
import { removeToken } from "../../Redux/Actions/registerActions";
import Skeleton from "../../components/Skeleton/Skeleton";
import { COMING_SOON_URL } from "../../constants/constants";
import { FiMenu } from "react-icons/fi";

const BasicDetails = () => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [showGsid, setShowGsid] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isMobileEditOpen, setIsMobileEditOpen] = useState(false);
  const [isEmailEditOpen, setIsEmailEditOpen] = useState(false);
  const [isEmailVerifyOpen, setIsEmailVerifyOpen] = useState(false);
  const [isMobileVerifyOpen, setIsMobileVerifyOpen] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [help, setHelp] = useState(false);
  const token = useSelector((state) => state.register.token);
  const basicDetails = useSelector((state) => state.profile.basicDetails);
  const menuState = useSelector((state) => state.profile.menuData);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleGsid = () => {
    setShowGsid(!showGsid);
  };

  useEffect(() => {
    const fetchMenuState = async (token) => {
      try {
        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          dispatch(removeToken());
          console.error("Failed to menu state");
        }
      } catch (error) {
        console.error("Error to get menu state", error);
      }
    };
    fetchMenuState(token);
  }, [token]);

  useEffect(() => {
    const fetchBasicDetails = async (token) => {
      try {
        const deatils = await getBasicDetails(token);
        if (deatils.status === 1) {
          dispatch(setBasicDetailsData(deatils.data));
          setSkeletonLoad(false);
        } else {
          console.error("Failed to fetch basic details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error basic details :", error);
        setSkeletonLoad(false);
      }
    };

    fetchBasicDetails(token);
  }, [dispatch, token]);

  const handleEmailVerify = (flag) => {
    setIsEmailVerified(flag);
  };
  const handleMobileVerify = (flag) => {
    setIsMobileVerified(flag);
  };

  const handleReseller = () => {
    setIsReseller(true);
  };

  if (skeletonLoad) {
    return <Skeleton/>;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
    <button
      className="block md:hidden ml-2 mb-2  "
      onClick={() => dispatch(setSidebarToggle(true))}
    >
      <FiMenu className="text-xl font-medium text-custom" />
    </button>
    <div className="flex justify-center overflow-x-hidden md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>

      <div className="lg:w-[65%] md:w-full    w-[97%] mb-4">
        <div className="bg-white shadow-lg  w-full lg:ml-6 md:mx-2 mx-0 p-6 md:p-10 md:pt-6 mb-4">
          <div className="mb-4 md:flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <h2 className="text-lg text-custom">BASIC DETAILS</h2>
                <button
                  className="ml-2    flex items-center text-blue-500 text-xs"
                  onClick={() => setHelp(true)}
                  title="Help"
                >
                  <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                </button>
              </div>

              <div className="bg-custom w-12 my-2 h-[2px]    "></div>
            </div>
             
            <div className="w-full md:w-[250px] md:block  flex justify-between" title="Profile Completion Percentage">
              <div className="w-[60%] md:w-full h-6 bg-gray-200 rounded-full mx-auto my-4 relative">
                <div
                  className={`h-6 bg-blue-500 rounded-full`}
                  style={{ width: menuState.progress + "%" }}
                >
                  {(() => {
                    let style = {};
                    if (menuState.progress <= 20) {
                      style = { left: "35%", color: "black" };
                    } else if (menuState.progress <= 35) {
                      style = { left: "0px" };
                    } else {
                      style = { left: menuState.progress / 3 + "%" };
                    }
                    return (
                      <span
                        className="absolute inset-0 flex items-center px-4 text-xs text-white"
                        style={style}
                      >
                        {menuState.progress}%
                      </span>
                    );
                  })()}
                </div>
              </div>
              <button
                className="ml-6 md:ml-auto w-max flex items-center text-blue-500 text-xs"
                onClick={toggleDetails}
              >
                {showDetails ? (
                  <HiEyeOff className="mr-1 text-sm text-blue-500" />
                ) : (
                  <HiEye className="mr-1 text-sm text-blue-500" />
                )}
                {showDetails ? "Hide Details" : "View Details"}
              </button>
            </div>
             
          </div>

          <div className=" lg:flex flex-row items-center">
            <label className="text-gray-500 text-xs lg:w-[250px]">
              Full Name
            </label>
            <div className="flex items-center mt-4 lg:mt-0 ">
              <FaRegUser className="text-custom mr-3 text-[15px]" />
              <span className="font-medium text-xs">
                {basicDetails.full_name}
              </span>
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className="lg:flex flex-row items-center ">
            <label className="text-gray-500  text-xs lg:w-[250px]">
              Username
            </label>
            <div className="flex items-center mt-4 lg:mt-0">
              <FaRegUser className="text-custom mr-3 text-[15px]" />
              <span className="font-medium  text-xs">
                {showDetails
                  ? basicDetails.username
                  : basicDetails.username_hide}
              </span>
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className=" lg:flex flex-row items-center  ">
            <label className="text-gray-500 text-xs lg:w-[250px]  ">
              Email Id
            </label>

            <div className="flex items-center justify-between flex-1  mt-4 lg:mt-0">
              <FaRegEnvelope className="text-custom mr-3 text-[15px]" />
              <span className=" font-medium text-xs">
                {showDetails ? basicDetails.email : basicDetails.email_hide}
              </span>
              <button
                className="  flex items-center text-blue-500 "
                onClick={() => setIsEmailEditOpen(true)}
                title="Edit"
              >
                <MdOutlineEdit className="text-blue-500 ml-2" />
              </button>
              {isEmailVerified || basicDetails.is_email_verfied === 1 ? (
                <div className="ml-auto" title="Verified">
                  <FaCheckCircle className="text-custom" />
                </div>
              ) : (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={() => setIsEmailVerifyOpen(true)}
                >
                  Verify
                </button>
              )}
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className="lg:flex flex-row items-center    ">
            <label className="text-gray-500 text-xs lg:w-[250px]">
              Mobile Number
            </label>

            <div className="flex items-center justify-between flex-1  mt-4 lg:mt-0">
              <MdOutlinePhone className="text-custom mr-3 text-[15px]" />
              <span className=" font-medium text-xs">
                {showDetails
                  ? `+ ${basicDetails.dial_code} ${basicDetails.mobile} `
                  : basicDetails.mobile_hide}
              </span>
              <button
                className=" flex items-center text-blue-500  "
                onClick={() => setIsMobileEditOpen(true)}
                title="Edit"
              >
                <MdOutlineEdit className="text-blue-500 ml-2" />
              </button>
              {isMobileVerified || basicDetails.is_mobile_verfied === 1 ? (
                <div className="ml-auto" title="Verified">
                  <FaCheckCircle className="text-custom" />
                </div>
              ) : (
                <button
                  className="ml-auto flex items-center text-blue-500 text-xs"
                  onClick={() => setIsMobileVerifyOpen(true)}
                >
                  Verify
                </button>
              )}
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className="lg:flex flex-row items-center mb-2">
            <label className="text-gray-500 text-xs lg:w-[250px]">
              City and Country
            </label>
            <div className="flex items-center mt-4 relative   lg:mt-0">
              <IoLocationOutline className="text-custom mr-3 text-[15px]" />
              <span className="font-medium  text-xs">
                {basicDetails.locations}
              </span>
              <button
                className="flex items-center text-blue-500"
                onClick={() => setShowTooltip(!showTooltip)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <FaInfoCircle className="text-blue-500 ml-2" />
              </button>
              {showTooltip && (
                <div className="absolute lg:top-[0%] top-[40px] left-[25%] lg:left-[150%]  transform -translate-x-[20%] w-[250px] text-[12px] mb-2 p-4 bg-gray-700 text-white   rounded shadow-none z-10">
                  All the features and services offered on our website are based
                  on this location.
                </div>
              )}
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className="lg:flex flex-row items-center    ">
            <label className="text-gray-500 text-xs lg:w-[250px]">
              GSR Number
            </label>
            <div className="flex items-center justify-between flex-1  mt-4 lg:mt-0">
              <IoFingerPrintSharp className="text-custom mr-3 text-[15px]" />
              <span className=" font-medium text-xs">
                {showGsid ? basicDetails.gsid : basicDetails.gsid_hide}
              </span>
              <button
                className="ml-auto flex items-center text-custom text-xs"
                onClick={toggleGsid}
                 
              >
                {showGsid ? (
                  <HiEyeOff className="mr-1 text-[16px] text-blue-500" title="Hide"/>
                ) : (
                  <HiEye className="mr-1 text-[16px] text-blue-500" title="Show"/>
                )}
              </button>
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
          <div className="lg:flex flex-row items-center  ">
            <label className="text-gray-500 text-xs lg:w-[250px]">
              Reseller Status
            </label>
            <div className="flex items-center justify-between flex-1  mt-4 lg:mt-0">
              <GiMedal className="text-custom mr-3 text-[15px]" />
              <span className="font-medium  text-xs">
                {basicDetails.is_reseller === 1 ? "Active" : "Inactive"}
              </span>{" "}
              <button
                className="ml-auto flex items-center text-blue-500 text-xs"
                onClick={handleReseller}
              >
                Become A Reseller
              </button>
            </div>
          </div>
          <hr className="border-gray-300 my-4" />
        </div>
      </div>

      {isReseller && (
        <Modal isOpen={isReseller} onClose={() => setIsReseller(false)}>
          <div className="flex justify-center mt-4">
            <img
              src={`${COMING_SOON_URL}${coming}`}
              alt=""
              height="200px"
              width="200px"
            />
          </div>
          <p className="my-5 text-xl font-medium text-custom text-center">
            COMING SOON
          </p>
        </Modal>
      )}

      {help && (
        <Modal
          isOpen={help}
          help={true}
          title={"BASIC DETAILS INFO"}
          onClose={() => setHelp(false)}
        >
          <div className=" ps-8  overflow-y-auto max-h-[300px]">
            <p className="mb-5 text-lg font-medium text-custom ">
              {basicDetails.info_title}
            </p>
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{
                __html: basicDetails.info_details,
              }}
            />
          </div>
        </Modal>
      )}

      {isEmailEditOpen && (
        <EmailEditModal
          isOpen={isEmailEditOpen}
          onClose={() => setIsEmailEditOpen(false)}
          val={basicDetails.email}
          verify={handleEmailVerify}
        />
      )}
      {isMobileEditOpen && (
        <MobileEditModal
          isOpen={isMobileEditOpen}
          verify={handleMobileVerify}
          onClose={() => setIsMobileEditOpen(false)}
        />
      )}

      {isEmailVerifyOpen && (
        <EmailVerifyModal
          isOpen={isEmailVerifyOpen}
          verify={handleEmailVerify}
          onClose={() => setIsEmailVerifyOpen(false)}
        />
      )}

      {isMobileVerifyOpen && (
        <MobileVerifyModal
          isOpen={isMobileVerifyOpen}
          verify={handleMobileVerify}
          onClose={() => setIsMobileVerifyOpen(false)}
        />
      )}
    </div>
    </div>
  );
};

export default BasicDetails;
