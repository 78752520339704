import axios from "axios";
import {
  ANSWER_SURVEY_QUESTIONS,
  FETCH_GENERIC_INFO,
  SURVEY_QUESTIONS,
  UPDATE_GENERIC_INFO,
} from "../config/config";

// get generic details get api call
export const getGenericDetails = async (data,token) => {
  try {
    const response = await axios.post(FETCH_GENERIC_INFO,data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get generic info", error);
    throw error;
  }
};

//update prsent address  data post api call
export const updateGenericData = async (q_id, option_id,option_value, token) => {
  try {
    const response = await axios.post(
      UPDATE_GENERIC_INFO,
      {
        question_id: q_id,
        option_no: option_id,
        is_profile:"1",
        option_value:option_value
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error update generic info data:", error);
    throw error;
  }
};

// get survey questions api call
export const getSurveyQuestions = async (token) => {
  try {
    const response = await axios.get(SURVEY_QUESTIONS + "?is_profile=1", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get survey questions", error);
    throw error;
  }
};

//update survey questions post api call
export const updateSurveyQuestions = async (q_id, option_id,value, token) => {
  try {
    const response = await axios.post(
      ANSWER_SURVEY_QUESTIONS,
      {
        question_id: q_id,
        option_no: option_id,
        is_profile:"1",
        option_value:value
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error update survey data", error);
    throw error;
  }
};
