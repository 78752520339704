import React, { useRef, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import Modal from "../Modal/Modal";
import { updateBasicDetails } from "../../api/basicDetailsApi";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import { setBasicDetailsData } from "../../Redux/Actions/profileActions";
import * as yup from "yup";
import { userCheck } from "../../api/registerApi";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
});

const EmailEditModal = ({ isOpen, onClose, val, verify }) => {
  const userToken = useSelector((state) => state.register.token);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: val,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (errors.email) {
        return;
      }
      await schema.validate(formData, { abortEarly: false });

      setLoading(true);

      // If validation passes, send to api

      const response = await updateBasicDetails(userToken, formData);

      // store data to redux state in case of success
      if (response.status === 1) {
        verify(false);
        dispatch(setAlert(response.message, "success"));
        dispatch(setBasicDetailsData(response.data));
        setFormData({ ...formData, email: "" });
        setLoading(false);
        setErrors({});
        handleCancel();
      } else {
        dispatch(setAlert(response.message, "error"));
        const apiErrors = response.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleCancel = () => {
    setFormData({ ...formData, email: "" });
    setErrors({});
    onClose();
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value) {
      checkUser({ [name]: value }, userToken, event.target);
    }
  };

  const checkUser = async (userData, userToken, target) => {
    try {
      const response = await userCheck(userData, userToken);
      const key = Object.keys(userData)[0];
      if (response.data.status) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: response.data.errors[key],
        }));
        target.focus();
      }
    } catch (error) {
      console.error("Error checking user duplicate", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
        <FaEnvelope className="text-white text-xl" />
      </div>
      <p className="my-5 text-lg font-normal text-custom text-center">
        EDIT YOUR EMAIL
      </p>
      <div className="px-[25px]">
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <input
            className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
              errors.email ? "border-red-500" : ""
            }`}
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={inputRef}
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
          <div className="my-2 flex justify-end">
            <button
              className="border hover:bg-custom hover:text-white border-custom w-1/2 rounded-full   text-custom text-xs mt-4 py-2 px-4 focus:outline-none focus:shadow-outline mr-2"
              type="button"
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className="bg-[#5E8862] w-1/2 text-xs rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline ml-2"
              type="submit"
              disabled={loading}
            >
              {loading ? "SAVING..." : "SAVE"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailEditModal;
