import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import {
  saveMenuData,
  setSidebarToggle,
} from "../../Redux/Actions/profileActions";
import { BiSolidHelpCircle } from "react-icons/bi";
import Modal from "../../components/Modal/Modal";
import PublicitySkeleton from "../../components/Skeleton/PublicitySkeleton";
import { FaPlus } from "react-icons/fa";
import {
  deleteProfilePicture,
  getProfileImages,
  removeDefaultProfilePicture,
  sendPicture,
  setDefaultProfilePicture,
} from "../../api/profileImagesApi";
import { BsExclamationCircleFill } from "react-icons/bs";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { getMenuState } from "../../api/sideBarApi";
import { FiMenu } from "react-icons/fi";

const ProfilePhotos = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.register.token);
  const [help, setHelp] = useState(false);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const [preImgs, setPreImgs] = useState([]);
  const [canvasModal, setCanvasModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [imageCount, setImageCount] = useState(null);
  const [proPhoto, setProphoto] = useState(
    "https://t4.ftcdn.net/jpg/03/40/12/49/360_F_340124934_bz3pQTLrdFpH92ekknuaTHy8JuXgG7fi.jpg"
  );
  const acceptedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

  useEffect(() => {
    const fetchProfileDetails = async (token) => {
      try {
        const details = await getProfileImages(token);
        if (details.status === 1) {
          setProphoto(details.data.profile_photo);
          setPreImgs(details.data.photos);
          setImageCount(details.data.total_photos);
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          setSkeletonLoad(false);
        } else {
          setInfo({
            info_title: details.info_title,
            info_details: details.info_details,
          });
          console.error("Failed to fetch profile pic details ");
          setSkeletonLoad(false);
        }
      } catch (error) {
        console.error("Error profile pic details :", error);
        setSkeletonLoad(false);
      }
    };

    fetchProfileDetails(token);
  }, [dispatch, token]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (imageCount === 5) {
      dispatch(setAlert("You can't upload more than 5 photos", "error"));
      return;
    }
    if (rejectedFiles.length > 0) {
      dispatch(setAlert("Please upload only SVG, JPG, or PNG files.", "error"));
    } else {
      const file = acceptedFiles[0];
      if (acceptedFormats.includes(file.type)) {
        setImage(file);
        setCanvasModal(true);
      } else {
        dispatch(
          setAlert(
            "Invalid file format. Please upload an SVG, JPG, or PNG file.",
            "error"
          )
        );
      }
    }
  };

  const handleSave = async () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas().toDataURL();

      try {
        setLoading(true);
        const pic = await sendPicture(token, { profile_pic: canvas });
        if (pic.status === 1) {
          setProphoto(pic.data.profile_photo);
          setPreImgs(pic.data.photos);
          setImageCount(pic.data.total_photos);
          dispatch(setAlert(pic.message, "success"));
          setLoading(false);
          setImage(null);
          setCanvasModal(false);

          //fetch the menu state

          const details = await getMenuState(token);

          if (details.status === 1) {
            dispatch(saveMenuData(details.data));
          } else {
            console.error("Failed to get menu state");
            dispatch(setAlert(details.message, "note"));
          }
        } else {
          setLoading(false);
          setImage(null);
          setCanvasModal(false);
          console.error("Failed to upload image ");
          dispatch(setAlert(pic.message, "error"));
        }
      } catch (error) {
        setLoading(false);
        setImage(null);
        setCanvasModal(false);
        console.error("Error in upload image :", error);
      }
    }
  };

  const handleCancel = () => {
    setImage(null);
    setCanvasModal(false);
  };

  const handleDelete = (index) => {
    setDeleteModal(true);

    setIndex(index);
  };

  const handleProfilePhoto = (index) => {
    setDefaultModal(true);
    setIndex(index);
  };

  const handleProfileDelete = (id) => {
    setRemoveModal(true);
    setIndex(id);
  };

  const handleProfilePicDelete = async () => {
    const delete_id = preImgs[index].id;
    try {
      setLoading(true);
      const response = await deleteProfilePicture(token, delete_id);
      if (response.status === 1) {
        setProphoto(response.data.profile_photo);
        setPreImgs(response.data.photos);
        setImageCount(response.data.total_photos);
        dispatch(setAlert(response.message, "success"));
        setLoading(false);
        setDeleteModal(false);
        //fetch the menu state

        const details = await getMenuState(token);

        if (details.status === 1) {
          dispatch(saveMenuData(details.data));
        } else {
          console.error("Failed to get menu state");
          dispatch(setAlert(details.message, "note"));
        }
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error setting  profile pic delete", error.message);
      setLoading(false);
    }
  };

  const handleProfileDefault = async () => {
    const default_id = preImgs[index].id;
    try {
      setLoading(true);
      const response = await setDefaultProfilePicture(token, default_id);
      if (response.status === 1) {
        setProphoto(response.data.profile_photo);
        setPreImgs(response.data.photos);
        setImageCount(response.data.total_photos);
        dispatch(setAlert(response.message, "success"));
        setLoading(false);
        setDefaultModal(false);
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error setting default pic", error.message);
      setLoading(false);
    }
  };

  const handleProfileRemove = async () => {
    try {
      setLoading(true);
      const response = await removeDefaultProfilePicture(token, index);
      if (response.status === 1) {
        setProphoto(response.data.profile_photo);
        setPreImgs(response.data.photos);
        setImageCount(response.data.total_photos);
        dispatch(setAlert(response.message, "success"));
        setLoading(false);
        setRemoveModal(false);
      } else {
        dispatch(setAlert(response.message, "error"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during form submission
      console.error("Error removing profile pic ", error.message);
      setLoading(false);
    }
  };

  if (skeletonLoad) {
    return <PublicitySkeleton />;
  }

  return (
    <div className="pt-20 min-h-screen bg-[#F9F9F9]">
      <button
        className="block md:hidden ml-2 mb-2  "
        onClick={() => dispatch(setSidebarToggle(true))}
      >
        <FiMenu className="text-xl font-medium text-custom" />
      </button>

      <div className="flex justify-center overflow-x-hidden md:justify-normal relative ">
        <div>
          <Sidebar />
        </div>

        <div className=" lg:w-[65%] md:w-full  w-[97%] mb-4 ">
          <div className="bg-white shadow-lg  w-full lg:ml-6 md:mx-2 mx-0 p-6 md:p-10 mb-4">
            <div className="mb-6 flex items-center ">
              <div>
                <div className="flex items-center">
                  <h2 className="text-lg text-custom">PROFILE IMAGES</h2>
                  <button
                    className="ml-auto   flex items-center text-blue-500 text-xs"
                    onClick={() => setHelp(true)}
                    title="Help"
                  >
                    <BiSolidHelpCircle className=" ml-2 cursor-pointer text-xl mb-0.5 text-help" />
                  </button>
                </div>

                <div className="bg-custom w-12 mt-2 h-[2px]   "></div>
              </div>
            </div>

            <div className="profile-upload p-4">
              <div className="  w-full  flex justify-center pb-8">
                <div
                  className={`bg-white relative rounded-lg border-[1px] border-gray-400 shadow-lg w-[200px] h-[200px]
               `}
                >
                  <img
                    src={proPhoto.profile_pic}
                    alt="Profile"
                    className="w-[150px] h-[150px]  mx-auto mt-2 object-cover rounded-full  "
                  />
                  {proPhoto.id ? (
                    <button
                      onClick={() => handleProfileDelete(proPhoto.id)}
                      className="absolute bottom-0 w-full right-0 p-2 rounded-b-lg bg-[#FAEDEA] text-red-500 hover:bg-[#fff3f2]"
                    >
                      <div className="flex justify-center items-center text-xs">
                        <img
                          src="https://img.icons8.com/?size=15&id=nerFBdXcYDve&format=png&color=ff0000"
                          alt=""
                          className="mr-2"
                        />
                        Remove profile image
                      </div>
                    </button>
                  ) : (
                    <div className="absolute bottom-0 w-full right-0 p-2 rounded-b-lg bg-[#FAEDEA] text-red-500 hover:bg-[#fff3f2]">
                      <div className="flex justify-center items-center text-xs">
                        <AiOutlineExclamationCircle className="mr-2" />
                        No default image
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {canvasModal && (
                <Modal isOpen={canvasModal} onClose={handleCancel}>
                  <div className="my-4">
                    <div className="editor-wrapper mx-auto mb-4 border-2 border-[#5E8862] rounded-lg overflow-hidden h-[300px] w-[300px]">
                      <AvatarEditor
                        ref={setEditor}
                        image={image}
                        width={200}
                        height={200}
                        border={50}
                        color={[163, 163, 163, 0.7]}
                        scale={scale}
                        rotate={0}
                      />
                    </div>
                    <div className="w-full flex justify-center mb-6 mt-2">
                      <input
                        type="range"
                        value={scale}
                        min="1"
                        max="2"
                        step="0.01"
                        onChange={(e) => setScale(parseFloat(e.target.value))}
                        className="w-[300px] bg-gray-300 rounded-lg appearance-none cursor-pointer"
                      />
                    </div>
                    <div className="flex justify-center ">
                      <button
                        onClick={handleCancel}
                        className="border-custom border  text-xs    hover:bg-custom  w-[140px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
                      >
                        CANCEL
                      </button>
                      <button
                        onClick={handleSave}
                        disabled={loading}
                        className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[140px] ml-[20px]  text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                      >
                        {loading ? "Saving..." : "SAVE"}
                      </button>
                    </div>
                  </div>
                </Modal>
              )}

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2">
                <Dropzone
                  onDrop={onDrop}
                  accept="image/jpeg, image/png, image/svg+xml"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      title="Upload Photo"
                      className="w-[200px] h-[200px] md:w-[150px] md:h-[150px] bg-[#E1E1E1] mx-auto mb-10 shadow-md p-4 rounded-md cursor-pointer"
                    >
                      <input {...getInputProps()} />
                      <div className="flex justify-center items-center w-full h-full">
                        <FaPlus className="text-5xl" />
                      </div>
                    </div>
                  )}
                </Dropzone>

                {preImgs.map((img, index) => (
                  <div key={index} className="flex justify-center  mb-10">
                    <div className="relative">
                      <img
                        src={img.profile_pic}
                        alt={`Preview ${index}`}
                        className="w-[200px] h-[200px] md:w-[150px] border-[1px] border-gray-400 md:h-[150px] object-cover shadow-lg rounded-md"
                      />
                      <button
                        onClick={() => handleDelete(index)}
                        title="Delete"
                        className="absolute top-0 right-0 p-2 bg-red-500 text-white rounded-bl-xl rounded-tr-md hover:bg-red-700"
                      >
                        <img
                          src="https://img.icons8.com/?size=15&id=nerFBdXcYDve&format=png&color=ffffff"
                          alt=""
                        />
                      </button>
                      <button
                        onClick={() => handleProfilePhoto(index)}
                        className="absolute bottom-0 w-full text-xs right-0 p-2 rounded-b-md bg-[#526754] text-white hover:bg-[#384139]"
                      >
                        Set as default
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {help && (
          <Modal
            isOpen={help}
            help={true}
            title={"PROFILE PHOTO INFO"}
            onClose={() => setHelp(false)}
          >
            <div className="  ps-8 overflow-y-auto max-h-[300px]">
              <p className="mb-5 text-lg font-medium text-custom ">
                {info.info_title}
              </p>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{ __html: info.info_details }}
              />
            </div>
          </Modal>
        )}

        {deleteModal && (
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to delete this profile pic ?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-[100px] mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleProfilePicDelete}
                >
                  {loading ? "DELETING..." : "DELETE"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {defaultModal && (
          <Modal isOpen={defaultModal} onClose={() => setDefaultModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to set this as default profile pic?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setDefaultModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552]  w-max mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleProfileDefault}
                >
                  {loading ? "Saving..." : "CONFIRM"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {removeModal && (
          <Modal isOpen={removeModal} onClose={() => setRemoveModal(false)}>
            <div className="rounded-full mx-auto my-4 w-max bg-custom p-2 ">
              <BsExclamationCircleFill className="text-white text-xl" />
            </div>
            <p className="my-5 text-lg font-normal text-custom text-center">
              ARE YOU SURE ?
            </p>
            <p className="text-xs text-center">
              Do you want to remove profile pic?
            </p>
            <div className="px-[25px]">
              <div className="my-2 flex justify-center">
                <button
                  className="border-custom border mr-3 mt-3 text-xs    hover:bg-custom  w-[100px] text-custom   hover:text-white   rounded-full  py-1.5 px-6 focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setRemoveModal(false)}
                >
                  CANCEL
                </button>
                <button
                  className="bg-[#5E8862]  hover:bg-[#4F7552] w-max mt-3 text-xs text-white rounded-full   py-2 px-6 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                  onClick={handleProfileRemove}
                >
                  {loading ? "Saving..." : "CONFIRM"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ProfilePhotos;
