import axios from "axios";
import {
  FETCH_BLOOD_LIST,
  FETCH_COUNTRY_LIST,
  FETCH_GENDER_LIST,
  FETCH_JOB_LIST,
  FETCH_MARITAL_STATUS,
  FETCH_RELIGION_LIST,
  GET_PERSONAL_DATA,
  UPDATE_PERSONAL_DATA,
} from "../config/config";

// get personal details get api call
export const getPersonalDetails = async (token) => {
  try {
    const response = await axios.get(GET_PERSONAL_DATA, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error to get personal data", error);
    throw error;
  }
};

//update personal data post api call
export const updatePersonalData = async (userData, token) => {
  try {
    const response = await axios.post(UPDATE_PERSONAL_DATA, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error update personal data:", error);
    throw error;
  }
};

// get country list api call
export const getCountryList = async (token, id) => {
  try {
    const response = await axios.post(
      FETCH_COUNTRY_LIST,
      {
        limit: "0",
        status: "1",
        val_type: id ? id : "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching country list:", error);
    throw error;
  }
};

// get marriage list api call
export const getMaritalList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_MARITAL_STATUS,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching marital status list:", error);
    throw error;
  }
};

// get gader list api call
export const getGnederList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_GENDER_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching gender list:", error);
    throw error;
  }
};

// get blood list api call
export const getBloodList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_BLOOD_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching blood group list:", error);
    throw error;
  }
};

// get religion list api call
export const getReligionList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_RELIGION_LIST,
      {
        limit: "0",
        status: "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching religion list:", error);
    throw error;
  }
};

// get job list api call
export const getJobList = async (token) => {
  try {
    const response = await axios.post(
      FETCH_JOB_LIST,
      {
        limit: "0",
        status: "1",
        occupation_id: "0",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job list:", error);
    throw error;
  }
};
