import React, { useEffect, useRef, useState } from "react";
import { LuRefreshCcw } from "react-icons/lu";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { generateCaptcha, validateCaptcha } from "../../api/loginApi";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alertActions";
import PhoneForm from "../../components/Phone/Phone";
import Welcome from "../../components/Welcome/Welcome";
import { PhoneNumberUtil } from "google-libphonenumber";
import { saveStepOneData } from "../../Redux/Actions/loginActions";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};
const LoginOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const userToken = useSelector((state) => state.register.token);
  const phoneFormRef = useRef(null);
  const [loginData, setLoginData] = useState({
    username: "",
    key: "",
    captcha: "",
    dial_code: "",
    country_code: "",
  });
  const [captcha, setCaptcha] = useState({});
  const [errors, setErrors] = useState({});
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string()

      .test("is-valid-username", "Invalid Credentials", function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const usernameRegex = /^(?![0-9])[a-zA-Z0-9]+$/;

        if (emailRegex.test(value) || usernameRegex.test(value)) {
          return true;
        } else {
          const dialCode = loginData.dial_code;
          const phoneNumber = `+${dialCode}${value}`;
          console.log("phone in log one", phoneNumber);
          return isPhoneValid(phoneNumber);
        }
      })
      .required("This field is required"),
    captcha: Yup.string().required("CAPTCHA is required"),
  });

  const fetchCaptcha = async () => {
    try {
      const captchaData = await generateCaptcha();
      if (captchaData) {
        setCaptcha(captchaData);
        setLoginData({ ...loginData, key: captchaData.key, captcha: "" });
        setRefreshing(false);
      } else {
        console.error("Failed to fetch captcha ");
      }
    } catch (error) {
      console.error("Error fetching captcha :", error);
    }
  };

  useEffect(() => {
    if (userToken) {
      navigate("/basic-details");
    } else {
      fetchCaptcha();
    }
  }, []);

  useEffect(() => {
    if (!mobile && inputRef.current) {
      inputRef.current.focus();
    }
  }, [mobile]);

  useEffect(() => {
    // Focus the input field of the PhoneForm component
    if (phoneFormRef.current) {
      phoneFormRef.current.focusInput();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      const isNumeric = /^[0-9]+$/.test(value);
      setMobile(isNumeric);
    }
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handlePhoneChange = ({ dial_code, mobile, country_code }) => {
    const isNumeric = /^[0-9]+$/.test(mobile);
    setMobile(isNumeric);
    setLoginData({
      ...loginData,
      username: mobile,
      dial_code: dial_code,
      country_code: country_code,
    });

    setErrors({ ...errors, username: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await validationSchema.validate(loginData, { abortEarly: false });

      let inputType;
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginData.username)) {
        inputType = "email";
      } else if (/^(?![0-9])[a-zA-Z0-9]+$/.test(loginData.username)) {
        inputType = "username";
      } else {
        inputType = "mobile";
      }

      const loginVal = {
        [inputType]: loginData.username,
        key: loginData.key,
        captcha: loginData.captcha,
      };

      if (inputType === "mobile") {
        loginVal.country_code = loginData.country_code;
        loginVal.dial_code = loginData.dial_code;
      }

      const response = await validateCaptcha(loginVal);

      if (response.data.status === 1) {
        dispatch(saveStepOneData(response.data.data));
        dispatch(setAlert(response.data.message, "success"));
        setLoginData({
          username: "",
          captcha: "",
          key: "",
        });
        navigate("/login-step-2");
        setErrors({});
        setLoading(false);
      } else {
        setRefreshing(true);
        fetchCaptcha();
        dispatch(setAlert(response.data.message, "error"));
        const apiErrors = response.data.errors;
        const newErrors = {};
        Object.keys(apiErrors).forEach((key) => {
          newErrors[key] = apiErrors[key][0];
        });
        setErrors(newErrors);
        setLoading(false);
      }
    } catch (error) {
      setRefreshing(true);
      fetchCaptcha();
      setLoading(false);
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      }
    }
  };

  const handleRefreshCaptcha = () => {
    setRefreshing(true);
    fetchCaptcha();
  };

  return (
    <div className="flex bg-custom-pattern justify-center items-center min-h-page-height">
      <div className="container mx-auto ">
        <div className="flex md:justify-around flex-col md:flex-row">
          {/* Left Section */}
          <Welcome />
          {/* Right Section */}
          <div className="lg:w-2/5 md:w-[60%] p-4 md:my-auto">
            <div className="bg-white lg:w-11/12  rounded-lg shadow-lg p-9">
              <h2 className="text-lg text-custom text-center my-4">
                STUDENT LOGIN
              </h2>
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="username"
                  >
                    Enter Username, mobile number or email-id
                  </label>
                  {mobile ? (
                    <PhoneForm
                      ref={phoneFormRef}
                      onPhoneChange={handlePhoneChange}
                      logMobile={loginData.username}
                      Error={errors.username}
                    />
                  ) : (
                    <input
                      ref={inputRef}
                      className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                        errors.username ? "border-red-500" : ""
                      }`}
                      type="text"
                      id="username"
                      name="username"
                      value={loginData.username}
                      onChange={handleInputChange}
                      placeholder="Enter Username, mobile number or email-id"
                    />
                  )}
                  {errors.username && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.username}
                    </p>
                  )}
                </div>

                <div className="mb-4 flex items-center  bg-[#5267543A] rounded-lg pl-6 mt-6 relative">
                  {captcha.img ? (
                    <img src={captcha.img} alt="captcha" className="h-[34px]" />
                  ) : (
                    <p className="text-xs h-[34px] pt-2">loading...</p>
                  )}
                  <button
                    className="absolute right-2 top-2"
                    type="button"
                    onClick={handleRefreshCaptcha}
                    disabled={refreshing}
                    title="Refresh Captcha"
                  >
                    <LuRefreshCcw />
                  </button>
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs mb-2"
                    htmlFor="username"
                  >
                    Enter Captcha
                  </label>
                  <input
                    className={`border border-gray-400 rounded-lg px-4 py-2 text-xs w-full focus:outline-none ${
                      errors.enteredCaptcha ? "border-red-500" : ""
                    }`}
                    type="text"
                    id="captcha"
                    name="captcha"
                    value={loginData.captcha}
                    onChange={handleInputChange}
                    placeholder="Enter CAPTCHA"
                  />
                  {errors.captcha && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.captcha}
                    </p>
                  )}
                </div>

                <button
                  className="bg-[#5E8862] w-full rounded-full hover:bg-[#4F7552] text-white mt-4 py-2 px-4 focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "NEXT"}
                </button>

                <p className="text-xs my-4">
                  Don't have an account?{" "}
                  <NavLink
                    to="/register-step-1"
                    className="text-custom font-bold"
                  >
                    Register
                  </NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOne;
