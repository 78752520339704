import React from "react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex items-center flex-col justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-red-600">404</h1>
      <h2 className="text-2xl font-semibold text-gray-800 mt-4">
        Page Not Found
      </h2>
      <p className="text-gray-600 text-xs my-2">
        Sorry, the page you are looking for does not exist.
      </p>
      <NavLink
        to="/profile/basic-details"
        className="bg-custom mt-3 text-xs text-white rounded-full py-2 px-6 focus:outline-none focus:shadow-outline hover:bg-lime-950"
      >
        Go Home
      </NavLink>
    </div>
  );
};

export default NotFound;
